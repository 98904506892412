import { Switch } from '@headlessui/react'
import { useField } from 'formik'
import styled from 'styled-components'
import { getClassNames } from '../../util'

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    .switch-container {
        --bg-color: #454163;
        background: var(--bg-color);
        width: 2.5rem;
        height: 1.25rem;
        border-radius: 0.75rem;
        border: none;
        position: relative;
    }

    .switch-container.on {
        --bg-color: var(--primary);
    }

    .switch-container.disabled {
        --bg-color: gray;
    }

    .switch-button {
        display: flex;
        background: white;
        border-radius: 100%;
        border: 0.25rem var(--bg-color) solid;
        height: 1.25rem;
        --width: 1.25rem;
        width: var(--width);
        position: absolute;
        top: 0;
        left: 0;
        transition: left 0.25s ease;
    }

    .switch-container.on .switch-button {
        left: calc(100% - var(--width));
    }

    .switch-label {
        margin-left: 1rem;
    }
`

interface Props {
    disabled?: boolean
    name: string
    label?: string
}

export const ToggleInput: React.FC<Props> = ({ disabled, name, label }) => {
    const [field, meta, helpers] = useField<boolean>(name)

    return (
        <Switch.Group>
            <Wrapper>
                <Switch
                    checked={field.value}
                    onChange={(value) => {
                        helpers.setValue(value, true)
                    }}
                    disabled={disabled}
                    className={getClassNames(
                        'switch-container',
                        field.value && 'on',
                        disabled && 'disabled',
                    )}
                >
                    <span className="switch-button" />
                </Switch>
                {label && (
                    <Switch.Label className="switch-label">
                        {label}
                    </Switch.Label>
                )}
                {meta.error && meta.error}
            </Wrapper>
        </Switch.Group>
    )
}
