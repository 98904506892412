/* eslint-disable no-fallthrough */
/* tslint-disable no-fallthrough */
import { UilTimes } from '@iconscout/react-unicons'
import { useField } from 'formik'
import { filter, uniqBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { ValidationMessage } from '../messages'

interface TagProps {
    value: string
    remove: (value: string) => void
}

const Tag: React.FC<TagProps> = ({ value, remove }) => {
    return (
        <div className="inline-flex items-center bg-gray-100 rounded-sm h-5 mr-1 mb-1">
            <span className="px-2 text-xs">{value}</span>
            <button
                className="flex items-center justify-center h-full rounded-sm aspect-square bg-gray-200 hover:bg-gray-300"
                onClick={remove.bind(null, value)}
                type="button"
            >
                <UilTimes className="h-3 w-3" />
            </button>
        </div>
    )
}

interface Props {
    name: string
    label?: string | React.ReactNode
    placeholder?: string
    showMultipleErrors?: boolean
    useComma?: boolean
}

export const normalizeValue = (value: string) => {
    return value
        .replace(/[^\w\d]+/g, '')
        .toLowerCase()
        .trim()
}

export const MultiTextInput: React.FC<Props> = ({
    name,
    label,
    placeholder,
    showMultipleErrors,
    useComma,
}) => {
    const [field, meta, helpers] = useField(name)
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    const removeTag = useCallback(
        (tagValue: string) => {
            return helpers.setValue(
                filter(field.value, (value) => value !== tagValue),
            )
        },
        [field.value, helpers],
    )

    const tags = useMemo(() => {
        return field.value.map((value: string) => (
            <Tag value={value} key={value} remove={removeTag} />
        ))
    }, [field.value, removeTag])

    const handleEnter = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const target: HTMLInputElement = e.target as any
            const value: string = (target.value as string)
                .trim()
                .replace(/\s+/g, ' ')

            switch (e.key) {
                case ',':
                    if (!useComma) break
                case 'Enter':
                    e.preventDefault()
                    e.stopPropagation()

                    if (value) {
                        helpers.setValue(
                            uniqBy(
                                (field.value as string[]).concat([value]),
                                normalizeValue,
                            ),
                        )

                        target.value = ''
                    }
            }
        },
        [field.value, helpers, useComma],
    )

    return (
        <div>
            {label && (
                <label className="inline-block text-[#333] font-[500] text-[.75rem] mb-1">
                    {label}
                </label>
            )}
            <div className="flex flex-col justify-center px-3 py-2 min-h-[42px] rounded-[.5rem] bg-white overflow-hidden border !border-[#bfbfbf]">
                {field.value.length !== 0 && (
                    <div className="flex flex-wrap mb-2">{tags}</div>
                )}
                <input
                    type="text"
                    className="ring-0 outline-none text-xs placeholder:text-[#c4c4c4]"
                    onKeyDown={handleEnter}
                    placeholder={placeholder}
                />
            </div>
            {invalid && (
                <div className="grid grid-cols-1 gap-2 mt-3">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <ValidationMessage
                                key={i}
                                message={message}
                                type="error"
                            />
                        ),
                    )}
                </div>
            )}
        </div>
    )
}
