import {
    Allow,
    IsEmail,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsPhoneNumber,
    IsString,
    Max,
    Min,
    ValidateNested,
} from 'class-validator'
import { FilePondFile } from 'filepond'
import { assign, clone, isString } from 'lodash'
import { ShoppingOption, toBase64 } from '../../../util'
import { Partner, Store } from '../../response'
import { BaseModel } from '../base.model'

export class ContactPersonModel {
    @IsString()
    @IsNotEmpty({ message: 'Must provide a name' })
    public name: string = ''

    @IsPhoneNumber('GB', { message: 'Must be a valid phone number' })
    public phone: string = ''

    @IsEmail(undefined, { message: 'Must be a valid email address' })
    public email: string = ''
}

// tslint:disable-next-line: max-classes-per-file
export class UpdateStoreConfigurationModel {
    @Min(1, { message: 'Must be at least $constraint1 mile(s)' })
    @Max(10, { message: 'Must be at most $constraint1 mile(s)' })
    @IsNumber(undefined, { message: 'Must be a number' })
    public mile_radius: number = 0

    @Allow()
    public category: string = ''

    @IsEnum(ShoppingOption, { message: 'Must be a valid selection' })
    public shopping_option: string = ShoppingOption.Store

    @Allow()
    public logo?: string = ''

    @IsOptional()
    public files?: (FilePondFile | string)[] = []

    @ValidateNested({ message: 'Contact person details are invalid ' })
    public contact_person?: ContactPersonModel

    constructor(partner: Partner, store?: Store | null) {
        this.logo = store?.logo ?? partner.logo ?? undefined
        this.files = this.logo ? [this.logo] : this.files
        this.mile_radius = store?.mile_radius ?? this.mile_radius
        this.category = store?.category ?? this.category
        this.shopping_option = store?.shopping_option ?? this.shopping_option

        const contactPerson = new ContactPersonModel()
        assign(contactPerson, store?.contact_person)

        this.contact_person = contactPerson
    }

    public generateCustomValidation(): Record<keyof BaseModel, string[]> {
        const errors: Partial<
            Record<keyof UpdateStoreConfigurationModel, string[]>
        > = {}

        if (this.files && this.files.length === 0) {
            errors.files = ['Must provide a logo']
        }

        return errors
    }

    public async withProcessedImages(): Promise<UpdateStoreConfigurationModel> {
        const model = clone(this)
        const file = model.files![0]

        if (isString(file)) {
            model.logo = file
        } else if (isString(file.source)) {
            model.logo = file.source
        } else {
            model.logo = await toBase64(file.source as File)
        }

        delete model.files
        return model
    }

    public getRequestBody(): UpdateStoreConfigurationModel {
        return clone(this)
    }
}
