import { motion } from 'framer-motion'
import React from 'react'
import { getClassNames } from '../../../../../util'

interface Props {
    day: string
    isToggled: boolean
    toggleDay: () => any
    isValid?: boolean
}

export const WorkingHoursDayButton: React.FC<Props> = ({
    day,
    isToggled,
    toggleDay,
    isValid,
}) => {
    return (
        <motion.button
            className={getClassNames(
                'w-[90px] h-11 items-center flex justify-center p-3 rounded-lg',
                isToggled ? 'bg-[#F2994A]' : 'bg-[#BDBDBD]',
            )}
            onClick={toggleDay}
            type="button"
        >
            <span className="text-white uppercase font-bold">
                {day.slice(0, 3)}
            </span>
        </motion.button>
    )
}
