import { validate, ValidationError } from 'class-validator'
import { merge, setWith } from 'lodash'
import { BaseModel } from '../../models/request'

const mapErrors = (
    errors: ValidationError[],
    errorRecord?: Record<string, string[]>,
    prefix: string = '',
    prefixErrorWithPropertyName?: boolean,
): Record<string, string[]> => {
    return errors.reduce((fieldErrors, { property, constraints, children }) => {
        if (prefix) {
            property = prefix + '.' + property
        }

        if (children && children.length !== 0) {
            return mapErrors(
                children,
                fieldErrors,
                property,
                prefixErrorWithPropertyName,
            )
        }

        let errors = Object.values(constraints as Record<string, string>)

        if (prefixErrorWithPropertyName) {
            errors = errors.map((error) => `${property}: ${error}`)
        }

        setWith(fieldErrors, property, errors, Object)

        return fieldErrors
    }, errorRecord ?? ({} as Record<string, string[]>))
}

export const validateModel = async <T extends BaseModel = BaseModel>(
    model: T,
    prefixErrorWithPropertyName?: boolean,
): Promise<Record<keyof T, string[]>> => {
    model.transform?.()
    const validationErrors = await validate(model, { whitelist: true })
    const customErrors = await model.generateCustomValidation?.()
    const mappedErrors = merge(
        mapErrors(validationErrors, {}, '', prefixErrorWithPropertyName),
        customErrors ?? {},
    )

    if (process.env.NODE_ENV !== 'production') {
        console.log({ errors: mappedErrors, values: { ...model } })
    }

    return mappedErrors as Record<keyof T, string[]>
}
