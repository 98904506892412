import { UilExclamationTriangle } from '@iconscout/react-unicons'
import { useField } from 'formik'
import React from 'react'
import Select from 'react-select/async'
import styled from 'styled-components'
import { getClassNames } from '../../util'

const Wrapper = styled.label`
    display: flex;
    flex-direction: column;
    position: relative;

    .select__label {
        font-weight: 500;
        margin-bottom: 0.75rem;
    }

    .select__control {
        overflow: hidden;
        border: 1px solid #bfbfbf;
        border-radius: 0.85rem;
        display: flex;
        --height: calc(3rem + 2px);
        height: var(--height);

        :focus-within {
            border-color: black;
            border-width: 2px;
        }
    }

    .select__value-container {
        padding: 0 1.25rem;
    }

    .select__error-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        margin-top: 0.75rem;
    }

    .select__error-message {
        color: #a11717;
        font-size: 0.85rem;
        font-weight: 500;
        background: #f5f1f1;
        display: inline-flex;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        align-items: center;
    }

    .select__error-message__text {
        margin-left: 1rem;
    }

    .select__options {
        background: white;
        border: 1px solid black;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        z-index: 4;
    }
`

interface Props<OptionType> {
    name: string
    options: {
        display: keyof OptionType
        value: keyof OptionType
    }
    className?: string
    debounce?: number
    loadOptions: (value: string) => OptionType[] | Promise<OptionType[]>
    onChange?: (value: any) => any
    label?: string
    showMultipleErrors?: boolean
}

export const ReactSelectInputAsync = <OptionType,>({
    name,
    label,
    className,
    options,
    showMultipleErrors,
    loadOptions,
    onChange,
}: React.PropsWithChildren<Props<OptionType>>) => {
    const field = useField(name)
    const meta = field[1]
    const helpers = field[2]
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const valid = React.useMemo(() => !meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    const onInputChange = React.useCallback(
        async (text: string) => {
            helpers.setTouched(true)
            return (await loadOptions(text)).map((option) => ({
                label: option[options.display],
                value: option[options.value],
            }))
        },
        [loadOptions, options.display, options.value, helpers],
    )

    return (
        <Wrapper
            className={getClassNames(
                className,
                invalid && 'invalid',
                valid && 'valid',
            )}
        >
            <span className="select__label">{label}</span>
            <Select
                className="select"
                classNamePrefix="select"
                cacheOptions
                loadOptions={onInputChange}
                getOptionValue={(option) => option.value as unknown as string}
                onChange={
                    onChange ??
                    ((newValue) => {
                        helpers.setValue(newValue?.value ?? '')
                    })
                }
            />
            {invalid && (
                <div className="select__error-section">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <span key={i} className="select__error-message">
                                <UilExclamationTriangle size={14} />
                                <span className="select__error-message__text">
                                    {message}
                                </span>
                            </span>
                        ),
                    )}
                </div>
            )}
        </Wrapper>
    )
}
