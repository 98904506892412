import { Formik, FormikHelpers } from 'formik'
import { map, uniq } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import { firstValueFrom, Subscription } from 'rxjs'
import { InvertedButton, PrimaryButton, Table } from '../../../../components'
import { Modal } from '../../../../components/misc/modal'
import { CreateProductModel } from '../../../../models'
import { ProductUploadModel } from '../../../../models/request/products/product-upload.model'
import { appState, MessageType } from '../../../../stores'
import { useStores } from '../../../../util'
import { ModalFormWrapper } from '../../common'
import { BulkProductUploader } from '../components'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

export const BulkUploadProductModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { categories, products } = useStores()
        const subscriptions: Subscription[] = []
        const [productUploads, setProductUploads] = useState<
            ProductUploadModel[]
        >([])
        const [isReadyToSubmit, setIsReadyToSubmit] = useState(false)
        const categoriesLoaded = categories.categoriesLoaded

        const validateProducts = async (products_: ProductUploadModel[]) => {
            try {
                await firstValueFrom(categories.listCategories())
                setIsReadyToSubmit(!!products_.length)
                setProductUploads(products_)
            } catch (error) {
                //
            }
        }

        const getCategories = async () => {
            try {
                const categoryNames = uniq(
                    map(productUploads, (product) =>
                        product.category!.trim(),
                    ) as string[],
                )

                await categories.findOrCreateCategoriesByName(categoryNames)
            } catch {
                //
            }
        }

        const transformProducts = async () => {
            try {
                await firstValueFrom(categories.listCategories())
                return productUploads.map(async (product) => {
                    if (product instanceof CreateProductModel) {
                        return product
                    }

                    const category =
                        await categories.findOrCreateCategoryByName(
                            product.category!.trim(),
                        )

                    product.category = category?.id
                    const model = product.convertToCreateProductModel()
                    return model
                })
            } catch (error) {
                return []
            }
        }

        const createProducts = async (
            _: {},
            { setSubmitting }: FormikHelpers<{}>,
        ) => {
            try {
                unsubscribe()
                setSubmitting(true)
                await getCategories()
                const models = await Promise.all(await transformProducts())

                if (models.length === 0) {
                    appState.createMessage('Error occured', MessageType.ERROR)
                    return
                }

                const subscription = products
                    .createMultipleProducts(models)
                    .subscribe({
                        next: () => {
                            setSubmitting(false)
                            setIsOpen(false)
                            setProductUploads([])
                            unsubscribe()
                        },
                    })

                subscriptions.push(subscription)
            } catch {
                setIsOpen(false)
                setIsReadyToSubmit(false)
                setSubmitting(false)
                setProductUploads([])
                unsubscribe()
            }
        }

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Bulk upload products"
            >
                <Formik initialValues={{}} onSubmit={createProducts}>
                    {({ isSubmitting, handleSubmit }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="flex flex-col flex-1 p-7 overflow-hidden">
                                <span className="block mb-2">
                                    Kindly make sure that your file matches the
                                    excel format provided here, your file must
                                    come in .CSV format.
                                </span>
                                <a
                                    href="/docs/product-upload-template.csv"
                                    download
                                    className="block mb-4"
                                >
                                    <span>
                                        Download the format for bulk upload
                                    </span>
                                </a>
                                <BulkProductUploader
                                    validateProducts={validateProducts}
                                />
                                {productUploads.length !== 0 && (
                                    <div className="flex-[1_1_auto] overflow-y-auto">
                                        <div className="relative">
                                            <Table
                                                data={productUploads}
                                                columns={[
                                                    {
                                                        Header: 'Product',
                                                        Cell: ({
                                                            cell,
                                                        }: CellProps<ProductUploadModel>) => {
                                                            const product =
                                                                cell.row
                                                                    .original

                                                            return (
                                                                <div
                                                                    className="flex"
                                                                    style={{
                                                                        color: '#646464',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="h-9 w-9 bg-[#f2f2f2] bg-center bg-contain bg-no-repeat"
                                                                        style={{
                                                                            backgroundImage: `url(${product.image_url})`,
                                                                        }}
                                                                    />
                                                                    <div className="flex flex-col ml-2">
                                                                        <b>
                                                                            {
                                                                                product.title
                                                                            }
                                                                        </b>
                                                                        <span>
                                                                            {
                                                                                product.product_code
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                    },
                                                    {
                                                        Header: 'Category',
                                                        accessor: 'category',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )}
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={
                                        !categoriesLoaded ||
                                        !isReadyToSubmit ||
                                        isSubmitting
                                    }
                                >
                                    <span>Upload products</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
