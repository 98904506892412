import { entries } from 'lodash'
import { Option } from './option'

export enum CategoryVisibility {
    InStock = 'in-stock',
    OutOfStock = 'out-of-stock',
}

export const categoryVisibilityMap = {
    [CategoryVisibility.InStock]: 'In stock',
    [CategoryVisibility.OutOfStock]: 'Out of stock',
}

export const categoryVisibilityOptions: Option[] = entries(
    categoryVisibilityMap,
).map(([key, value]) => ({ key, value }))
