import { UilUpload } from '@iconscout/react-unicons'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { useField } from 'formik'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { FilePond, registerPlugin } from 'react-filepond'
import styled from 'styled-components'
import { ValidationMessage } from '../../../../components'
import { appState, MessageType } from '../../../../stores'
import { getClassNames } from '../../../../util'

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
)

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    .filepond--root {
        font-family: 'Quicksand';
        min-height: 128px;
        border: 1px solid #bfbfbf;
        border-radius: 0.5rem;

        :focus-within {
            border-color: black;
        }
    }

    .filepond--drop-label {
        height: 128px;
    }

    .filepond--panel-root {
        background-color: white;
        overflow: hidden;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .image-uploader__label {
        color: #333;
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
    }

    .image-uploader__error-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        margin-top: 0.75rem;
    }

    .image-uploader__error-message {
        color: #a11717;
        font-size: 0.85rem;
        font-weight: 500;
        background: #f5f1f1;
        display: inline-flex;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        align-items: center;
    }

    .image-uploader__error-message__text {
        margin-left: 1rem;
    }

    .image-uploader__placeholder {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .image-uploader__placeholder-icon {
        color: var(--primary);
    }

    .image-uploader__placeholder-text {
        font-style: normal;
        font-weight: 700;
        font-size: 11.9574px;
        line-height: 15px;
        text-align: center;
        color: #646464;
    }

    .image-uploader__placeholder-subtext {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #c4c4c4;
    }
`

interface Props {
    name: string
    label: string
    placeholder: string
    showMultipleErrors?: boolean
    className?: string
    alt?: string
}

const Placeholder = (
    <div className="image-uploader__placeholder">
        <UilUpload
            className="image-uploader__placeholder-icon mb-2"
            size={24}
        />
        <span className="image-uploader__placeholder-text mb-1">
            Drag your image here
        </span>
        <span className="image-uploader__placeholder-subtext">
            (Only *.jpeg and *.png images will be accepted)
        </span>
    </div>
)

export const ProductImageUploader: React.FC<Props> = ({
    name,
    label,
    className,
    showMultipleErrors,
}) => {
    const [field, meta, helpers] = useField<any[]>({ name })
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const valid = React.useMemo(() => !meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    const image = field.value[0]

    return (
        <Wrapper
            className={getClassNames(
                className,
                invalid && 'invalid',
                valid && 'valid',
            )}
        >
            <span className="image-uploader__label">{label}</span>
            {image && (
                <div className="p-3 bg-white border border-[#bfbfbf] rounded-lg mb-4">
                    <img src={image} className="w-full" alt="" />
                </div>
            )}
            <div>
                <FilePond
                    instantUpload={false}
                    credits={false}
                    onaddfile={(error) => {
                        helpers.setTouched(true)

                        if (error) {
                            helpers.setValue([])
                            return appState.createMessage(
                                'Error occured while loading image',
                                MessageType.ERROR,
                            )
                        }
                    }}
                    onupdatefiles={helpers.setValue}
                    allowMultiple={false}
                    labelIdle={ReactDOMServer.renderToString(Placeholder)}
                    acceptedFileTypes={['image/jpeg', 'image/png']}
                />
            </div>
            {invalid && (
                <div className="image-uploader__error-section">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <ValidationMessage
                                key={i}
                                message={message}
                                type="error"
                            />
                        ),
                    )}
                </div>
            )}
        </Wrapper>
    )
}
