import React, { useCallback, useEffect, useState } from 'react'
import { useZxing } from 'react-zxing'
import { Loader } from '../../../../components'

interface Props {
    onRead: (value: string) => any
}

export const BarcodeScanner: React.FC<Props> = ({ onRead }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [hasPermission, setHasPermission] = useState<boolean>(true)

    const { ref } = useZxing({
        timeBetweenDecodingAttempts: 2000,
        onResult(result) {
            onRead(result.getText())
        },
    })

    const requestCameraPermission = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                },
            })

            ref.current!.srcObject = stream
        } catch (err) {
            console.log(err)
            setHasPermission(false)
        } finally {
            setIsLoading(false)
        }
    }, [ref])

    useEffect(() => {
        requestCameraPermission()
    }, [ref, requestCameraPermission])

    return (
        <div className="grid gap-1 grid-cols-1">
            <label className="inline-block text-[#333] font-[500] text-[.75rem]">
                Scan product barcode
            </label>
            <div className="aspect-[2_/_1] overflow-hidden flex justify-center items-center relative">
                {!hasPermission && (
                    <span>
                        You need to grant camera permissions to use the barcode
                        reading feature
                    </span>
                )}
                {hasPermission && isLoading && (
                    <Loader
                        loading={isLoading}
                        className="flex justify-center items-center relative"
                    />
                )}
                <video ref={ref} className="w-full absolute" />
            </div>
        </div>
    )
}
