import { IsBoolean } from 'class-validator'
import { Store } from '../../response'
import { BaseModel } from '../base.model'

export class UpdateStoreVisibilityModel implements BaseModel {
    @IsBoolean()
    public isLive: boolean

    constructor(store: Store) {
        this.isLive = store.isLive
    }
}
