import { Formik } from 'formik'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import { PrimaryButton } from '../../../components'
import { Loader } from '../../../components/layout/loader'
import { Table } from '../../../components/misc/table'
import { Category } from '../../../models'
import { getCellItem, useStores } from '../../../util'
import { DashboardPageWrapper } from '../common'
import { CategoryActions } from './components'
import { EditCategoryModal } from './edit-category.modal'
import { NewCategoryModal } from './new-category.modal'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .grid-view {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        @media screen and (max-width: 1500px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .main-view {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex: 1 1 auto;
    }
`

export const CategoriesPage: React.FC = observer(() => {
    const { categories } = useStores()
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [activeCategory, setActiveCategory] = useState<Category | null>(null)
    const subscriptions: Subscription[] = []
    const loading = !categories.categoriesLoaded

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    const getCategories = useCallback(() => {
        const subscription = categories.listCategories().subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories])

    useEffect(() => {
        getCategories()
    }, [getCategories])

    return (
        <DashboardPageWrapper title="Categories">
            <NewCategoryModal
                isOpen={isCreateModalOpen}
                setIsOpen={setIsCreateModalOpen}
            />
            <EditCategoryModal
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                category={activeCategory}
            />
            <Wrapper>
                <header className="top-row">
                    <Formik
                        onSubmit={() => {
                            //
                        }}
                        initialValues={{}}
                    >
                        {() => (
                            <>
                                <div className="flex"></div>
                                <div className="flex">
                                    <PrimaryButton
                                        small
                                        onClick={() => {
                                            setIsCreateModalOpen(true)
                                        }}
                                    >
                                        <span>Add new category</span>
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </Formik>
                </header>
                <main className="main-view relative">
                    {
                        <Loader className="absolute" loading={loading}>
                            {categories.categories.length === 0 && (
                                <span>You do not have any categories</span>
                            )}
                            {categories.categories.length !== 0 && (
                                <Table
                                    data={categories.categories}
                                    columns={[
                                        {
                                            Header: 'Created',
                                            accessor: 'created_at',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Category>) => {
                                                const { created_at } =
                                                    getCellItem(cell)
                                                const date = moment(created_at)

                                                return (
                                                    <div className="flex flex-col">
                                                        <span>
                                                            {date.format(
                                                                'DD MMM yyyy',
                                                            )}
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: '#646464',
                                                            }}
                                                        >
                                                            {date.format(
                                                                'hh:mm A',
                                                            )}
                                                        </span>
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Title',
                                            accessor: 'title',
                                        },
                                        {
                                            Header: 'Action',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Category>) => {
                                                const category =
                                                    getCellItem(cell)

                                                return (
                                                    <CategoryActions
                                                        category={category}
                                                        setActiveCategory={
                                                            setActiveCategory
                                                        }
                                                        setIsEditModalOpen={
                                                            setIsEditModalOpen
                                                        }
                                                    />
                                                )
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </Loader>
                    }
                </main>
            </Wrapper>
        </DashboardPageWrapper>
    )
})
