import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { ListBox } from '../../../components'
import {
    cancelSubscriptions,
    orderStatusAnalyticsOptions,
    useStores,
} from '../../../util'
import { DashboardPageWrapper, HeadedBox } from '../common'
import { DonutChart } from './components'

export const AnalyticsPage: React.FC = observer(() => {
    const { analytics, stores } = useStores()
    const [analyticsStage, setAnalyticsStage] = useState(
        orderStatusAnalyticsOptions[0],
    )

    const stageWiseTimeliness = useMemo(() => {
        return analytics.stageWiseTimeliness?.[analyticsStage.key] ?? null
    }, [analyticsStage, analytics.stageWiseTimeliness])

    useEffect(() => {
        const subscriptions = [
            analytics.getOverallTimeliness().subscribe(),
            analytics.getStageWiseTimeliness().subscribe(),
        ]

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [stores.selectedStore, analytics])

    return (
        <DashboardPageWrapper title="Analytics">
            <div className="px-10 py-6 overflow-y-auto">
                <div className="grid grid-cols-2 gap-[10px]">
                    <HeadedBox className="aspect-square">
                        <span className="font-[Dosis] text-[17px] text-[#11263C]">
                            Overall timeliness
                        </span>
                        {analytics.overallTimeliness && (
                            <div className="flex flex-1 mt-2 relative">
                                {analytics.overallTimeliness.early +
                                    analytics.overallTimeliness.late !==
                                0 ? (
                                    <DonutChart
                                        data={analytics.overallTimeliness}
                                    />
                                ) : (
                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <span>No data available</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </HeadedBox>
                    <HeadedBox className="aspect-square">
                        {stageWiseTimeliness && (
                            <>
                                <div className="flex justify-between">
                                    <span className="font-[Dosis] text-[17px] text-[#11263C]">
                                        Stage-wise
                                    </span>
                                    <div>
                                        <ListBox
                                            onChange={setAnalyticsStage}
                                            value={analyticsStage}
                                            options={
                                                orderStatusAnalyticsOptions
                                            }
                                        />
                                    </div>
                                </div>
                                {analytics.stageWiseTimeliness && (
                                    <div className="flex flex-1 mt-2 relative">
                                        {stageWiseTimeliness.early +
                                            stageWiseTimeliness.late !==
                                        0 ? (
                                            <DonutChart
                                                data={stageWiseTimeliness}
                                            />
                                        ) : (
                                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                <span>No data available</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </HeadedBox>
                </div>
            </div>
        </DashboardPageWrapper>
    )
})
