import React from 'react'
import { Link } from 'react-router-dom'
import { InvertedButton } from '../../components'
import { RouteLink } from '../../util'

export const LostPage: React.FC = () => {
    return (
        <div className="flex flex-col h-screen w-screen items-center justify-center">
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="h-8 mb-8"
                />
            </Link>
            <main className="">
                <Link to={RouteLink.Index}>
                    <InvertedButton small type="button">
                        <span>Go home</span>
                    </InvertedButton>
                </Link>
            </main>
        </div>
    )
}
