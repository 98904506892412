import { entries } from 'lodash'
import { Option } from '../option'

export enum DiscountLevel {
    CHECKOUT = 'checkout',
    PRODUCT = 'product',
}

export const discountLevelMap = {
    [DiscountLevel.CHECKOUT]: 'Checkout',
    [DiscountLevel.PRODUCT]: 'Product',
}

export const discountLevelOptions: Option[] = entries(discountLevelMap).map(
    ([key, value]) => ({ key, value }),
)
