import { capitalize } from 'lodash'
import { OrderStatus, OrderTimeliness } from '../../models/response'
import { DiscountStatus, ProductStatus } from '../constants'

interface StatusColors {
    color: string
    background: string
    text: string
}

export enum StatusColorCode {
    // Critical colors
    CRITICAL_FOREGROUND = '',
    CRITICAL_BACKGROUND = '',

    // Success colors
    SUCCESS_FOREGROUND = '#219653',
    SUCCESS_BACKGROUND = 'rgba(33, 150, 83, 0.13)',

    // Success colors
    DEFAULT_FOREGROUND = '#EEA83F',
    DEFAULT_BACKGROUND = 'rgba(238, 168, 63, 0.13)',

    // Warning colors
    WARNING_FOREGROUND = '',
    WARNING_BACKGROUND = '',
}

export const getStatusColors = (status?: string): StatusColors => {
    switch (status) {
        case OrderStatus.DISPATCHED:
            return {
                color: '#EEA83F',
                background: 'rgba(238, 168, 63, 0.13)',
                text: 'Dispatched',
            }
        case OrderStatus.PROCESSING:
            return {
                color: '#EEA83F',
                background: 'rgba(238, 168, 63, 0.13)',
                text: 'Processing',
            }
        case OrderStatus.CANCELLED:
            return {
                color: '#CA2626',
                background: 'rgba(202, 38, 38, 0.13)',
                text: 'Cancelled',
            }
        case OrderStatus.DELIVERED:
            return {
                color: '#219653',
                background: 'rgba(33, 150, 83, 0.13)',
                text: 'Delivered',
            }
        case OrderTimeliness.ON_TIME:
            return {
                color: '#219653',
                background: 'rgba(33, 150, 83, 0.13)',
                text: 'On Time',
            }
        case OrderTimeliness.LATE:
            return {
                color: '#CA2626',
                background: 'rgba(202, 38, 38, 0.13)',
                text: 'Late',
            }
        case DiscountStatus.ACTIVE:
            return {
                color: '#219653',
                background: 'rgba(33, 150, 83, 0.13)',
                text: 'Active',
            }
        case DiscountStatus.DISABLED:
            return {
                color: '#CA2626',
                background: 'rgba(202, 38, 38, 0.13)',
                text: 'Disabled',
            }
        case ProductStatus.Published:
            return {
                color: '#219653',
                background: 'rgba(33, 150, 83, 0.13)',
                text: 'Published',
            }
        case ProductStatus.Unpublished:
            return {
                color: '#CA2626',
                background: 'rgba(202, 38, 38, 0.13)',
                text: 'Unpublished',
            }
        case 'credit':
            return {
                color: '#219653',
                background: 'rgba(33, 150, 83, 0.13)',
                text: 'Credit',
            }
        case 'debit':
            return {
                color: '#CA2626',
                background: 'rgba(202, 38, 38, 0.13)',
                text: 'Debit',
            }
        default:
            return {
                color: '#654FF3',
                background: 'rgba(101, 79, 243, 0.13)',
                text: capitalize(status),
            }
    }
}
