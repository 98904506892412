import { UilEllipsisV } from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Subscription } from 'rxjs'
import { Dropdown, DropdownItem, TableButton } from '../../../../../components'
import { EditProductStatusModel, Product } from '../../../../../models'
import { getClassNames, ProductStatus, useStores } from '../../../../../util'

interface Props {
    product: Product
    small?: boolean
    className?: string
}

export const ProductDropdown: React.FC<Props> = observer(
    ({ product, small, className }) => {
        const { products } = useStores()
        let subscription: Subscription | null = null

        const model = useMemo(() => {
            const x = new EditProductStatusModel(product)
            x.status =
                x.status === ProductStatus.Unpublished
                    ? (x.status = ProductStatus.Published)
                    : (x.status = ProductStatus.Unpublished)
            return x
        }, [product])

        const updateProductStatus = useCallback(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            subscription = products.editProductStatus(model).subscribe()
        }, [model])

        useEffect(() => {
            return () => {
                subscription?.unsubscribe?.()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const items: DropdownItem[] = useMemo(() => {
            const isPublished = product.status !== ProductStatus.Unpublished

            return [
                {
                    label: isPublished ? 'Unpublish' : 'Publish',
                    action: updateProductStatus,
                },
                {
                    label: 'Delete',
                    action: () => {
                        // Do nothing
                    },
                    disabled: true,
                },
            ]
        }, [product.status, updateProductStatus])

        return (
            <Dropdown
                button={TableButton}
                buttonClassName={getClassNames('!px-1', small && '!h-5')}
                buttonContent={<UilEllipsisV size={small ? 8 : 12} />}
                items={items}
                className={getClassNames(small && '!h-5', className)}
            />
        )
    },
)
