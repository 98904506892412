import { UilTrash } from '@iconscout/react-unicons'
import React, { useCallback } from 'react'
import { confirmDialog, TableButton } from '../../../../../components'
import { Category } from '../../../../../models'
import { useStores } from '../../../../../util'

interface Props {
    category: Category
    setActiveCategory: (...args: any[]) => any
    setIsEditModalOpen: (...args: any[]) => any
}

export const CategoryActions: React.FC<Props> = ({
    category,
    setActiveCategory,
    setIsEditModalOpen,
}) => {
    const { categories } = useStores()
    const editCategory = useCallback(() => {
        setActiveCategory(category)
        setIsEditModalOpen(true)
    }, [category, setActiveCategory, setIsEditModalOpen])

    const deleteCategory = useCallback(() => {
        confirmDialog({
            title: `Delete "${category.title}"`,
            content: 'Are you sure you want to delete this category',
            confirmText: 'Yes, delete',
            confirmTextClassName: '!bg-red !text-white !border-red',
            onConfirm: () => {
                categories.deleteCategory(category.id).subscribe()
            },
        })
    }, [category, categories])

    return (
        <div className="flex">
            <TableButton className="mr-2" onClick={editCategory}>
                <span>Edit category</span>
            </TableButton>
            <TableButton
                className="!bg-red !text-white !border-red"
                onClick={deleteCategory}
            >
                <UilTrash size={12} />
            </TableButton>
        </div>
    )
}
