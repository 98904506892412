import React from 'react'
import styled from 'styled-components'
import { DashboardPageWrapper, HeadedBox } from './common'

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 20px;
    gap: 20px;
    padding: 1.5rem 2.5rem;

    .block {
        background: #ffffff;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        height: 100%;
    }
`

export const DashboardPage: React.FC = () => {
    return (
        <DashboardPageWrapper title="Dashboard">
            <Wrapper>
                <HeadedBox />
                <HeadedBox
                    heading="Total visits"
                    style={{ gridColumn: 'span 3' }}
                />
                <HeadedBox heading="Valuable customers" />
                <HeadedBox heading="Recent orders" />
                <HeadedBox
                    heading="Stock report"
                    style={{ gridColumn: 'span 2' }}
                />
            </Wrapper>
        </DashboardPageWrapper>
    )
}
