import { Allow, IsEmail, Matches, MinLength } from 'class-validator'
import { PASSWORD_MIN_LENGTH } from '../../../util'
import { BaseModel } from '../base.model'

export class ResetPasswordModel implements BaseModel {
    @IsEmail(undefined, { message: 'Must be a valid email address' })
    public email: string = ''

    @Matches(/^[\d]{6}$/, { message: 'Invalid code' })
    public code: string = ''

    @MinLength(PASSWORD_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @Matches(/[A-Z]+/, { message: 'Must contain an uppercase letter' })
    @Matches(/[a-z]+/, { message: 'Must contain a lowercase letter' })
    @Matches(/[0-9]+/, { message: 'Must contain a number' })
    public password: string = ''

    @Allow()
    public token?: string

    constructor(email?: string) {
        if (email) {
            this.email = email
        }
    }

    public attachToken(token: string) {
        this.token = token
    }
}
