import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    PrimaryButton,
    ReactSelectInput,
    TextInput,
    ToggleInput,
} from '../../../../components'
import { Modal } from '../../../../components/misc/modal'
import { CreateDiscountModel } from '../../../../models'
import {
    discountLevelOptions,
    discountStatusOptions,
    discountTypeOptions,
    useStores,
    validateModel,
} from '../../../../util'
import { ModalFormWrapper } from '../../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

export const NewDiscountModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { categories, discounts, products } = useStores()
        const subscriptions: Subscription[] = []
        const productOptions = products.products
        const categoryOptions = categories.categories

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        useEffect(() => {
            products.listProducts().subscribe()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [products])

        useEffect(() => {
            categories.listCategories().subscribe()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [categories])

        return (
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Add new discount"
            >
                <Formik
                    initialValues={new CreateDiscountModel()}
                    validate={validateModel}
                    onSubmit={(values, { setSubmitting }) => {
                        unsubscribe()
                        setSubmitting(true)

                        const subscription = discounts
                            .createDiscount(values)
                            .subscribe({
                                next(response) {
                                    if (response.ok) {
                                        setIsOpen(false)
                                    }
                                },
                                complete() {
                                    setSubmitting(false)
                                },
                            })

                        subscriptions.push(subscription)
                    }}
                >
                    {({ handleSubmit, values, isValid, isSubmitting }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid">
                                    <ReactSelectInput
                                        name="type"
                                        label="Type"
                                        placeholder="Discount type"
                                        list={discountTypeOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    <TextInput
                                        name="offer"
                                        label="Offer"
                                        placeholder="Offer"
                                    />
                                    <ReactSelectInput
                                        name="level"
                                        label="Applied to"
                                        placeholder="Applied to"
                                        list={discountLevelOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    <TextInput
                                        name="min_amount"
                                        label="Minimum amount"
                                        placeholder="Minimum amount"
                                        type="number"
                                    />
                                    <ReactSelectInput
                                        name="status"
                                        label="Status"
                                        placeholder="Discount status"
                                        list={discountStatusOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {!values.all_products && (
                                        <ReactSelectInput
                                            name="products"
                                            label="Products"
                                            placeholder="Select products"
                                            isMulti
                                            list={productOptions}
                                            options={{
                                                display: 'title',
                                                value: '_id',
                                            }}
                                        />
                                    )}
                                    <ReactSelectInput
                                        name="categories"
                                        label="Categories"
                                        placeholder="Select categories"
                                        isMulti
                                        list={categoryOptions}
                                        options={{
                                            display: 'title',
                                            value: 'id',
                                        }}
                                    />
                                    <ToggleInput
                                        name="all_products"
                                        label="All products"
                                    />
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Add new discount</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
