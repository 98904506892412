import { Allow, MaxLength, MinLength } from 'class-validator'
import { CategoryVisibility, NAME_MIN_LENGTH } from '../../../util'
import { BaseModel } from '../base.model'

export class CreateCategoryModel implements BaseModel {
    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @MaxLength(400, {
        message: 'Must be at most $constraint1 character(s) long',
    })
    public title: string = ''

    @Allow()
    public visibility: CategoryVisibility = CategoryVisibility.InStock
}
