import { UilCheck, UilSearch, UilTimes } from '@iconscout/react-unicons'
import { Formik } from 'formik'
import { memoize } from 'lodash'
import { observer } from 'mobx-react'
import moment from 'moment'
import Papa from 'papaparse'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { CellProps } from 'react-table'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import {
    confirmDialog,
    InvertedButton,
    TableButton,
    TextInput,
} from '../../../components'
import { Table } from '../../../components/misc/table'
import { Order, OrderStatus, UpdateOrderStatusModel } from '../../../models'
import {
    cancellableStatuses,
    downloadTextFile,
    formatAsCurrency,
    getCellItem,
    getStatusColors,
    RouteLink,
    updateableStatuses,
    useStores,
} from '../../../util'
import { DashboardPageWrapper } from '../common'
import { OrdersTabBar } from './common'
import { CancelOrderModal, UpdateOrderStatusModal } from './modals'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .main-view {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex: 1 1 auto;
    }
`

const getSubtotal = memoize((order: Order) => order.sub_total)

const mapOrdersToCsvFormat = (orders: Order[]) => {
    return orders.map((order) => {
        return {
            Reference: order.reference,
            'Date created': new Date(order.created_at).toISOString(),
            Customer: order.delivery.name,
            'Shipping address': order.delivery.address,
            Price: order.sub_total,
            Status: order.status.status,
        }
    })
}

export const OrdersPage: React.FC = observer(() => {
    const { orders, stores } = useStores()
    const subscriptions: Subscription[] = []
    const activeOrder = useRef<Order | null>(null)
    const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] =
        useState(false)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasOrders = orders.orders.length !== 0

    const exportOrders = useCallback(() => {
        const storeName = stores.selectedStore!.name
        const csv = Papa.unparse(mapOrdersToCsvFormat(orders.orders))
        downloadTextFile(
            storeName + '-orders-' + Date.now() + '.csv',
            csv,
            'text/csv',
        )
    }, [orders.orders, stores.selectedStore])

    const setActiveOrder = useCallback(
        (order: Order) => {
            activeOrder.current = order
        },
        [activeOrder],
    )

    const getOrders = useCallback(() => {
        const subscription = orders.listOrders().subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders])

    const acceptOrder = useCallback(() => {
        if (
            !activeOrder.current ||
            activeOrder.current.status.status !== OrderStatus.NEW
        ) {
            return
        }

        const values = new UpdateOrderStatusModel(activeOrder.current)
        values.status = OrderStatus.PROCESSING

        const subscription = orders.updateOrderStatus(values).subscribe({
            next: (response) => {
                if (response.ok && activeOrder) {
                    getOrders()
                }
            },
        })

        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, getOrders, activeOrder])

    const promptAcceptOrder = useCallback(() => {
        confirmDialog({
            onConfirm: () => acceptOrder(),
            title: 'Accept order',
            content: 'Are you sure you want to accept this order?',
            confirmText: 'Yes, accept',
        })
    }, [acceptOrder])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    useEffect(() => {
        getOrders()
    }, [getOrders])

    return (
        <DashboardPageWrapper title="Orders">
            {activeOrder.current && (
                <>
                    <UpdateOrderStatusModal
                        order={activeOrder.current}
                        isOpen={isUpdateStatusModalOpen}
                        setIsOpen={setIsUpdateStatusModalOpen}
                        getOrders={getOrders}
                    />
                    <CancelOrderModal
                        order={activeOrder.current}
                        isOpen={isCancelModalOpen}
                        setIsOpen={setIsCancelModalOpen}
                    />
                </>
            )}
            <Wrapper>
                {hasOrders && (
                    <header className="top-row">
                        <Formik
                            onSubmit={() => {
                                //
                            }}
                            initialValues={{}}
                        >
                            {() => (
                                <>
                                    <div className="flex">
                                        <TextInput
                                            name="search"
                                            placeholder="Search by any order parameter"
                                            className="mr-2"
                                            icon={UilSearch}
                                            disabled
                                        />
                                        {/* <TextInput
                                        name="date"
                                        placeholder="Date range"
                                        className="mr-2"
                                        icon={UilCalendarAlt}
                                        disabled
                                    />
                                    <TextInput
                                        name="status"
                                        placeholder="Status"
                                        className="mr-2"
                                        icon={UilTagAlt}
                                        disabled
                                    /> */}
                                    </div>
                                    <div className="flex">
                                        {/* <InvertedButton className="mr-3" small>
                                        <span>Print</span>
                                    </InvertedButton> */}
                                        {orders.orders.length !== 0 && (
                                            <InvertedButton
                                                small
                                                onClick={exportOrders}
                                            >
                                                <span>Export</span>
                                            </InvertedButton>
                                        )}
                                    </div>
                                </>
                            )}
                        </Formik>
                    </header>
                )}
                {orders.orders.length !== 0 && <OrdersTabBar />}
                <main className="main-view">
                    {!hasOrders && (
                        <span className="flex mt-3">
                            You do not have any orders right now
                        </span>
                    )}
                    <div className="relative">
                        {hasOrders && (
                            <Table
                                data={orders.orders}
                                columns={[
                                    {
                                        Header: 'Ref',
                                        accessor: 'reference',
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const { reference, _id: id } =
                                                getCellItem(cell)

                                            return (
                                                <Link
                                                    to={`${RouteLink.Orders}/${id}`}
                                                >
                                                    <b>{reference}</b>
                                                </Link>
                                            )
                                        },
                                    },
                                    {
                                        Header: 'Date created',
                                        accessor: 'created_at',
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const { created_at } =
                                                getCellItem(cell)
                                            const date = moment(created_at)

                                            return (
                                                <div className="flex flex-col">
                                                    <span>
                                                        {date.format(
                                                            'DD MMM yyyy',
                                                        )}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        {date.format('hh:mm A')}
                                                    </span>
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        Header: 'Customer',
                                        accessor: (order: Order) =>
                                            order.delivery?.name ??
                                            order.pickup?.name,
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const order = getCellItem(cell)
                                            const name =
                                                order.delivery?.name ??
                                                order.pickup?.name

                                            return <span>{name}</span>
                                        },
                                    },
                                    {
                                        Header: 'Shipping address',
                                        accessor: (order: Order) =>
                                            order.delivery?.address ?? '',
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const order = getCellItem(cell)
                                            const address =
                                                order.delivery?.address ?? ''

                                            return <span>{address}</span>
                                        },
                                    },
                                    {
                                        Header: 'Price',
                                        accessor: (order: Order) => {
                                            return getSubtotal(order)
                                        },
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const order = getCellItem(cell)
                                            const subtotal = getSubtotal(order)

                                            return (
                                                <b
                                                    style={{
                                                        whiteSpace: 'pre',
                                                    }}
                                                >
                                                    {formatAsCurrency(subtotal)}
                                                </b>
                                            )
                                        },
                                    },
                                    {
                                        Header: 'Status',
                                        accessor: (order: Order) =>
                                            order.status.status,
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const { status } = getCellItem(cell)
                                            const { color, background, text } =
                                                getStatusColors(status.status)

                                            return (
                                                <span
                                                    className="inline-flex px-2 rounded"
                                                    style={{
                                                        color,
                                                        background,
                                                    }}
                                                >
                                                    {text}
                                                </span>
                                            )
                                        },
                                    },
                                    {
                                        Header: 'Action',
                                        Cell: ({ cell }: CellProps<Order>) => {
                                            const order = getCellItem(cell)

                                            return (
                                                <div className="flex">
                                                    {updateableStatuses.includes(
                                                        order.status.status,
                                                    ) && (
                                                        <TableButton
                                                            onClick={() => {
                                                                setActiveOrder(
                                                                    order,
                                                                )
                                                                setIsUpdateStatusModalOpen(
                                                                    true,
                                                                )
                                                            }}
                                                        >
                                                            <span>
                                                                Change status
                                                            </span>
                                                        </TableButton>
                                                    )}

                                                    {order.status.status ===
                                                        OrderStatus.NEW && (
                                                        <TableButton
                                                            className="px-2 py-0"
                                                            style={{
                                                                color: '#27AE60',
                                                                borderColor:
                                                                    '#27AE60',
                                                            }}
                                                            onClick={() => {
                                                                setActiveOrder(
                                                                    order,
                                                                )
                                                                promptAcceptOrder()
                                                            }}
                                                        >
                                                            <UilCheck
                                                                size={12}
                                                            />
                                                        </TableButton>
                                                    )}

                                                    {cancellableStatuses.includes(
                                                        order.status.status,
                                                    ) && (
                                                        <TableButton
                                                            className="px-2 py-0 ml-2"
                                                            style={{
                                                                color: '#EB5757',
                                                                borderColor:
                                                                    '#EB5757',
                                                            }}
                                                            onClick={() => {
                                                                setActiveOrder(
                                                                    order,
                                                                )
                                                                setIsCancelModalOpen(
                                                                    true,
                                                                )
                                                            }}
                                                        >
                                                            <UilTimes
                                                                size={12}
                                                            />
                                                        </TableButton>
                                                    )}
                                                </div>
                                            )
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </main>
            </Wrapper>
        </DashboardPageWrapper>
    )
})

export default OrdersPage
