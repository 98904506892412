import { capitalize, find } from 'lodash'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import { TableButton } from '../../../components'
import { Table } from '../../../components/misc/table'
import { Store } from '../../../models'
import { getCellItem, useStores } from '../../../util'
import { DashboardPageWrapper } from '../common'
import { EditStoreModal } from './edit-store.modal'
import { NewStoreModal } from './new-store.modal'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .grid-view {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        @media screen and (max-width: 1500px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
`

export const StoresPage: React.FC = observer(() => {
    const { stores } = useStores()
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [activeStore, setActiveStore] = useState<Store | null>(null)
    const subscriptions: Subscription[] = []

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    const getStores = useCallback(() => {
        subscriptions.push(stores.listStores().subscribe())
        subscriptions.push(stores.listStoreCategories().subscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores])

    useEffect(() => {
        getStores()
    }, [getStores])

    return (
        <DashboardPageWrapper title="Stores">
            <NewStoreModal
                isOpen={isCreateModalOpen}
                setIsOpen={setIsCreateModalOpen}
            />
            {activeStore && (
                <EditStoreModal
                    isOpen={isEditModalOpen}
                    setIsOpen={setIsEditModalOpen}
                    store={activeStore}
                />
            )}
            <Wrapper>
                <header className="top-row"></header>
                <main className="main-view">
                    <Table
                        data={stores.stores}
                        columns={[
                            {
                                Header: 'Created',
                                accessor: 'created_at',
                                Cell: ({ cell }: CellProps<Store>) => {
                                    const { created_at } = getCellItem(cell)
                                    const date = moment(created_at)

                                    return (
                                        <div className="flex flex-col">
                                            <span>
                                                {date.format('DD MMM yyyy')}
                                            </span>
                                            <span
                                                style={{
                                                    color: '#646464',
                                                }}
                                            >
                                                {date.format('hh:mm A')}
                                            </span>
                                        </div>
                                    )
                                },
                            },
                            {
                                Header: 'Name',
                                accessor: 'name',
                            },
                            {
                                Header: 'Mile radius',
                                accessor: 'mile_radius',
                            },
                            {
                                Header: 'Postcode',
                                accessor: 'postcode',
                                Cell: ({ cell }: CellProps<Store>) => {
                                    const store = getCellItem(cell)
                                    return (
                                        <span>
                                            {store.postcode.toUpperCase()}
                                        </span>
                                    )
                                },
                            },
                            {
                                Header: 'Category',
                                accessor: 'category',
                                Cell: ({ cell }: CellProps<Store>) => {
                                    const store = getCellItem(cell)
                                    return (
                                        <span>
                                            {capitalize(
                                                find(stores.storeCategories, {
                                                    id: store.category,
                                                })?.name ?? '',
                                            )}
                                        </span>
                                    )
                                },
                            },
                            {
                                Header: 'Action',
                                Cell: ({ cell }: CellProps<Store>) => {
                                    const store = getCellItem(cell)
                                    return (
                                        <div className="flex">
                                            <TableButton
                                                className="mr-2"
                                                onClick={() => {
                                                    setActiveStore(store)
                                                    setIsEditModalOpen(true)
                                                }}
                                            >
                                                <span>Edit store</span>
                                            </TableButton>
                                        </div>
                                    )
                                },
                            },
                        ]}
                    />
                </main>
            </Wrapper>
        </DashboardPageWrapper>
    )
})
