import { Observable } from 'rxjs'
import {
    Order,
    UpdateOrderItemStatusModel,
    UpdateOrderStatusModel,
} from '../models'
import { HttpMethod, request, Response } from '../util'

export class OrdersService {
    public static listOrders(): Observable<Response<Order[]>> {
        return request<never, Order[]>('partner/orders', HttpMethod.GET, {
            loadingMessage: 'Retrieving orders',
        })
    }

    public static retrieveOrder(id: string): Observable<Response<Order>> {
        return request<never, Order>(`partner/orders/${id}`, HttpMethod.GET, {
            loadingMessage: 'Retrieving order',
        })
    }

    public static updateOrderStatus(model: UpdateOrderStatusModel) {
        return request<UpdateOrderStatusModel, never>(
            `partner/orders/${model.id}/status`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating order status',
                completionMessage: 'Order status updated',
            },
        )
    }

    public static updateOrderItemStatus(model: UpdateOrderItemStatusModel) {
        return request<UpdateOrderItemStatusModel, never>(
            `partner/orders/item/${model.id}/status`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating order item status',
                completionMessage: 'Order item status updated',
            },
        )
    }
}
