import moment from 'moment'
import { useMemo } from 'react'
import { WeeklyOpeningHours } from '../../models'
import { useStores } from '../stores'

export const useIsStoreOpen = () => {
    const { stores } = useStores()

    const isStoreOpen: boolean = useMemo(() => {
        const openingHours = stores.selectedStore?.opening_hours

        if (!openingHours) {
            return false
        }

        const now = moment()
        const day = now.format('dddd').toLowerCase()
        const todaysHours =
            openingHours[day as unknown as keyof WeeklyOpeningHours]

        if (!todaysHours || todaysHours.status === 'inactive') {
            return false
        }

        const startTime = moment(todaysHours.opens_at, 'HH:mm')
        const endTime = moment(todaysHours.closes_at, 'HH:mm')

        return now.diff(startTime) > 0 && endTime.diff(now) > 0
    }, [stores.selectedStore])

    return isStoreOpen
}
