import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: block;
    width: 100%;

    .ratio {
        height: 0;
        position: relative;
    }

    .ratio__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

interface Props {
    ratio: number
    className?: string
    children: React.ReactNode
}

export const AspectRatio: React.FC<Props> = ({ ratio, children, ...props }) => {
    const paddingTop = (ratio === 0 ? 100 : 100 / ratio) + '%'

    return (
        <Wrapper {...props}>
            <div className="ratio" style={{ paddingTop }}>
                <div className="ratio__content">{children}</div>
            </div>
        </Wrapper>
    )
}
