import {
    HTMLMotionProps,
    MotionProps,
    TargetAndTransition,
} from 'framer-motion'
import { merge } from 'lodash'
import React from 'react'
import { getClassNames } from '../../util'

interface Args {
    children: React.ReactNode
    disabled?: boolean | undefined
    isLoading?: boolean
    small?: boolean
    whileHover?: TargetAndTransition
    whileTap?: TargetAndTransition
    props: HTMLMotionProps<'button'>
}

export const generateMotionButtonAttributes = ({
    children,
    disabled,
    isLoading,
    small,
    whileHover = {},
    whileTap = {},
    props: { className, ...props },
}: Args): MotionProps => {
    const _children = (
        <React.Fragment>
            {isLoading
                ? [
                      <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ marginRight: '1rem' }}
                      ></div>,
                      <span className="sr-only">Loading</span>,
                  ]
                : children}
        </React.Fragment>
    )

    const motionAttributes: MotionProps = {
        whileHover: {
            scale: 1.025,
            ...whileHover,
        },
        whileTap: {
            scale: 0.95,
            ...whileTap,
        },
    }

    return merge(
        {
            className: getClassNames(
                small ? 'h-10 px-[1.5rem]' : 'h-12 px-8',
                className,
            ),
            children: _children,
            disabled,
        } as HTMLMotionProps<'button'>,
        {
            ...props,
            ...(disabled ? {} : motionAttributes),
        },
    )
}
