import { Formik } from 'formik'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import { PrimaryButton, TableButton } from '../../../components'
import { Table } from '../../../components/misc/table'
import { Discount } from '../../../models'
import {
    DiscountType,
    formatAsCurrency,
    getCellItem,
    getStatusColors,
    useStores,
} from '../../../util'
import { DashboardPageWrapper } from '../common'
import { DiscountDropdown } from './components'
import { NewDiscountModal } from './modals'
import { EditDiscountModal } from './modals/edit-discount.modal'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
`

export const DiscountsPage: React.FC = observer(() => {
    const { discounts, stores } = useStores()
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [activeDiscount, setActiveDiscount] = useState<Discount | null>(null)
    const subscriptions: Subscription[] = []

    const editDiscount = useCallback(
        (discount: Discount) => () => {
            setActiveDiscount(discount)
            setIsUpdateModalOpen(true)
        },
        [setActiveDiscount, setIsUpdateModalOpen],
    )

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    useEffect(() => {
        if (stores.storesLoaded && stores.selectedStore) {
            const subscription = discounts.listDiscounts().subscribe()
            subscriptions.push(subscription)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discounts, stores.stores, stores.selectedStore, stores.storesLoaded])

    return (
        <DashboardPageWrapper title="Discounts">
            {isCreateModalOpen && (
                <NewDiscountModal
                    isOpen={isCreateModalOpen}
                    setIsOpen={setIsCreateModalOpen}
                />
            )}
            {activeDiscount && isUpdateModalOpen && (
                <EditDiscountModal
                    isOpen={isUpdateModalOpen}
                    setIsOpen={setIsUpdateModalOpen}
                    discount={activeDiscount}
                />
            )}
            <Wrapper>
                <header className="top-row">
                    <Formik
                        onSubmit={() => {
                            //
                        }}
                        initialValues={{}}
                    >
                        {() => (
                            <>
                                <div className="flex"></div>
                                <div className="flex">
                                    <PrimaryButton
                                        small
                                        onClick={() => {
                                            setIsCreateModalOpen(true)
                                        }}
                                    >
                                        <span>Add new discount</span>
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </Formik>
                </header>
                <main className="main-view">
                    {discounts.discounts.length === 0 && (
                        <span className="flex mt-3">
                            You do not have any discounts
                        </span>
                    )}
                    {discounts.discounts.length !== 0 && (
                        <Table
                            data={discounts.discounts}
                            columns={[
                                {
                                    Header: 'Date created',
                                    accessor: 'created_at',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const { created_at: createdAt } =
                                            getCellItem(cell)
                                        const date = moment(createdAt)

                                        return (
                                            <span>
                                                {date.format('DD MMM yyyy')}
                                            </span>
                                        )
                                    },
                                },
                                {
                                    Header: 'Type',
                                    accessor: 'type',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const { type } = getCellItem(cell)
                                        const { background, color, text } =
                                            getStatusColors(type)

                                        return (
                                            <span
                                                className="inline-flex px-2"
                                                style={{
                                                    color,
                                                    borderRadius: 4,
                                                    background,
                                                }}
                                            >
                                                {text}
                                            </span>
                                        )
                                    },
                                },
                                {
                                    Header: 'Offer',
                                    accessor: 'offer',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const { offer, type } =
                                            getCellItem(cell)

                                        return (
                                            <b>
                                                {type === DiscountType.AMOUNT
                                                    ? formatAsCurrency(offer)
                                                    : `${offer}%`}
                                            </b>
                                        )
                                    },
                                },
                                {
                                    Header: 'Min total order',
                                    accessor: 'min_amount',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const { min_amount: minTotalOrder } =
                                            getCellItem(cell)

                                        return (
                                            <b>
                                                {formatAsCurrency(
                                                    minTotalOrder,
                                                )}
                                            </b>
                                        )
                                    },
                                },
                                {
                                    Header: 'Applied to',
                                    accessor: 'applied_to',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const { applied_to } = getCellItem(cell)
                                        const { background, color, text } =
                                            getStatusColors(applied_to)

                                        return (
                                            <span
                                                className="inline-flex px-2"
                                                style={{
                                                    color,
                                                    borderRadius: 4,
                                                    background,
                                                }}
                                            >
                                                {text}
                                            </span>
                                        )
                                    },
                                },

                                {
                                    Header: 'Status',
                                    accessor: 'status',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const { status } = getCellItem(cell)
                                        const { background, color, text } =
                                            getStatusColors(status)

                                        return (
                                            <span
                                                className="inline-flex px-2"
                                                style={{
                                                    color,
                                                    borderRadius: 4,
                                                    background,
                                                }}
                                            >
                                                {text}
                                            </span>
                                        )
                                    },
                                },
                                {
                                    Header: 'Action',
                                    Cell: ({ cell }: CellProps<Discount>) => {
                                        const discount = getCellItem(cell)
                                        return (
                                            <div className="flex">
                                                <TableButton
                                                    className="mr-2"
                                                    onClick={editDiscount(
                                                        discount,
                                                    )}
                                                >
                                                    <span>Edit discount</span>
                                                </TableButton>
                                                <DiscountDropdown
                                                    discount={discount}
                                                />
                                            </div>
                                        )
                                    },
                                },
                            ]}
                        />
                    )}
                </main>
            </Wrapper>
        </DashboardPageWrapper>
    )
})
