import { Menu } from '@headlessui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getClassNames } from '../../util'

export interface DropdownItem {
    label: string
    action?: (...args: any[]) => any
    href?: string
    disabled?: boolean
    className?: string
}

interface Props {
    button?: React.FC<{ className?: string; onClick?: (e: any) => any }>
    buttonContent?: React.ReactElement
    buttonText?: string
    buttonClassName?: string
    className?: string
    dropdownClassName?: string
    items: DropdownItem[]
}

const itemClassName = getClassNames(
    'h-8 text-left flex border-b border-[#bfbfbf] last:border-b-0',
    'items-center px-4 text-[10px] text-[#646464] font-bold cursor-pointer',
)

const itemDisabledClassName =
    '!backdrop-blur-sm !bg-[#e7e7e7] !cursor-not-allowed !text-[#b6b6b6]'

const preventPropagation = (handler?: Function) => {
    return (e: any) => {
        e?.stopPropagation?.()
        handler?.(e)
    }
}

export const Dropdown: React.FC<Props> = ({
    button,
    buttonContent,
    buttonText,
    buttonClassName,
    className,
    dropdownClassName,
    items,
}) => {
    const itemList = React.useMemo(() => {
        return items.map((item, i) => {
            const isLink = !!item.href

            if (isLink) {
                return (
                    <Menu.Item
                        as={Link}
                        to={item.href!}
                        className={getClassNames(
                            item.className,
                            itemClassName,
                            item.disabled && itemDisabledClassName,
                        )}
                        key={i}
                        onClick={preventPropagation(
                            item.disabled
                                ? (e: any) => e?.preventDefault?.()
                                : undefined,
                        )}
                        disabled={item.disabled}
                    >
                        <span>{item.label}</span>
                    </Menu.Item>
                )
            }

            return (
                <Menu.Item
                    as="button"
                    key={i}
                    onClick={preventPropagation(item.action)}
                    disabled={item.disabled}
                    className={getClassNames(
                        item.className,
                        itemClassName,
                        item.disabled && itemDisabledClassName,
                    )}
                >
                    <span>{item.label}</span>
                </Menu.Item>
            )
        })
    }, [items])

    return (
        <Menu as="div" className={getClassNames('relative flex', className)}>
            <Menu.Button
                as={button}
                onClick={preventPropagation()}
                className={getClassNames(buttonClassName)}
            >
                {buttonContent ?? <span>{buttonText}</span>}
            </Menu.Button>
            <Menu.Items
                className={getClassNames(
                    'absolute top-[calc(100%+10px)] right-0 grid grid-cols-1 w-[120px] max-w-[240px] bg-white border',
                    'border-[#bfbfbf] rounded-lg overflow-hidden z-[99]',
                    dropdownClassName,
                )}
            >
                {itemList}
            </Menu.Items>
        </Menu>
    )
}
