import React from 'react'
import styled from 'styled-components'
import { SideBar } from './side-bar'
import { TopBar } from './top-bar'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: var(--side-bar-width) 1fr;
    height: 100vh;

    .dashboard-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #f6f7f8;
        height: 100%;
    }

    .dashboard-body-content {
        height: calc(100vh - 4.25rem);
        overflow-y: auto;
    }
`

interface Props {
    title?: string
    children: React.ReactNode
}

export const DashboardPageWrapper: React.FC<Props> = ({ title, children }) => {
    return (
        <Wrapper>
            <SideBar />
            <main className="dashboard-body">
                <TopBar title={title} />
                <main className="dashboard-body-content">{children}</main>
            </main>
        </Wrapper>
    )
}
