import { useField } from 'formik'
import { map } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import Select, { OnChangeValue } from 'react-select'
import styled from 'styled-components'
import { getClassNames } from '../../util'
import { ValidationMessage } from '../messages'

const Wrapper = styled.label`
    display: flex;
    flex-direction: column;
    position: relative;

    .select__label {
        color: #333;
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
    }

    .select__placeholder {
        font-weight: normal;
        color: #c4c4c4;
    }

    .select__control {
        overflow: hidden;
        background: white;
        overflow: hidden;
        border: 1px solid #bfbfbf;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        --height: calc(2.5rem + 2px);
        min-height: var(--height);
        font-size: 0.75rem;
        font-weight: bold;
        color: #646464 !important;
        box-shadow: none !important;

        :focus-within,
        &.select__control--is-focused {
            border-color: black;
        }
    }

    .select__placeholder {
        color: #c4c4c4;
    }

    .select__input-container {
        font-size: 0.75rem;
    }

    .select__indicator-separator {
        background: none !important;
    }

    .select__multi-value {
        background: #f3f3f3;
        border-radius: 0.125rem;
    }

    .select__value-container {
        padding: 0.25rem 0.75rem;
    }

    .select__error-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        margin-top: 0.75rem;
    }
`

interface Props<OptionType> {
    name: string
    list: OptionType[]
    options: {
        display: keyof OptionType
        value: keyof OptionType
    }
    className?: string
    placeholder?: string
    label?: string
    isMulti?: boolean
    showMultipleErrors?: boolean
}

const Input = <OptionType,>({
    name,
    label,
    placeholder,
    className,
    options: optionTypes,
    list,
    isMulti,
    showMultipleErrors,
}: React.PropsWithChildren<Props<OptionType>>) => {
    const field = useField(name)
    const meta = field[1]
    const helpers = field[2]
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const valid = React.useMemo(() => !meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    const options = useMemo(() => {
        return list.map((option) => ({
            label: option[optionTypes.display],
            value: option[optionTypes.value],
        }))
    }, [list, optionTypes])

    const optionsMap = useMemo(() => {
        // tslint:disable-next-line: no-shadowed-variable
        const map: Record<string, any> = {}

        options.forEach((option) => {
            map[option.value as unknown as string] = option
        })

        return map
    }, [options])

    const value = useMemo(() => {
        return !isMulti
            ? optionsMap[field?.[0]?.value]
            : (field?.[0]?.value as string[])?.map?.((val) => optionsMap[val])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsMap, isMulti, field[0].value])

    const onChange = useCallback(
        (newValue: OnChangeValue<any, true>) => {
            helpers.setTouched(true)
            const val = Array.isArray(newValue)
                ? map(newValue, 'value')
                : (newValue as any).value
            helpers.setValue(val)
        },
        [helpers],
    )

    return (
        <Wrapper
            className={getClassNames(
                className,
                invalid && 'invalid',
                valid && 'valid',
            )}
        >
            <span className="select__label">{label}</span>
            <Select
                className="select"
                classNamePrefix="select"
                value={value}
                isMulti={isMulti}
                isSearchable
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                // Disabled for now due to edge cases where it looks terrible
                menuPortalTarget={document.getElementById(
                    'react-select-portal',
                )}
            />
            {invalid && (
                <div className="select__error-section">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <ValidationMessage
                                key={i}
                                message={message}
                                type="error"
                            />
                        ),
                    )}
                </div>
            )}
        </Wrapper>
    )
}

export const ReactSelectInput = observer(Input)
