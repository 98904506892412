import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    Modal,
    PrimaryButton,
    ReactSelectInput,
    TextInput,
} from '../../../../components'
import { Discount, EditDiscountModel } from '../../../../models'
import {
    cancelSubscriptions,
    discountStatusOptions,
    discountTypeOptions,
    useStores,
    validateModel,
} from '../../../../util'
import { ModalFormWrapper } from '../../common'

interface Props {
    setIsOpen: Dispatch<SetStateAction<boolean>>
    isOpen: boolean
    discount: Discount
}

export const EditDiscountModal: React.FC<Props> = observer(
    ({ discount, isOpen, setIsOpen }) => {
        const { discounts } = useStores()
        const subscriptions: Subscription[] = []

        const closeModal = useCallback(() => {
            return setIsOpen(false)
        }, [setIsOpen])

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        const editDiscount = useCallback(
            (
                values: EditDiscountModel,
                { setSubmitting }: FormikHelpers<EditDiscountModel>,
            ) => {
                setSubmitting(true)
                cancelSubscriptions(subscriptions)

                const subscription = discounts
                    .updateDiscount(discount.id, values)
                    .subscribe({
                        next(response) {
                            if (response.ok) {
                                setIsOpen(false)
                            }
                        },
                        complete() {
                            setSubmitting(false)
                        },
                    })

                subscriptions.push(subscription)
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [setIsOpen],
        )

        return (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit discount">
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={new EditDiscountModel(discount)}
                    onSubmit={editDiscount}
                >
                    {({ handleSubmit, isValid, isSubmitting }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid">
                                    <ReactSelectInput
                                        name="type"
                                        label="Type"
                                        placeholder="Discount type"
                                        list={discountTypeOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    <TextInput
                                        name="offer"
                                        label="Offer"
                                        placeholder="Offer"
                                    />
                                    <TextInput
                                        name="min_amount"
                                        label="Minimum amount"
                                        placeholder="Minimum amount"
                                        type="number"
                                    />
                                    <ReactSelectInput
                                        name="status"
                                        label="Status"
                                        placeholder="Discount status"
                                        list={discountStatusOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Edit discount</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
