import React from 'react'
import {
    getClassNames,
    ProductStatus,
    productStatusMap,
} from '../../../../util'

interface Props {
    status?: ProductStatus
}

export const ProductStatusBadge: React.FC<Props> = ({ status }) => {
    const isPublished = status !== ProductStatus.Unpublished

    return (
        <div
            className={getClassNames(
                'text-white flex !text-[8px] items-center font-bold flex-1 uppercase h-5 tracking-widest py-1 px-2 rounded-md',
                isPublished && 'bg-[#219653]',
                !isPublished && 'bg-[#CA2626]',
            )}
        >
            <span>{productStatusMap[status ?? ProductStatus.Published]}</span>
        </div>
    )
}
