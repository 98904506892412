import { UilBright, UilClockThree, UilEye } from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useMemo, useState } from 'react'
import { DashboardPageWrapper } from '../../common'
import { SettingsButton, SettingsButtonProps } from '../components'
import {
    StoreConfigurationModal,
    StoreInformationModal,
    StoreVisibilityModal,
    WorkingHoursModal,
} from './modals'

export const GeneralSettingsPage: React.FC = observer(() => {
    const [isWorkingHoursModalOpen, setIsWorkingHoursModalOpen] =
        useState(false)

    const [isStoreConfigurationModalOpen, setIsStoreConfigurationModalOpen] =
        useState(false)

    const [isStoreInformationModalOpen, setIsStoreInformationModalOpen] =
        useState(false)

    const [isStoreVisibilityModalOpen, setIsStoreVisibilityModalOpen] =
        useState(false)

    const settingsButtons: SettingsButtonProps[] = useMemo(
        () => [
            {
                icon: UilBright,
                title: 'Configuration',
                action: () => setIsStoreConfigurationModalOpen(true),
            },
            {
                icon: UilClockThree,
                title: 'Working hours',
                action: () => setIsWorkingHoursModalOpen(true),
            },
            {
                icon: UilClockThree,
                title: 'Store information',
                action: () => setIsStoreInformationModalOpen(true),
            },
            {
                icon: UilEye,
                title: 'Store visibility',
                action: () => setIsStoreVisibilityModalOpen(true),
            },
        ],
        [
            setIsWorkingHoursModalOpen,
            setIsStoreConfigurationModalOpen,
            setIsStoreInformationModalOpen,
            setIsStoreVisibilityModalOpen,
        ],
    )

    return (
        <>
            {isWorkingHoursModalOpen && (
                <WorkingHoursModal
                    isOpen={isWorkingHoursModalOpen}
                    setIsOpen={setIsWorkingHoursModalOpen}
                />
            )}
            {isStoreConfigurationModalOpen && (
                <StoreConfigurationModal
                    isOpen={isStoreConfigurationModalOpen}
                    setIsOpen={setIsStoreConfigurationModalOpen}
                />
            )}
            {isStoreInformationModalOpen && (
                <StoreInformationModal
                    isOpen={isStoreInformationModalOpen}
                    setIsOpen={setIsStoreInformationModalOpen}
                />
            )}
            {isStoreVisibilityModalOpen && (
                <StoreVisibilityModal
                    isOpen={isStoreVisibilityModalOpen}
                    setIsOpen={setIsStoreVisibilityModalOpen}
                />
            )}
            <DashboardPageWrapper title="General">
                <div className="px-10 py-6 overflow-y-auto flex">
                    <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-3">
                        {settingsButtons.map((props, i) => (
                            <SettingsButton key={i} {...props} />
                        ))}
                    </div>
                </div>
            </DashboardPageWrapper>
        </>
    )
})

export default GeneralSettingsPage
