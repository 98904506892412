import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    Modal,
    PrimaryButton,
    ReactSelectInput,
    SingleRangeInput,
    TextInput,
} from '../../../../../components'
import { UpdateStoreConfigurationModel } from '../../../../../models'
import {
    shoppingOptionOptions,
    useStores,
    validateModel,
} from '../../../../../util'
import { ModalFormWrapper } from '../../../common'
import { ProductImageUploader } from '../../../products/components'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

const subscriptions: Subscription[] = []
export const StoreConfigurationModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { stores, auth } = useStores()
        const storeCategories = stores.storeCategoriesFormatted

        const updateConfiguration = useCallback(
            async (
                values: UpdateStoreConfigurationModel,
                { setSubmitting }: FormikHelpers<UpdateStoreConfigurationModel>,
            ) => {
                setSubmitting(true)

                const subscription = stores
                    .updateConfiguration(await values.withProcessedImages())
                    .subscribe({
                        next(response) {
                            if (response.ok) {
                                setIsOpen(false)
                            }
                        },
                        complete() {
                            setSubmitting(false)
                        },
                    })

                subscriptions.push(subscription)
            },
            [setIsOpen, stores],
        )

        useEffect(() => {
            const subscription = stores.listStoreCategories().subscribe()
            return () => {
                subscription.unsubscribe()
            }
        }, [stores])

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Configuration">
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={
                        new UpdateStoreConfigurationModel(
                            auth.user!.partner!,
                            stores.selectedStore!,
                        )
                    }
                    onSubmit={updateConfiguration}
                >
                    {({ handleSubmit, isValid, isSubmitting }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid overflow-visible">
                                    <SingleRangeInput
                                        name="mile_radius"
                                        label="Mile radius"
                                        description="Area of coverage for the store's branches."
                                        min={0}
                                        max={10}
                                        step={1}
                                    />
                                    <ReactSelectInput
                                        name="shopping_option"
                                        label="Shopping option"
                                        placeholder="Select shopping option"
                                        list={shoppingOptionOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    <ProductImageUploader
                                        name="files"
                                        label="Logo"
                                        placeholder=""
                                    />
                                    <ReactSelectInput
                                        name="category"
                                        label="Store category"
                                        placeholder="Select category"
                                        list={storeCategories}
                                        options={{
                                            display: 'formattedName',
                                            value: 'id',
                                        }}
                                    />
                                    <span className="font-[500] tracking-[0.09em] text-xs">
                                        CONTACT INFO
                                    </span>
                                    <div className="grid grid-cols-2 gap-4">
                                        <TextInput
                                            className="col-span-1"
                                            name="contact_person.name"
                                            placeholder="Name"
                                            label="Contact name"
                                        />
                                        <TextInput
                                            className="col-span-1"
                                            name="contact_person.phone"
                                            placeholder="Phone"
                                            label="Contact phone"
                                        />
                                        <TextInput
                                            className="col-span-2"
                                            name="contact_person.email"
                                            placeholder="Email"
                                            label="Contact email"
                                        />
                                    </div>
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    <span>Save settings</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
