import { orderBy } from 'lodash'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { finalize, tap } from 'rxjs'
import { Order, OrderStatus, UpdateOrderStatusModel } from '../models'
import { OrdersService } from '../services'
import { Resettable } from '../util'

export class OrdersStore implements Resettable {
    public loading: boolean = false
    public orders: Order[] = []
    public currentOrder: Order | null = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public listOrders() {
        return OrdersService.listOrders().pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.orders = orderBy(
                            response.data,
                            'created_at',
                            'desc',
                        )
                    }
                })
            }),
        )
    }

    @action
    public retrieveOrder(id: string) {
        this.loading = true

        return OrdersService.retrieveOrder(id).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.currentOrder = response.data
                    }
                })
            }),
            finalize(() => {
                runInAction(() => {
                    this.loading = false
                })
            }),
        )
    }

    @action
    public updateOrderStatus(model: UpdateOrderStatusModel) {
        return OrdersService.updateOrderStatus(model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.currentOrder) {
                        this.currentOrder.status.status =
                            model.status as OrderStatus
                        this.currentOrder.status.reason = model.reason
                    }
                })
            }),
        )
    }

    @action
    public updateOrderItemStatus(model: UpdateOrderStatusModel) {
        return OrdersService.updateOrderItemStatus(model).pipe(
            tap((response) => {
                if (response.ok && this.currentOrder) {
                    this.retrieveOrder(this.currentOrder._id)
                }
            }),
        )
    }

    @action
    public reset(): void {
        this.loading = false
        this.orders = []
        this.currentOrder = null
    }
}
