import { UilWallet } from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { RouteLink } from '../../../../util'
import { DashboardPageWrapper } from '../../common'
import { SettingsButton, SettingsButtonProps } from '../components'

export const PaymentSettingsPage: React.FC = observer(() => {
    const settingsButtons: SettingsButtonProps[] = useMemo(
        () => [
            {
                icon: UilWallet,
                title: 'Transactions',
                link: RouteLink.Transactions,
            },
        ],
        [],
    )

    return (
        <>
            <DashboardPageWrapper title="Payment">
                <div className="px-10 py-6 overflow-y-auto flex">
                    <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-3">
                        {settingsButtons.map((props, i) => (
                            <SettingsButton key={i} {...props} />
                        ))}
                    </div>
                </div>
            </DashboardPageWrapper>
        </>
    )
})

export default PaymentSettingsPage
