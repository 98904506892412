import React from 'react'
import { InvertedButton, Modal, PrimaryButton } from '../../../../components'
import { ModalFormWrapper } from '../../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

export const AccountModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Account">
            <ModalFormWrapper>
                <main className="form__content">
                    <span>Placeholder</span>
                </main>
                <footer className="form__footer">
                    <InvertedButton
                        className="mr-3"
                        type="button"
                        onClick={() => setIsOpen(false)}
                    >
                        <span>Close</span>
                    </InvertedButton>
                    <PrimaryButton type="submit" disabled>
                        <span>Print code</span>
                    </PrimaryButton>
                </footer>
            </ModalFormWrapper>
        </Modal>
    )
}
