import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    Modal,
    PrimaryButton,
    ReactSelectInput,
    TextAreaInput,
} from '../../../../components'
import { Order, OrderStatus, UpdateOrderStatusModel } from '../../../../models'
import { useStores, validateModel } from '../../../../util'
import { ModalFormWrapper } from '../../common'

interface Props {
    order: Order
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    getOrders: () => any
}

interface StatusOption {
    value: OrderStatus
    display: string
}

const statuses: Record<string, StatusOption[]> = {
    [OrderStatus.NEW]: [
        { value: OrderStatus.PROCESSING, display: 'Processing' },
    ],
    [OrderStatus.PROCESSING]: [
        { value: OrderStatus.PROCESSED, display: 'Processed' },
    ],
    [OrderStatus.PROCESSED]: [
        { value: OrderStatus.DISPATCHED, display: 'Dispatched' },
    ],
}

export const UpdateOrderStatusModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, order, getOrders }) => {
        const { orders } = useStores()
        const subscriptions: Subscription[] = []

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Update order status"
            >
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={new UpdateOrderStatusModel(order)}
                    onSubmit={(values, { setSubmitting }) => {
                        unsubscribe()
                        setSubmitting(true)

                        const subscription = orders
                            .updateOrderStatus(values)
                            .subscribe({
                                next: (response) => {
                                    setSubmitting(false)

                                    if (response.ok) {
                                        getOrders()
                                        setIsOpen(false)
                                    }
                                },
                            })

                        subscriptions.push(subscription)
                    }}
                >
                    {({ isValid, isSubmitting, handleSubmit }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid">
                                    <ReactSelectInput
                                        name="status"
                                        label="New status"
                                        placeholder="Select new status"
                                        list={
                                            statuses[order.status.status] ?? []
                                        }
                                        options={{
                                            display: 'display',
                                            value: 'value',
                                        }}
                                    />
                                    <TextAreaInput
                                        name="reason"
                                        label="Reason for status change (optional)"
                                        placeholder="Enter reason"
                                    />
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Update status</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
