import { pick, set, uniq } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    lastValueFrom,
    map,
    of,
    tap,
} from 'rxjs'
import {
    BarcodeProduct,
    CreateProductModel,
    EditProductModel,
    EditProductQuantityModel,
    EditProductStatusModel,
    Product,
} from '../models'
import { ProductsService } from '../services'
import {
    getObjectDiff,
    HttpMethod,
    request,
    Resettable,
    Response,
} from '../util'
import { appState, MessageType } from './app.store'

interface Filters {
    search?: string
    id: string[]
}
export class ProductsStore implements Resettable {
    public loading: boolean = false
    public activeLoading: boolean = false
    public products: Product[] = []
    public activeProduct: Product | null = null
    public productsSearchResults: Product[] = []
    public filters: Filters = { id: [], search: '' }

    @computed
    public get normalizedFilters(): string {
        return JSON.stringify(this.filters)
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public setFilter(key: keyof Filters, value: string | string[]) {
        set(this.filters, key, value)

        this.filters = {
            ...this.filters,
        }
    }

    @action
    public listProducts() {
        this.loading = true

        return ProductsService.listProducts(this.filters).pipe(
            tap((response) => {
                runInAction(() => {
                    this.loading = false

                    if (response.data) {
                        this.products = response.data
                    }
                })
            }),
        )
    }

    @action
    public getDetailsByBarcode(barcode: string) {
        return lastValueFrom(
            request<never, { products: BarcodeProduct[] }>(
                `partner/products/barcode/${barcode}`,
                HttpMethod.GET,
                { silentErrors: true },
            ).pipe(
                map((response) => {
                    if (!response.ok) {
                        appState.createMessage(
                            'Item not found',
                            MessageType.ERROR,
                        )
                    }

                    return response.data?.products[0]
                }),
            ),
        )
    }

    @action
    public retrieveProduct(id: string) {
        this.activeLoading = true

        return request<never, Product>(
            `partner/products/${id}/single`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    this.activeLoading = false

                    if (response.data) {
                        this.activeProduct = response.data
                    }
                })
            }),
        )
    }

    @action
    public searchProducts(subject: BehaviorSubject<string>) {
        return subject.pipe(
            map((searchString) => searchString.trim()),
            distinctUntilChanged(),
            debounceTime(500),
            tap((searchString) => {
                runInAction(() => {
                    this.setFilter('search', searchString)
                })
            }),
        )
    }

    @action
    public createProduct(model: CreateProductModel) {
        return ProductsService.createProduct(model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listProducts().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public createMultipleProducts(models: CreateProductModel[]) {
        return ProductsService.createMultipleProducts(models).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listProducts().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public updateProduct(id: string, model: any) {
        this.products = this.products.map((product) =>
            product._id === id ? { ...product, ...model } : product,
        )
    }

    @action
    public editProduct(model: EditProductModel, product: Product) {
        const requiredKeys: (keyof EditProductModel)[] = [
            'id',
            'title',
            'sku',
            'image',
            'categories',
            'unit',
            'quantity',
            'price',
            'product_code',
            'description',
            'meta_data',
            'status',
            'image',
        ]

        const changedKeys = getObjectDiff(
            model,
            new EditProductModel(product),
        ) as (keyof EditProductModel)[]

        if (changedKeys.length === 0) {
            return of({ ok: true, status: 0 } as Response<Product>).pipe(
                tap(() => {
                    appState.createMessage('No changes made', MessageType.INFO)
                }),
            )
        }

        const changed = pick(model, ...uniq(requiredKeys.concat(changedKeys)))

        return ProductsService.editProduct(changed).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.updateProduct(model.id, model)
                    }
                })
            }),
        )
    }

    @action
    public editProductQuantity(model: EditProductQuantityModel) {
        return ProductsService.editProductQuantity(model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.updateProduct(model.id, model)
                    }
                })
            }),
        )
    }

    public editProductStatus(model: EditProductStatusModel) {
        return request<EditProductStatusModel, Product>(
            `partner/products/${model.id}/status`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating product status',
                completionMessage: 'Product status updated',
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listProducts().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.products = []
        this.productsSearchResults = []
        this.filters = { id: [] }
        this.activeLoading = false
        this.loading = false
        this.activeProduct = null
    }
}
