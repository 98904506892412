import React from 'react'
import { getClassNames } from '../../util'
import { ButtonProps, PrimaryButton } from './primary-button'

export const InvertedButton: React.FC<ButtonProps> = ({
    className,
    ...props
}) => {
    return (
        <PrimaryButton
            className={getClassNames(
                className,
                '!bg-white !text-primary !border-primary',
            )}
            {...props}
        />
    )
}
