import { IsEmail, MinLength } from 'class-validator'
import { BaseModel } from '../base.model'

export class EmailLoginModel implements BaseModel {
    @IsEmail(undefined, { message: 'Must be a valid email address' })
    public email: string = ''

    @MinLength(1, {
        message: 'Cannot be empty',
    })
    public password: string = ''
}
