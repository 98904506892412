import { IsEnum, IsNotEmpty, IsNumber, IsPositive } from 'class-validator'
import { clone } from 'lodash'
import { DiscountStatus, DiscountType } from '../../../util/constants'
import { Discount } from '../../response'

export class EditDiscountModel {
    @IsPositive({ message: 'Must be a positive number' })
    @IsNotEmpty({ message: 'Must be provided' })
    public offer: number = 0

    @IsEnum(DiscountType, { message: 'Must be a valid discount type' })
    @IsNotEmpty({ message: 'Must be provided' })
    public type: string = ''

    @IsNumber({}, { message: 'Must be at least zero' })
    @IsNotEmpty({ message: 'Must be provided' })
    public min_amount: number = 0

    @IsEnum(DiscountStatus, { message: 'Must be a valid discount status' })
    @IsNotEmpty({ message: 'Must be provided' })
    public status: string = ''

    public transform() {
        this.offer = +this.offer
        this.min_amount = +this.min_amount
    }

    constructor(discount?: Discount) {
        if (discount) {
            this.offer = discount.offer
            this.type = discount.type
            this.min_amount = discount.min_amount
            this.status = discount.status
        }
    }

    public getRequestBody() {
        return clone(this)
    }
}
