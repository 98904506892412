import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { InvertedButton } from '../../../../components'
import { Review } from '../../../../models'
import { getClassNames } from '../../../../util'
import { ReviewStars } from './review-stars'

interface Props {
    review: Review
    className?: string
    controls: ModalControls<Review>
}

export const ReviewBox: React.FC<Props> = observer(
    ({ review, controls, className }) => {
        const openReply = useCallback(() => {
            controls.setActive?.(review)
            controls.setIsOpen?.(true)
        }, [review, controls])

        return (
            <div
                className={getClassNames(
                    className,
                    `border border-[#F2F2F2] bg-white grid px-4 py-3 grid-cols-[255px_1fr] rounded-lg`,
                )}
            >
                <div className="flex flex-col">
                    <div className="grid grid-cols-[40px_1fr] gap-[10px] items-center">
                        <div className="h-10 w-10 bg-slate-100 rounded-full" />
                        <span className="text-xs font-bold">
                            {review.user.name}
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-1 mb-5">
                    <ReviewStars stars={review.star} />
                    <span className="text-sm mt-[10px]">{review.comment}</span>
                    <div className="flex mt-[20px]">
                        {!!review.admin_comment ? (
                            <InvertedButton small onClick={openReply}>
                                <span>See reply</span>
                            </InvertedButton>
                        ) : (
                            <InvertedButton small onClick={openReply}>
                                <span>Reply</span>
                            </InvertedButton>
                        )}
                    </div>
                </div>
            </div>
        )
    },
)
