import {
    UilBox,
    UilCreditCard,
    UilLock,
    UilRightIndentAlt,
    UilSitemap,
    UilUserCircle,
} from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useMemo, useState } from 'react'
import { RouteLink } from '../../../util'
import { DashboardPageWrapper } from '../common'
import { SettingsButton, SettingsButtonProps } from './components'
import { AccountModal } from './modals'

export const SettingsPage: React.FC = observer(() => {
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)
    const settingsButtons: SettingsButtonProps[] = useMemo(
        () => [
            {
                icon: UilBox,
                title: 'General',
                link: RouteLink.GeneralSettings,
            },
            {
                icon: UilUserCircle,
                title: 'Profile',
                link: RouteLink.Profile,
            },
            {
                icon: UilCreditCard,
                title: 'Payment',
                link: RouteLink.Payment,
            },
            {
                icon: UilRightIndentAlt,
                title: 'Subscription',
                link: RouteLink.Subscription,
            },
            {
                icon: UilSitemap,
                title: 'Integration',
                link: `/settings/integration`,
                inactive: true,
            },
            {
                icon: UilLock,
                title: 'Account',
                action: () => {
                    setIsAccountModalOpen(true)
                },
                inactive: true,
            },
        ],
        [],
    )

    return (
        <DashboardPageWrapper title="Settings">
            {isAccountModalOpen && (
                <AccountModal
                    isOpen={isAccountModalOpen}
                    setIsOpen={setIsAccountModalOpen}
                />
            )}
            <div className="px-10 py-6 overflow-y-auto flex">
                <div className="w-full grid grid-cols-4 gap-3">
                    {settingsButtons
                        .filter((button) => !button.inactive)
                        .map((props, i) => (
                            <SettingsButton key={i} {...props} />
                        ))}
                </div>
            </div>
        </DashboardPageWrapper>
    )
})

export default SettingsPage
