import { UilCheck } from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { interval, Subscription } from 'rxjs'
import styled from 'styled-components'
import { confirmDialog, PrimaryButton, TableButton } from '../../../components'
import { OrderStatus, UpdateOrderStatusModel } from '../../../models'
import {
    cancellableStatuses,
    formatAsCurrency,
    getOrderTimeliness,
    getStatusColors,
    updateableStatuses,
    useStores,
} from '../../../util'
import { DashboardPageWrapper, HeadedBox } from '../common'
import { OrderItemsBlock } from './components'
import { CancelOrderModal, UpdateOrderStatusModal } from './modals'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
`

export const OrderPage: React.FC = observer(() => {
    const { orderId } = useParams()
    const { orders } = useStores()
    const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] =
        useState(false)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const subscriptions: Subscription[] = []
    const [timelinessInformation, setTimelinessInformation] =
        useState<ReturnType<typeof getOrderTimeliness>>(null)
    const componentRef = useRef<any>(null)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const acceptOrder = useCallback(() => {
        if (
            !orders.currentOrder ||
            orders.currentOrder.status.status !== OrderStatus.NEW
        ) {
            return
        }

        const values = new UpdateOrderStatusModel(orders.currentOrder)
        values.status = OrderStatus.PROCESSING

        const subscription = orders.updateOrderStatus(values).subscribe({
            next: (response) => {
                if (response.ok && orders.currentOrder) {
                    orders.currentOrder.status.status = OrderStatus.PROCESSING
                }
            },
        })

        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders])

    const promptAcceptOrder = useCallback(() => {
        confirmDialog({
            onConfirm: () => acceptOrder(),
            title: 'Accept order',
            content: 'Are you sure you want to accept this order?',
            confirmText: 'Yes, accept',
        })
    }, [acceptOrder])

    const orderInformation = useMemo(() => {
        if (!orders.currentOrder) {
            return null
        }

        const subtotal = orders.currentOrder.orders.reduce((acc, order) => {
            return acc + +order.price * order.quantity
        }, 0)

        return {
            subtotal,
            total: subtotal + orders.currentOrder.shipping_fee,
            itemCount: orders.currentOrder.orders.length,
            status: getStatusColors(orders.currentOrder.status.status),
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders.currentOrder, orders.currentOrder?.status.status])

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateTimeliness = useCallback(() => {
        setTimelinessInformation(getOrderTimeliness(orders.currentOrder))
    }, [orders, setTimelinessInformation])

    const getOrder = useCallback(() => {
        const subscription = orders.retrieveOrder(orderId!).subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, orderId])

    useEffect(() => {
        if (orderId) {
            getOrder()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, orderId])

    useEffect(() => {
        const subscription = interval(1000).subscribe(updateTimeliness)
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTimeliness])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    if (orders.loading) {
        return <div>Loading...</div>
    }

    if (orders.currentOrder === null || orderInformation === null) {
        return <div>Order does not exist</div>
    }

    return (
        <DashboardPageWrapper title={`Order #${orders.currentOrder.reference}`}>
            <UpdateOrderStatusModal
                order={orders.currentOrder}
                isOpen={isUpdateStatusModalOpen}
                setIsOpen={setIsUpdateStatusModalOpen}
                getOrders={getOrder}
            />
            <CancelOrderModal
                order={orders.currentOrder}
                isOpen={isCancelModalOpen}
                setIsOpen={setIsCancelModalOpen}
            />
            <Wrapper>
                <header className="flex justify-end mb-8">
                    <PrimaryButton small onClick={handlePrint}>
                        <span>Print order</span>
                    </PrimaryButton>
                </header>
                <div className="main-view" ref={componentRef}>
                    <img
                        src="/img/logo/color/ethco_color_full.png"
                        alt="Logo"
                        className="mb-8 h-12 hidden print:block"
                    />
                    <h1 className="mb-4 hidden print:block">
                        Order #{orders.currentOrder.reference}
                    </h1>
                    <div className="grid grid-cols-[1fr_2fr] gap-4 flex-1">
                        <div className="print:mb-4">
                            <div className="grid grid-cols-1 gap-4">
                                <HeadedBox heading="Customer">
                                    <span className="text-[13px] font-bold mb-2.5">
                                        {orders.currentOrder.delivery?.name}
                                    </span>
                                    <a
                                        className="text-[12px] text-primary"
                                        href={`tel:${orders.currentOrder.delivery?.phone_number}`}
                                    >
                                        {
                                            orders.currentOrder.delivery
                                                ?.phone_number
                                        }
                                    </a>
                                </HeadedBox>
                                <HeadedBox heading="Shipping address">
                                    <span className="text-[13px] font-bold mb-2.5">
                                        {orders.currentOrder.delivery?.name}
                                    </span>
                                    <span>
                                        {orders.currentOrder.delivery?.address}
                                    </span>
                                </HeadedBox>
                                <HeadedBox heading="Billing address">
                                    <span className="text-[13px] font-bold mb-2.5">
                                        {orders.currentOrder.delivery?.name}
                                    </span>
                                    <span>
                                        {orders.currentOrder.delivery?.address}
                                    </span>
                                </HeadedBox>
                                {timelinessInformation && (
                                    <HeadedBox
                                        heading="Timeliness"
                                        className="print:!hidden"
                                    >
                                        <span>
                                            Updated{' '}
                                            {moment(
                                                orders.currentOrder.updated_at,
                                            ).fromNow()}
                                        </span>
                                        <div>
                                            <span
                                                className="inline-flex px-2 mt-2"
                                                style={{
                                                    color: timelinessInformation.color,
                                                    borderRadius: 4,
                                                    background:
                                                        timelinessInformation.background,
                                                }}
                                            >
                                                {timelinessInformation.text}
                                            </span>
                                        </div>
                                    </HeadedBox>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-1 gap-4">
                                <HeadedBox className="print:!hidden">
                                    <table className="clear-table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {moment(
                                                        orders.currentOrder
                                                            .created_at,
                                                    ).format(
                                                        'DD MMM yyyy hh:mm A',
                                                    )}
                                                </td>
                                                <td>
                                                    <b>
                                                        Total{' '}
                                                        {formatAsCurrency(
                                                            orderInformation.subtotal,
                                                        )}
                                                    </b>
                                                </td>
                                                <td>
                                                    <span
                                                        className="inline-flex px-2"
                                                        style={{
                                                            color: orderInformation
                                                                .status.color,
                                                            borderRadius: 4,
                                                            background:
                                                                orderInformation
                                                                    .status
                                                                    .background,
                                                        }}
                                                    >
                                                        {
                                                            orderInformation
                                                                .status.text
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    {updateableStatuses.includes(
                                                        orders.currentOrder
                                                            .status.status,
                                                    ) && (
                                                        <TableButton
                                                            onClick={() =>
                                                                setIsUpdateStatusModalOpen(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                Change status
                                                            </span>
                                                        </TableButton>
                                                    )}
                                                    {orders.currentOrder.status
                                                        .status ===
                                                        OrderStatus.NEW && (
                                                        <TableButton
                                                            onClick={() =>
                                                                promptAcceptOrder()
                                                            }
                                                        >
                                                            <span className="mr-2">
                                                                Approve
                                                            </span>
                                                            <UilCheck
                                                                style={{
                                                                    color: '#27AE60',
                                                                }}
                                                                size={12}
                                                            />
                                                        </TableButton>
                                                    )}
                                                    {cancellableStatuses.includes(
                                                        orders.currentOrder
                                                            .status.status,
                                                    ) && (
                                                        <TableButton
                                                            className="ml-2"
                                                            style={{
                                                                color: '#EB5757',
                                                                borderColor:
                                                                    '#EB5757',
                                                            }}
                                                            onClick={() =>
                                                                setIsCancelModalOpen(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            <span>Cancel</span>
                                                        </TableButton>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </HeadedBox>
                                <OrderItemsBlock order={orders.currentOrder} />
                                <HeadedBox heading="Transactions">
                                    <table className="clear-table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>
                                                        <b>Payment</b> via{' '}
                                                        {
                                                            orders.currentOrder
                                                                .payment_details
                                                                .type
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="flex justify-end">
                                                        <b>
                                                            {formatAsCurrency(
                                                                orders
                                                                    .currentOrder
                                                                    .payment_details
                                                                    .total_amount,
                                                            )}
                                                        </b>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </HeadedBox>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </DashboardPageWrapper>
    )
})

export default OrderPage
