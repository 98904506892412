import { UilCapture } from '@iconscout/react-unicons'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    PrimaryButton,
    StringInput,
} from '../../../../components'
import { Modal } from '../../../../components/misc/modal'
import { CreateProductModel } from '../../../../models'
import { useStores, validateModel } from '../../../../util'
import { mapBarcodeProductToCreateProductModel } from '../../../../util/mapper'
import { ModalFormWrapper } from '../../common'
import { ManualEntry } from '../components/entry/manual'
import { BarcodeScannerModal } from './barcode-scanner-modal'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

const subscriptions: Subscription[] = []

export const NewProductModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { products } = useStores()
        const [initialModel, setInitialModel] = useState(
            new CreateProductModel(),
        )
        const [barcode, setBarcode] = useState<string>('')
        const [showManualEntry, setShowManualEntry] = useState(false)
        const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false)

        const fetchProductDetails = useCallback(async () => {
            if (!barcode) return
            const product = await products.getDetailsByBarcode(barcode)

            if (!product) {
                setShowManualEntry(true)
                return
            }

            const model = mapBarcodeProductToCreateProductModel(product)

            setInitialModel(model)
            setShowManualEntry(true)
        }, [barcode, products])

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const onSubmit = useCallback(
            async (
                values: CreateProductModel,
                { setSubmitting }: FormikHelpers<CreateProductModel>,
            ) => {
                unsubscribe()
                setSubmitting(true)

                values = await values.withProcessedImages()
                const subscription = products.createProduct(values).subscribe({
                    next: (response) => {
                        setSubmitting(false)

                        if (response.ok) {
                            setIsOpen(false)
                        }
                    },
                })

                subscriptions.push(subscription)
            },
            [products, setIsOpen, unsubscribe],
        )

        const onScanBarcode = useCallback(
            async (barcode: string) => {
                setBarcode(barcode)
                await fetchProductDetails()
            },
            [setBarcode, fetchProductDetails],
        )

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Add new product"
            >
                {isBarcodeScannerOpen && (
                    <BarcodeScannerModal
                        isOpen={isBarcodeScannerOpen}
                        setIsOpen={setIsBarcodeScannerOpen}
                        onRead={onScanBarcode}
                    />
                )}
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={initialModel}
                    onSubmit={onSubmit}
                >
                    {({
                        isValid,
                        isSubmitting,
                        handleSubmit,
                        dirty,
                        submitCount,
                    }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <>
                                <main className="form__content">
                                    <div className="grid grid-cols-1 gap-4">
                                        {!showManualEntry && (
                                            <>
                                                <div className="flex items-end space-x-2">
                                                    <StringInput
                                                        title="Barcode"
                                                        label="Enter barcode"
                                                        name="barcode"
                                                        value={barcode}
                                                        onChange={setBarcode}
                                                        className="flex-1"
                                                    />

                                                    <InvertedButton
                                                        small
                                                        className="space-x-2"
                                                        type="button"
                                                        onClick={() =>
                                                            setIsBarcodeScannerOpen(
                                                                true,
                                                            )
                                                        }
                                                    >
                                                        <UilCapture size={16} />
                                                        <span>Scan</span>
                                                    </InvertedButton>
                                                </div>
                                                <div className="flex flex-col items-start space-y-4">
                                                    <PrimaryButton
                                                        small
                                                        type="button"
                                                        onClick={
                                                            fetchProductDetails
                                                        }
                                                    >
                                                        <span>
                                                            Fetch product
                                                            details
                                                        </span>
                                                    </PrimaryButton>
                                                </div>
                                            </>
                                        )}
                                        <button
                                            className="inline-flex"
                                            type="button"
                                            onClick={setShowManualEntry.bind(
                                                undefined,
                                                !showManualEntry,
                                            )}
                                        >
                                            <span className="text-xs underline text-primary">
                                                Enter product details{' '}
                                                {showManualEntry
                                                    ? 'using barcode'
                                                    : 'manually'}
                                            </span>
                                        </button>
                                        {showManualEntry && <ManualEntry />}
                                    </div>
                                </main>
                                <footer className="form__footer">
                                    <InvertedButton
                                        className="mr-3"
                                        type="button"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <span>Cancel</span>
                                    </InvertedButton>
                                    {showManualEntry && (
                                        <PrimaryButton
                                            type="submit"
                                            disabled={
                                                (!isValid ||
                                                    isSubmitting ||
                                                    !dirty) &&
                                                submitCount > 0
                                            }
                                        >
                                            <span>Add new product</span>
                                        </PrimaryButton>
                                    )}
                                </footer>
                            </>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
