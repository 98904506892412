import { entries } from 'lodash'
import { Option } from '../option'

export enum DiscountStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export const discountStatusMap = {
    [DiscountStatus.ACTIVE]: 'Active',
    [DiscountStatus.DISABLED]: 'Disabled',
}

export const discountStatusOptions: Option[] = entries(discountStatusMap).map(
    ([key, value]) => ({ key, value }),
)
