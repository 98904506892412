import { Formik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import { PrimaryButton, TextAreaInput, TextInput } from '../../components'
import { EmailSignupModel } from '../../models'
import { RouteLink, useStores } from '../../util'
import { validateModel } from '../../util/validation'
import { AuthPageWrapper } from './common'

export const SignUpPage: React.FC = () => {
    const { auth } = useStores()

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <Formik
                    validate={validateModel}
                    initialValues={new EmailSignupModel()}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)

                        auth.signUpWithEmail(values).subscribe({
                            complete: () => {
                                setSubmitting(false)
                            },
                        })
                    }}
                >
                    {({ isValid, isSubmitting, handleSubmit }) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <header className="auth-form-header">
                                <span>Sign up</span>
                            </header>
                            <main>
                                <TextInput
                                    name="name"
                                    label="Full name"
                                    placeholder="Full name"
                                    type="text"
                                />
                                <TextInput
                                    name="email"
                                    placeholder="Email address"
                                    label="Email address"
                                />
                                <TextInput
                                    name="business_name"
                                    label="Business name"
                                    placeholder="Business name"
                                    type="text"
                                />
                                <TextAreaInput
                                    name="description"
                                    label="Description"
                                    placeholder="Description"
                                />
                                <TextInput
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    label="Password"
                                />
                            </main>
                            <footer>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Sign up</span>
                                </PrimaryButton>
                            </footer>
                        </form>
                    )}
                </Formik>
                <div className="auth-form-tail">
                    <span>
                        Already have an account?{' '}
                        <Link to={RouteLink.LogIn}>Log in</Link>
                    </span>
                </div>
            </main>
        </AuthPageWrapper>
    )
}
