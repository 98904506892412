import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    Modal,
    PrimaryButton,
    ToggleInput,
} from '../../../../../components'
import { UpdateStoreVisibilityModel } from '../../../../../models'
import { useStores, validateModel } from '../../../../../util'
import { ModalFormWrapper } from '../../../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

const subscriptions: Subscription[] = []
export const StoreVisibilityModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { stores } = useStores()

        const updateVisibility = useCallback(
            (
                values: UpdateStoreVisibilityModel,
                { setSubmitting }: FormikHelpers<UpdateStoreVisibilityModel>,
            ) => {
                setSubmitting(true)

                const subscription = stores.setVisibility(values).subscribe({
                    next(response) {
                        if (response.ok) {
                            setIsOpen(false)
                        }
                    },
                    complete() {
                        setSubmitting(false)
                    },
                })

                subscriptions.push(subscription)
            },
            [setIsOpen, stores],
        )

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Visibility">
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={
                        new UpdateStoreVisibilityModel(stores.selectedStore!)
                    }
                    onSubmit={updateVisibility}
                >
                    {({
                        handleSubmit,
                        values,
                        isValid,
                        submitCount,
                        dirty,
                        isSubmitting,
                    }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid overflow-visible">
                                    <ToggleInput
                                        name="isLive"
                                        label="Show store to customers?"
                                    />
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!dirty || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    <span>Save visibility</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
