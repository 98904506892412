import { HTMLMotionProps, motion } from 'framer-motion'
import styled from 'styled-components'
import { getClassNames } from '../../util'

export interface ButtonProps extends HTMLMotionProps<'button'> {
    isLoading?: boolean
    small?: boolean
}

const Button = styled(motion.button)`
    background: var(--primary);
    border-radius: 2rem;
    text-decoration: none;
    color: white;
    font-family: inherit;
    font-weight: bold;
    font-size: 0.75rem;
    border: 1px solid var(--primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: left;
    outline: none;
    white-space: nowrap;

    :focus-within {
        border-color: black;
    }

    &.cancel {
        background: #b6b6b6;
        border-color: #b6b6b6;
    }

    :disabled {
        backdrop-filter: blur(0.25rem);
        border-color: #b6b6b6 !important;
        color: #b6b6b6 !important;
        background: #e7e7e7;
        cursor: not-allowed !important;
    }

    .button__icon {
        height: 1rem;
        width: 1rem;
        stroke-width: 3px;
        stroke-linecap: square;
    }

    .button__icon + * {
        margin-left: 0.5rem;
    }
`

export const PrimaryButton: React.FC<ButtonProps> = ({
    children,
    isLoading,
    small,
    onClick,
    className,
    ...props
}) => {
    return (
        <Button
            className={getClassNames(
                className,
                small ? '!h-10' : 'h-12',
                small ? 'px-6' : 'px-8',
                isLoading && '!cursor-progress',
            )}
            onClick={isLoading ? (e) => e.preventDefault() : onClick}
            whileHover={!isLoading ? { scale: 1.025 } : undefined}
            whileTap={!isLoading ? { scale: 0.95 } : undefined}
            {...props}
        >
            {isLoading ? <span>Loading...</span> : children}
        </Button>
    )
}
