import { Allow } from 'class-validator'
import { Category } from '../../response'
import { CreateCategoryModel } from './create-category.model'

export class EditCategoryModel extends CreateCategoryModel {
    @Allow()
    public id: string

    constructor(category: Category) {
        super()
        this.id = category.id
        this.title = category.title
    }
}
