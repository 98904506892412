import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { MakeCommentModel, Review } from '../models'
import { HttpMethod, request, Resettable, stores } from '../util'

export class ReviewsStore implements Resettable {
    public reviews: Review[] = []
    public activeReview: Review | null = null
    public loading: boolean = false
    public ready: boolean = false

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public listReviews() {
        this.loading = true
        const storeId = stores.stores.selectedStore?.id

        return request<never, Review[]>(
            `partner/reviews/${storeId}`,
            HttpMethod.GET,
            { loadingMessage: 'Fetching reviews' },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    this.loading = false

                    if (response.data) {
                        this.reviews = response.data
                    }
                })
            }),
        )
    }

    @action
    public makeComment(reviewId: string, model: MakeCommentModel) {
        return request<MakeCommentModel, never>(
            `partner/reviews/${reviewId}/comment`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating review comment',
                completionMessage: 'Review comment updated',
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        stores.auth.getUser().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.reviews = []
        this.activeReview = null
        this.ready = false
        this.loading = false
    }
}
