import { Allow } from 'class-validator'
import { Store } from '../../response'
import { CreateStoreModel } from './create-store.model'

export class EditStoreModel extends CreateStoreModel {
    @Allow()
    public id: string

    constructor(store: Store) {
        super(store)
        this.id = store.id
        this.mile_radius = store.mile_radius
        this.category = store.category
    }
}
