import { Formik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    PrimaryButton,
    ReactSelectInput,
    TextInput,
} from '../../../components'
import { Modal } from '../../../components/misc/modal'
import { CreateStoreModel } from '../../../models'
import { useStores } from '../../../util'
import { validateModel } from '../../../util/validation'
import { ModalFormWrapper } from '../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

export const NewStoreModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    const { stores } = useStores()
    const subscriptions: Subscription[] = []

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const subscription = stores.listStoreCategories().subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Add new store">
            <Formik
                validate={validateModel}
                initialValues={new CreateStoreModel()}
                onSubmit={(values, { setSubmitting }) => {
                    unsubscribe()
                    setSubmitting(true)

                    const subscription = stores.createStore(values).subscribe({
                        next: (response) => {
                            setSubmitting(false)

                            if (response.ok) {
                                setIsOpen(false)
                            }
                        },
                    })

                    subscriptions.push(subscription)
                }}
            >
                {({ isValid, isSubmitting, handleSubmit }) => (
                    <ModalFormWrapper onSubmit={handleSubmit}>
                        <main className="form__content">
                            <div className="form__content-grid">
                                <TextInput
                                    name="name"
                                    label="Name"
                                    placeholder="Enter name"
                                />
                                <TextInput
                                    name="street"
                                    label="Street"
                                    placeholder="Enter street"
                                />
                                <TextInput
                                    name="town"
                                    label="Town"
                                    placeholder="Enter town"
                                />
                                <TextInput
                                    name="postcode"
                                    label="Postcode"
                                    placeholder="Enter postcode"
                                />
                                <TextInput
                                    name="mile_radius"
                                    label="Delivery mile radius"
                                    placeholder="Enter mile radius"
                                />
                                <ReactSelectInput
                                    name="category"
                                    label="Category"
                                    placeholder="Select category"
                                    list={stores.storeCategoriesFormatted}
                                    options={{
                                        display: 'formattedName',
                                        value: 'id',
                                    }}
                                />
                            </div>
                        </main>
                        <footer className="form__footer">
                            <InvertedButton
                                className="mr-3"
                                type="button"
                                onClick={() => setIsOpen(false)}
                            >
                                <span>Cancel</span>
                            </InvertedButton>
                            <PrimaryButton
                                type="submit"
                                disabled={!isValid || isSubmitting}
                            >
                                <span>Add new store</span>
                            </PrimaryButton>
                        </footer>
                    </ModalFormWrapper>
                )}
            </Formik>
        </Modal>
    )
}
