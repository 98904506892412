import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;

    .headed-box__heading {
        padding: 1rem;
        padding-bottom: 0;
        font-family: 'Dosis';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }

    .headed-box__content {
        padding: 1rem;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #000;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .headed-box__footer {
        padding: 1rem;
        background: #f2f2f2;
    }

    .clear-table {
        width: 100%;

        td {
            padding-right: 0.5rem;
            align-items: center;
        }

        td:last-of-type {
            padding: 0;
        }
    }
`

interface Props extends React.ComponentPropsWithoutRef<'div'> {
    heading?: string
    footerElement?: React.ReactElement
}

export const HeadedBox: React.FC<Props> = ({
    heading,
    children,
    footerElement,
    ...props
}) => {
    return (
        <Wrapper {...props}>
            {heading && (
                <header className="headed-box__heading">
                    <span>{heading}</span>
                </header>
            )}
            <main className="headed-box__content">{children}</main>
            {footerElement && (
                <div className="headed-box__footer">{footerElement}</div>
            )}
        </Wrapper>
    )
}
