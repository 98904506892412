import { UilQrcodeScan } from '@iconscout/react-unicons'
import React, { useCallback } from 'react'
import { TableButton } from '../../../../../components'
import { Product } from '../../../../../models'
import { ProductDropdown } from './product-dropdown'

interface Props {
    product: Product
    setActiveProduct: (...args: any[]) => any
    setIsEditModalOpen: (...args: any[]) => any
    setIsBarcodeModalOpen: (...args: any[]) => any
}

export const ProductActions: React.FC<Props> = ({
    product,
    setActiveProduct,
    setIsEditModalOpen,
    setIsBarcodeModalOpen,
}) => {
    const editProduct = useCallback(() => {
        setActiveProduct(product)
        setIsEditModalOpen(true)
    }, [product, setActiveProduct, setIsEditModalOpen])

    const openQRCode = useCallback(() => {
        setActiveProduct(product)
        setIsBarcodeModalOpen(true)
    }, [product, setActiveProduct, setIsBarcodeModalOpen])

    return (
        <div className="flex">
            <TableButton className="mr-2" onClick={editProduct}>
                <span>Edit product</span>
            </TableButton>
            <TableButton className="mr-2" onClick={openQRCode}>
                <UilQrcodeScan size={12} />
            </TableButton>
            <ProductDropdown product={product} />
        </div>
    )
}
