import { remove, uniqueId } from 'lodash'
import { action, makeAutoObservable } from 'mobx'
import { finalize, Observable } from 'rxjs'
import { Resettable } from '../util'

export enum MessageType {
    INFO,
    SUCCESS,
    WARNING,
    LOADING,
    ERROR,
}

interface Task {
    id: string
    message: string
    messageType: MessageType
    silent: boolean
    timeout?: NodeJS.Timeout
}

class AppState implements Resettable {
    public queue: Task[] = []

    public get isLoading(): boolean {
        return this.queue.length > 0
    }

    @action
    private _createTask(
        message: string,
        silent: boolean,
        messageType: MessageType = MessageType.LOADING,
    ): Task {
        const id = uniqueId()
        const task: Task = { id, message, silent, messageType }
        this.queue.push(task)

        return task
    }

    @action
    private _removeTask(task: Task): void {
        if (task.timeout) {
            clearTimeout(task.timeout)
        }

        remove(this.queue, { id: task.id })
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public createObservableTask<T>(
        item: Observable<T>,
        message: string = 'Task loading',
        silent: boolean = false,
    ): Observable<T> {
        const task = this._createTask(message, silent)

        return item.pipe(
            finalize(() => {
                this._removeTask(task)
            }),
        )
    }

    @action
    public createPromiseTask<T>(
        item: Promise<T>,
        message: string = 'Task loading',
        silent: boolean = false,
    ): Promise<T> {
        const task = this._createTask(message, silent)

        item.then((x) => {
            this._removeTask(task)
            return x
        }).catch((x) => {
            this._removeTask(task)
            return x
        })

        return item
    }

    @action
    public createMessage(message: string, messageType: MessageType): void {
        const task = this._createTask(message, false, messageType)

        task.timeout = setTimeout(() => {
            this._removeTask(task)
        }, 5000)
    }

    @action
    public closeMessage(task: Task): void {
        return this._removeTask(task)
    }

    @action
    public reset(): void {
        this.queue.forEach(this._removeTask)
    }
}

export const appState = new AppState()
