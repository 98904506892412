import moment from 'moment'

const MINIMUM_DIFF_MINUTES = 1

export const areWorkingHoursValid = (
    opensAt?: string,
    closesAt?: string,
): boolean => {
    if (!opensAt || !closesAt) {
        return false
    }

    const startTime = moment(opensAt, 'HH:mm')
    const endTime = moment(closesAt, 'HH:mm')

    if (!startTime.isValid() || !endTime.isValid()) {
        return false
    }

    const diffInMinutes = endTime.diff(startTime, 'minute')

    if (diffInMinutes < MINIMUM_DIFF_MINUTES) {
        return false
    }

    return true
}
