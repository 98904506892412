import { find } from 'lodash'
import { CreateProductModel } from '../../models'
import { BarcodeProduct } from '../../models/response'

export const mapBarcodeProductToCreateProductModel = (
    product: BarcodeProduct,
): CreateProductModel => {
    const {
        barcode_number,
        mpn,
        asin,
        images,
        stores,
        title,
        model,
        description,
    } = product

    const image = images[0]
    const price = find(stores, { currency: 'GBP' })?.price ?? 0

    return CreateProductModel.fromInitial({
        product_code: barcode_number,
        sku: mpn ?? asin,
        files: [image],
        price: +price,
        description,
        title: title ?? model,
    })
}
