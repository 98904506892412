import { Observable } from 'rxjs'
import { Category, CreateCategoryModel, EditCategoryModel } from '../models'
import { HttpMethod, request, Response } from '../util'

export class CategoriesService {
    public static listCategories(
        silent?: boolean,
    ): Observable<Response<Category[]>> {
        return request<never, Category[]>(`partner/categories`, HttpMethod.GET)
    }

    public static retrieveCategory(id: string): Observable<Response<Category>> {
        return request<never, Category>(
            `partner/categories/${id}`,
            HttpMethod.GET,
        )
    }

    public static createCategory(
        model: CreateCategoryModel,
        silent?: boolean,
    ): Observable<Response<Category>> {
        return request<CreateCategoryModel, Category>(
            `partner/categories`,
            HttpMethod.POST,
            {
                body: model,
                loadingMessage: silent ? undefined : 'Creating category',
                completionMessage: silent ? undefined : 'Category created',
            },
        )
    }

    public static editCategory(
        model: EditCategoryModel,
    ): Observable<Response<Category>> {
        return request<EditCategoryModel, Category>(
            `partner/categories/${model.id}`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating category',
                completionMessage: 'Category updated',
            },
        )
    }
}
