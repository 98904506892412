import { UilSearch } from '@iconscout/react-unicons'
import { Formik } from 'formik'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { CellProps } from 'react-table'
import { BehaviorSubject, Subscription } from 'rxjs'
import styled from 'styled-components'
import {
    InvertedButton,
    PrimaryButton,
    SelectInput,
    StatusTag,
    TextInput,
} from '../../../components'
import { Loader } from '../../../components/layout/loader'
import { Table } from '../../../components/misc/table'
import { Product } from '../../../models'
import { formatAsCurrency, getCellItem, useStores } from '../../../util'
import { DashboardPageWrapper } from '../common'
import { ProductActions, ProductGridItem } from './components'
import {
    BulkUploadProductModal,
    EditProductModal,
    NewProductModal,
} from './modals'
import { ProductBarcodeModal } from './modals/product-barcode.modal'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .main-view {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex: 1 1 auto;
    }
`

export const ProductsPage: React.FC = observer(() => {
    const [query] = useSearchParams()
    const isListView = useMemo(() => !!Number(query.get('list')), [query])
    const { products, categories, stores } = useStores()
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false)
    const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false)
    const [activeProduct, setActiveProduct] = useState<Product | null>(null)
    const subscriptions: Subscription[] = []
    const [searchStringSubject] = useState(new BehaviorSubject(''))
    const loading =
        products.loading || !categories.categoriesLoaded || !stores.storesLoaded
    const productList = products.products
    const categoryOptions = categories.categories
    const selectedCategoryIds = products.filters.id

    const search = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            searchStringSubject.next(e.target.value)
        },
        [searchStringSubject],
    )

    const setCategories = useCallback(
        (selectedCategories: string[]) => {
            products.setFilter('id', selectedCategories)
        },
        [products],
    )

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const subscription = products
            .searchProducts(searchStringSubject)
            .subscribe()

        return () => {
            subscription.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    useEffect(() => {
        const subscription = categories.listCategories().subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories])

    useEffect(() => {
        if (stores.storesLoaded && stores.selectedStore) {
            const subscription = products.listProducts().subscribe()
            return () => subscription.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        products,
        products.normalizedFilters,
        stores.stores,
        stores.selectedStore,
        stores.storesLoaded,
    ])

    return (
        <DashboardPageWrapper title="Products">
            {isCreateModalOpen && (
                <NewProductModal
                    isOpen={isCreateModalOpen}
                    setIsOpen={setIsCreateModalOpen}
                />
            )}
            {isBulkUploadModalOpen && (
                <BulkUploadProductModal
                    isOpen={isBulkUploadModalOpen}
                    setIsOpen={setIsBulkUploadModalOpen}
                />
            )}
            {isEditModalOpen && (
                <EditProductModal
                    isOpen={isEditModalOpen}
                    setIsOpen={setIsEditModalOpen}
                    product={activeProduct}
                />
            )}
            {isBarcodeModalOpen && (
                <ProductBarcodeModal
                    isOpen={isBarcodeModalOpen}
                    setIsOpen={setIsBarcodeModalOpen}
                    product={activeProduct}
                />
            )}
            <Wrapper>
                <header className="top-row">
                    <Formik
                        onSubmit={() => {
                            // Do nothing
                        }}
                        initialValues={{ id: [] }}
                    >
                        {() => (
                            <div className="grid grid-cols-1 gap-4 lg:flex w-full justify-between flex-nowrap lg:space-x-4">
                                <div className="flex flex-1">
                                    <TextInput
                                        name="search"
                                        placeholder="Search for product"
                                        className="mr-2"
                                        onInput={search}
                                        value={searchStringSubject.value}
                                        onBlur={search}
                                        icon={UilSearch}
                                    />
                                    <SelectInput
                                        name="id"
                                        className="flex-1"
                                        placeholder="Filter categories"
                                        multiple
                                        options={categoryOptions}
                                        position="bottom"
                                        accessor={{
                                            value: 'id',
                                            display: 'title',
                                        }}
                                        value={selectedCategoryIds}
                                        onChange={setCategories}
                                    />
                                </div>
                                <div className="flex">
                                    {isListView && (
                                        <Link to="?list=0">
                                            <InvertedButton
                                                small
                                                className="mr-2"
                                            >
                                                <span>Grid view</span>
                                            </InvertedButton>
                                        </Link>
                                    )}
                                    {!isListView && (
                                        <Link to="?list=1">
                                            <InvertedButton
                                                small
                                                className="mr-2"
                                            >
                                                <span>List view</span>
                                            </InvertedButton>
                                        </Link>
                                    )}
                                    <InvertedButton
                                        small
                                        className="mr-2"
                                        onClick={() =>
                                            setIsBulkUploadModalOpen(true)
                                        }
                                    >
                                        <span>Bulk upload</span>
                                    </InvertedButton>
                                    <PrimaryButton
                                        small
                                        onClick={() =>
                                            setIsCreateModalOpen(true)
                                        }
                                    >
                                        <span>Add new product</span>
                                    </PrimaryButton>
                                </div>
                            </div>
                        )}
                    </Formik>
                </header>
                <div></div>
                <main className="main-view">
                    <Loader loading={loading}>
                        {!productList.length &&
                            (!products.filters.search ? (
                                <span>You do not have any products</span>
                            ) : (
                                <span>No results found</span>
                            ))}
                        {!isListView && productList.length !== 0 && (
                            <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4">
                                {productList.map((product, i) => (
                                    <ProductGridItem
                                        product={product}
                                        setActiveProduct={setActiveProduct}
                                        setIsEditModalOpen={setIsEditModalOpen}
                                        key={i}
                                    />
                                ))}
                            </div>
                        )}
                        <div className="relative">
                            {isListView && productList.length !== 0 && (
                                <Table
                                    data={productList}
                                    columns={[
                                        {
                                            Header: 'Created',
                                            accessor: 'created_at',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Product>) => {
                                                const { created_at } =
                                                    getCellItem(cell)
                                                const date = moment(created_at)

                                                return (
                                                    <div className="flex flex-col">
                                                        <span>
                                                            {date.format(
                                                                'DD MMM yyyy',
                                                            )}
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: '#646464',
                                                            }}
                                                        >
                                                            {date.format(
                                                                'hh:mm A',
                                                            )}
                                                        </span>
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Product',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Product>) => {
                                                const product =
                                                    cell.row.original

                                                return (
                                                    <button className="flex text-[#646464] text-left">
                                                        <div
                                                            className="h-9 w-9 bg-contain bg-center bg-no-repeat"
                                                            style={{
                                                                backgroundImage: `url(${product.image[0]})`,
                                                            }}
                                                        />
                                                        <div className="flex flex-col ml-2">
                                                            <b>
                                                                {product.title}
                                                            </b>
                                                            <span>
                                                                {
                                                                    product.product_code
                                                                }
                                                            </span>
                                                        </div>
                                                    </button>
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Stock',
                                            accessor: 'quantity',
                                        },
                                        {
                                            Header: 'Status',
                                            accessor: 'status',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Product>) => {
                                                const product =
                                                    getCellItem(cell)

                                                return (
                                                    <StatusTag
                                                        value={product.status}
                                                    />
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Price',
                                            accessor: 'price',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Product>) => {
                                                const product =
                                                    getCellItem(cell)

                                                return (
                                                    <b className="whitespace-nowrap">
                                                        {formatAsCurrency(
                                                            product.price,
                                                        )}
                                                    </b>
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Action',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Product>) => {
                                                const product =
                                                    getCellItem(cell)

                                                return (
                                                    <ProductActions
                                                        product={product}
                                                        setActiveProduct={
                                                            setActiveProduct
                                                        }
                                                        setIsBarcodeModalOpen={
                                                            setIsBarcodeModalOpen
                                                        }
                                                        setIsEditModalOpen={
                                                            setIsEditModalOpen
                                                        }
                                                    />
                                                )
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </Loader>
                </main>
            </Wrapper>
        </DashboardPageWrapper>
    )
})

export default ProductsPage
