import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { BehaviorSubject, Subscription } from 'rxjs'
import {
    InvertedButton,
    Modal,
    MultiTextInput,
    PrimaryButton,
    ReactSelectInput,
    ReactSelectInputCreatable,
    TextInput,
} from '../../../../../components'
import { UpdateStoreInformationModel } from '../../../../../models'
import {
    getClassNames,
    PolarAnswer,
    polarAnswerOptions,
    useStores,
    validateModel,
} from '../../../../../util'
import { ModalFormWrapper } from '../../../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

const subscriptions: Subscription[] = []
export const StoreInformationModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { stores, misc } = useStores()
        const [postcodeSubject] = useState<BehaviorSubject<string>>(
            new BehaviorSubject(''),
        )

        const postcodeInformation = misc.postcodeInformation
        const addresses = postcodeInformation?.addresses ?? []

        const search = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                postcodeSubject.next(e.target.value)
            },
            [postcodeSubject],
        )

        useEffect(() => {
            const subscription = misc.getPostcodeSubscription(postcodeSubject)
            return () => {
                subscription.unsubscribe()
            }
        }, [postcodeSubject, misc])

        const updateInformation = useCallback(
            (
                values: UpdateStoreInformationModel,
                { setSubmitting }: FormikHelpers<UpdateStoreInformationModel>,
            ) => {
                setSubmitting(true)

                const subscription = stores
                    .updateInformation(values)
                    .subscribe({
                        next(response) {
                            if (response.ok) {
                                setIsOpen(false)
                            }
                        },
                        complete() {
                            setSubmitting(false)
                        },
                    })

                subscriptions.push(subscription)
            },
            [setIsOpen, stores],
        )

        useEffect(() => {
            stores.listStoreCategories().subscribe()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            if (stores.selectedStore) {
                postcodeSubject.next(stores.selectedStore.postcode)
            }
        }, [postcodeSubject, stores.selectedStore])

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Information">
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={
                        new UpdateStoreInformationModel(stores.selectedStore!)
                    }
                    onSubmit={updateInformation}
                >
                    {({
                        handleSubmit,
                        values,
                        isValid,
                        submitCount,
                        setTouched,
                        isSubmitting,
                    }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid overflow-visible">
                                    <TextInput
                                        name="name"
                                        placeholder="Name"
                                        label="Store name"
                                    />
                                    <TextInput
                                        name="email"
                                        placeholder="Email"
                                        label="Store email (optional)"
                                    />
                                    <TextInput
                                        name="postcode"
                                        label="Store postcode"
                                        onInput={search}
                                        onBlur={(e) => {
                                            search(e)
                                            setTouched({ postcode: true }, true)
                                        }}
                                        placeholder="Enter postcode"
                                    />
                                    <div
                                        className={getClassNames(
                                            'text-[10px] text-[#646464] flex space-x-4 -mt-2',
                                        )}
                                    >
                                        <span>
                                            Longitude: {values.longitude}
                                        </span>
                                        <span>Latitude: {values.latitude}</span>
                                    </div>
                                    <ReactSelectInputCreatable
                                        name="street"
                                        placeholder="Address"
                                        label="Store address"
                                        list={addresses.map((address) => ({
                                            display: address,
                                            value: address,
                                        }))}
                                        options={{
                                            display: 'display',
                                            value: 'value',
                                        }}
                                    />
                                    <ReactSelectInput
                                        name="feedback.other_pos"
                                        label="Do you have an electric point of sale system?"
                                        placeholder="Select option"
                                        list={polarAnswerOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {values.feedback.other_pos ===
                                        PolarAnswer.Yes && (
                                        <TextInput
                                            name="feedback.epos_brand"
                                            placeholder="Brand name"
                                            label="Name of EPOS brand"
                                        />
                                    )}
                                    <ReactSelectInput
                                        name="feedback.has_internet"
                                        label="Do you have internet connection in the store?"
                                        placeholder="Select option"
                                        list={polarAnswerOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {values.feedback.has_internet ===
                                        PolarAnswer.Yes && (
                                        <TextInput
                                            name="feedback.internet_provider"
                                            placeholder="Internet Service Provider name"
                                            label="Name of Internet Service Provider"
                                        />
                                    )}
                                    {values.feedback.has_internet ===
                                        PolarAnswer.No && (
                                        <ReactSelectInput
                                            name="feedback.internet_pay"
                                            label="Are you willing to pay for cheap internet?"
                                            placeholder="Select option"
                                            list={polarAnswerOptions}
                                            options={{
                                                display: 'value',
                                                value: 'key',
                                            }}
                                        />
                                    )}
                                    <ReactSelectInput
                                        name="feedback.has_website"
                                        label="Do you have a website?"
                                        placeholder="Select option"
                                        list={polarAnswerOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {values.feedback.has_website ===
                                        PolarAnswer.Yes && (
                                        <TextInput
                                            name="feedback.website_url"
                                            placeholder="www.example.com"
                                            label="Website URL"
                                        />
                                    )}
                                    <TextInput
                                        name="feedback.products_in_store"
                                        placeholder="Number of unique products"
                                        label="What is the number of unique products you think you have in the store?"
                                        type="number"
                                    />
                                    <MultiTextInput
                                        name="feedback.suppliers"
                                        placeholder="Enter supplier names"
                                        label="Enter names of major suppliers / cash and carry you purchase goods from"
                                        useComma
                                    />
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={
                                        submitCount > 0 &&
                                        (!isValid || isSubmitting)
                                    }
                                    isLoading={isSubmitting}
                                >
                                    <span>Save settings</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
