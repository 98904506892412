import styled from 'styled-components'

export const ModalFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;

    .form__content {
        flex: 1;
        padding: 1.75rem;
        padding-top: 0;
        overflow-y: auto;
    }

    .form__content-grid {
        display: grid;
        width: 100%;
        grid-template-columns: minmax(0, 1fr);
        gap: 1rem;
        padding-top: 0;
    }

    .form-split {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
    }

    .form__footer {
        padding: 0.875rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        width: 100%;
    }
`
