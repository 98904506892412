import { Allow } from 'class-validator'
import { Product } from '../../response'
import { BaseModel } from '../base.model'
import { CreateProductModel } from './create-product.model'

export class EditProductModel extends CreateProductModel implements BaseModel {
    @Allow()
    public id: string

    constructor(product: Product) {
        super()
        this.id = product.id ?? product._id
        this.product_code = product.product_code
        this.sku = product.sku
        this.title = product.title
        this.description = product.description
        this.image = product.image
        this.files = product.image
        this.categories = product.categories
        this.quantity = product.quantity
        this.unit = product.unit
        this.price = product.price
        this.meta_data = product.meta_data
        this.status = product.status!
    }

    public async withProcessedImages(): Promise<EditProductModel> {
        return super.withProcessedImages() as any
    }
}
