import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons'
import React from 'react'
import {
    TableInstance,
    TableOptions,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table'
import styled from 'styled-components'

const Wrapper = styled.table`
    background: #ffffff;
    backdrop-filter: blur(10px);
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    th {
        z-index: 1;
    }

    th:first-child,
    td:first-child {
        padding-left: 1.5rem;
    }

    tr {
        :last-child td {
            border-bottom: 0;
        }
    }

    th {
        background: #f2f2f2;
        font-family: Quicksand;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        padding: 1rem 0;
        /* identical to box height */

        letter-spacing: 0.06em;
        text-transform: uppercase;
        position: sticky;
        top: 0;
    }

    td {
        padding: 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    th,
    td {
        text-align: left;
        margin: 0;
        padding-right: 1.5rem;
        font-size: 13px;

        :last-child {
            border-right: 0;
        }
    }
`

interface Props<T extends object> extends TableOptions<T> {
    //
}

export const Table = <T extends object>({
    columns,
    data,
    ...props
}: React.PropsWithRef<Props<T>>) => {
    const {
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    }: TableInstance<T> = useTable(
        {
            columns,
            data,
            ...props,
        },
        useSortBy,
        usePagination,
    )

    return (
        <Wrapper>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                <div className="flex space-x-2 items-center">
                                    <span>{column.render('Header')}</span>
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <UilAngleDown />
                                        ) : (
                                            <UilAngleUp />
                                        )
                                    ) : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} key={i}>
                            {row.cells.map((cell, j) => {
                                return (
                                    <td {...cell.getCellProps()} key={j}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </Wrapper>
    )
}
