import { Formik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import { InvertedButton, PrimaryButton, TextInput } from '../../../components'
import { Modal } from '../../../components/misc/modal'
import { Category, EditCategoryModel } from '../../../models'
import { useStores } from '../../../util'
import { validateModel } from '../../../util/validation'
import { ModalFormWrapper } from '../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    category: Category | null
}

export const EditCategoryModal: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    category,
}) => {
    const { categories } = useStores()
    const subscriptions: Subscription[] = []

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return category ? (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit category">
            <Formik
                validate={validateModel}
                initialValues={new EditCategoryModel(category)}
                onSubmit={(values, { setSubmitting }) => {
                    unsubscribe()
                    setSubmitting(true)

                    const subscription = categories
                        .editCategory(values)
                        .subscribe({
                            next: (response) => {
                                setSubmitting(false)

                                if (response.ok) {
                                    setIsOpen(false)
                                }
                            },
                        })

                    subscriptions.push(subscription)
                }}
            >
                {({ isValid, isSubmitting, handleSubmit }) => (
                    <ModalFormWrapper onSubmit={handleSubmit}>
                        <main className="form__content">
                            <div className="form__content-grid">
                                <TextInput
                                    name="title"
                                    label="Category title / name"
                                    placeholder="Enter title"
                                />
                            </div>
                        </main>
                        <footer className="form__footer">
                            <InvertedButton
                                className="mr-3"
                                type="button"
                                onClick={() => setIsOpen(false)}
                            >
                                <span>Cancel</span>
                            </InvertedButton>
                            <PrimaryButton
                                type="submit"
                                disabled={!isValid || isSubmitting}
                            >
                                <span>Update category</span>
                            </PrimaryButton>
                        </footer>
                    </ModalFormWrapper>
                )}
            </Formik>
        </Modal>
    ) : null
}
