import { Allow, IsBoolean } from 'class-validator'
import { cloneDeep, compact, entries, keys } from 'lodash'
import { Store } from '../../response'
import { OpeningHours, WeeklyOpeningHours } from '../../response'
import { BaseModel } from '../base.model'

interface OpeningHoursBody {
    day: string
    opens_at: string
    closes_at: string
    status?: 'active' | 'inactive'
}

const DEFAULT_OPENING_HOURS: OpeningHours = {
    opens_at: '08:00',
    closes_at: '20:00',
}

export class UpdateOpeningHoursModel implements BaseModel {
    @IsBoolean()
    public isUniform: boolean = false

    @Allow()
    public openingHours: WeeklyOpeningHours

    constructor(store?: Store) {
        this.openingHours = store?.opening_hours ?? {
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
        }
        ;(keys(this.openingHours) as (keyof WeeklyOpeningHours)[]).forEach(
            (key) => {
                if (this.openingHours[key] === null) {
                    this.openingHours[key] = { ...DEFAULT_OPENING_HOURS }
                }
            },
        )
    }

    public getRequestBody(): OpeningHoursBody[] {
        return compact(
            entries(this.openingHours).map(([day, openingHours]) => {
                if (!openingHours) return null

                return {
                    day,
                    ...openingHours,
                }
            }),
        )
    }

    public updateHours(hours: WeeklyOpeningHours) {
        this.openingHours = cloneDeep(hours)
    }
}
