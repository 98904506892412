import { HTMLMotionProps } from 'framer-motion'
import React, {
    ChangeEvent,
    ComponentPropsWithoutRef,
    useCallback,
} from 'react'
import { getClassNames } from '../../../util'
import { ValidationMessage } from '../../messages'

export interface StringInputProps
    extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
    name: string
    className?: string
    label?: string
    prependSymbol?: string
    appendSymbol?: string
    errors?: string[]
    onChange?: (value: string) => void
}

export const StringInput: React.FC<StringInputProps> = ({
    className,
    label,
    prependSymbol,
    appendSymbol,
    errors,
    onChange: changeFunction,
    ...props
}) => {
    const invalid = !!errors?.length

    const onChange: HTMLMotionProps<'input'>['onChange'] = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            changeFunction?.(e.target.value)
        },
        [changeFunction],
    )

    return (
        <div className={getClassNames(className)}>
            {label && (
                <label
                    htmlFor={props.name}
                    className="inline-block text-[#333] font-[500] text-[.75rem] mb-1"
                >
                    {label}
                </label>
            )}
            <div className="relative">
                {prependSymbol && (
                    <span className="absolute left-3 text-xs top-1/2 transform -translate-y-1/2">
                        {prependSymbol}
                    </span>
                )}
                <input
                    className={getClassNames(
                        prependSymbol && 'pl-6',
                        appendSymbol && 'pr-6',
                        'flex w-full h-10 border bg-white px-3 text-xs rounded-lg',
                        'outline-none focus:border-black',
                        'placeholder:text-slate-400 border-input-border',
                    )}
                    {...props}
                    onChange={onChange}
                />
                {appendSymbol && (
                    <span className="absolute right-3 text-xs top-1/2 transform -translate-y-1/2">
                        {appendSymbol}
                    </span>
                )}
            </div>
            {invalid && (
                <div className="grid grid-cols-1 gap-2 mt-3">
                    {errors.map((error, i) => {
                        return (
                            <ValidationMessage
                                message={error}
                                type="error"
                                key={i}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
