import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import { PrimaryButton } from '../../../../components'
import { EditProductQuantityModel, Product } from '../../../../models'
import { formatAsCurrency, useStores, validateModel } from '../../../../util'
import { ProductStatusBadge } from './product-status-badge'
import { ProductDropdown } from './table'

const Wrapper = styled.form`
    display: grid;
    --product-item-height: 7.5rem;
    grid-template-columns: var(--product-item-height) 1fr;
    gap: 1rem;

    .product-item__image {
        height: var(--product-item-height);
        width: var(--product-item-height);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 0.5rem;
        position: relative;
    }

    .product-item__body {
        display: flex;
        flex-direction: column;
    }

    .product-item__name {
        font-weight: 700;
    }

    .product-item__price {
        color: #2d606b;
        font-weight: 700;
    }

    .product-item__quantity-manager {
        justify-self: flex-end;
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
    }

    .product-item__quantity-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #333;
        border: none;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 100%;
        background: #ffffff;
        box-shadow: 0px 3.62903px 3.62903px rgba(0, 0, 0, 0.08);

        @media screen and (max-width: 1500px) {
            height: 2.5rem;
            width: 2.5rem;
        }
    }

    .product-item__quantity {
        display: inline-flex;
        justify-content: center;
        text-align: center;
        min-width: 2rem;
        margin: 0 0.5rem;
    }

    .product-item__save-button {
        padding: 0.25rem 1rem !important;
        border: none;
        height: unset !important;
    }
`

interface Props {
    product: Product
    setActiveProduct: (...args: any[]) => any
    setIsEditModalOpen: (...args: any[]) => any
}

export const ProductGridItem: React.FC<Props> = observer(
    ({ product, setActiveProduct, setIsEditModalOpen }) => {
        const { products } = useStores()
        const subscriptions: Subscription[] = []

        const editProduct = useCallback(() => {
            setActiveProduct(product)
            setIsEditModalOpen(true)
        }, [product, setActiveProduct, setIsEditModalOpen])

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Formik
                enableReinitialize
                validate={validateModel}
                initialValues={new EditProductQuantityModel(product)}
                onSubmit={(values, { setSubmitting }) => {
                    unsubscribe()
                    setSubmitting(true)

                    const subscription = products
                        .editProductQuantity(values)
                        .subscribe({
                            next: () => {
                                setSubmitting(false)
                            },
                        })

                    subscriptions.push(subscription)
                }}
            >
                {({
                    isValid,
                    dirty,
                    values,
                    initialValues,
                    isSubmitting,
                    setFieldValue,
                    handleSubmit,
                }) => (
                    <Wrapper className="product-item" onSubmit={handleSubmit}>
                        <div
                            className="product-item__image p-2 flex items-end cursor-pointer"
                            style={{
                                backgroundImage: `url(${product.image[0]})`,
                            }}
                            onClick={editProduct}
                        >
                            <div className="flex w-full space-x-1 items-center justify-between">
                                <ProductStatusBadge status={product.status} />
                                <ProductDropdown small product={product} />
                            </div>
                        </div>
                        <div className="product-item__body overflow-hidden">
                            <button
                                className="product-item__name flex justify-start"
                                type="button"
                                onClick={editProduct}
                            >
                                <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                                    {product.title}
                                </span>
                            </button>
                            <span className="product-item__price whitespace-nowrap">
                                {formatAsCurrency(product.price)}
                            </span>
                            <div className="product-item__quantity-manager flex">
                                <button
                                    className="product-item__quantity-button"
                                    type="button"
                                    disabled={values.quantity < 1}
                                    onClick={() => {
                                        setFieldValue(
                                            'quantity',
                                            values.quantity - 1,
                                        )
                                    }}
                                >
                                    -
                                </button>
                                <div className="product-item__quantity">
                                    <span>{values.quantity}</span>
                                </div>
                                <button
                                    className="product-item__quantity-button"
                                    type="button"
                                    onClick={() => {
                                        setFieldValue(
                                            'quantity',
                                            values.quantity + 1,
                                        )
                                    }}
                                >
                                    +
                                </button>
                            </div>
                            {dirty && (
                                <div>
                                    <PrimaryButton
                                        type="submit"
                                        className="product-item__save-button"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        <span>Update</span>
                                    </PrimaryButton>
                                </div>
                            )}
                        </div>
                    </Wrapper>
                )}
            </Formik>
        )
    },
)
