import React, { ReactElement } from 'react'

interface Props {
    topBarContent?: ReactElement
    children: React.ReactNode
}

export const Page: React.FC<Props> = ({ children }) => {
    return <React.Fragment>{children}</React.Fragment>
}
