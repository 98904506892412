import { ForwardRefComponent, HTMLMotionProps, motion } from 'framer-motion'
import styled from 'styled-components'
import { generateMotionButtonAttributes } from './common'

interface Props {
    isLoading?: boolean
    small?: boolean
}

type Button = ForwardRefComponent<
    HTMLButtonElement,
    HTMLMotionProps<'button'> & Props
>

export const TableButton = styled<Button>(motion.button).attrs(
    ({ children, disabled, isLoading, ...props }) =>
        generateMotionButtonAttributes({
            children,
            disabled,
            isLoading,
            props,
        }),
)`
    background: white;
    height: 2rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color: #646464;
    font-family: inherit;
    font-weight: bold;
    font-size: 0.875rem;
    border: 1px solid #bfbfbf;
    padding: 0 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: left;
    outline: none;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    white-space: pre;

    :focus-within {
        border-color: black;
    }

    &.cancel {
        background: #b6b6b6;
    }

    :disabled {
        backdrop-filter: blur(0.25rem);
        border-color: #b6b6b6 !important;
        color: #b6b6b6 !important;
        background: #e7e7e7;
        cursor: not-allowed !important;
    }

    .button__icon {
        height: 1rem;
        width: 1rem;
        stroke-width: 3px;
        stroke-linecap: square;
    }

    .button__icon + * {
        margin-left: 0.5rem;
    }
`
