import {
    Icon,
    UilBox,
    UilCog,
    UilFileCopyAlt,
    UilPostcard,
    UilShop,
    UilShoppingBag,
    UilSignout,
    UilStar,
} from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { InvertedButton, confirmDialog } from '../../../components'
import { UpdateStoreVisibilityModel } from '../../../models'
import { RouteLink, getClassNames, useStores } from '../../../util'
import { useIsStoreOpen } from '../../../util/hooks'

const Wrapper = styled.div`
    background: var(--primary);
    width: var(--side-bar-width);
    height: 100vh;
    display: flex;
    flex-direction: column;

    .logo-area {
        height: 4.25rem;
        display: flex;
        padding: 0 1.5rem;
        align-items: center;
    }

    .logo {
        height: 1.5rem;
    }

    .body {
        padding: 0.75rem 1.5rem;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .store-changer {
        background: #ffffff;
        padding: 0 0.5rem;
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        font-family: Dosis;
        font-style: normal;
        font-weight: bold;
        font-size: 12.3529px;
        line-height: 16px;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .store-logo {
        height: 2rem;
        width: 2rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 0.125rem;
        margin-right: 1rem;
    }

    .link-area {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .link-area .top-section {
        flex: 1;
    }

    .link-area .bottom-section {
        padding: 1rem 0;
        border-top: 1px solid rgba(255, 255, 255, 0.17);
    }

    .link-area .links {
        display: grid;
        gap: 1.5rem;
    }

    .link {
        display: grid;
        grid-template-columns: 1rem 1fr;
        gap: 1.25rem;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
    }

    .link {
        color: white;
        text-decoration: none;
    }

    .link.active {
        color: #eaa44a;
    }
`

interface SideBarLink {
    link: RouteLink
    icon: Icon
    name: string
    active: boolean
    hidden?: boolean
    subLinks?: SideBarLink[]
}

const sideBarLinks: SideBarLink[][] = [
    [
        // {
        //     link: RouteLink.Dashboard,
        //     icon: UilHome,
        //     name: 'Dashboard',
        //     active: false,
        //     subLinks: [
        //         {
        //             link: RouteLink.Analytics,
        //             icon: UilChartPieAlt,
        //             name: 'Analytics',
        //             active: true,
        //         },
        //     ],
        // },
        {
            link: RouteLink.Orders,
            icon: UilShoppingBag,
            name: 'Orders',
            active: true,
        },
        {
            link: RouteLink.Products,
            icon: UilBox,
            name: 'Products',
            active: true,
        },
        {
            link: RouteLink.Categories,
            icon: UilFileCopyAlt,
            name: 'Categories',
            active: true,
        },
        {
            link: RouteLink.Reviews,
            icon: UilStar,
            name: 'Reviews',
            active: true,
        },
        {
            link: RouteLink.Discounts,
            icon: UilPostcard,
            name: 'Discounts',
            active: true,
        },
        {
            link: RouteLink.Stores,
            icon: UilShop,
            name: 'Stores',
            active: false,
            hidden: true,
        },
    ],
    [
        {
            link: RouteLink.SettingsIndex,
            icon: UilCog,
            name: 'Settings',
            active: true,
        },
    ],
]

export const SideBar: React.FC = observer(() => {
    const { auth, stores } = useStores()

    const toggleVisibility = useCallback(() => {
        if (!stores.selectedStore) {
            return
        }

        const storeVisibilityModel = new UpdateStoreVisibilityModel(
            stores.selectedStore!,
        )

        storeVisibilityModel.isLive = !storeVisibilityModel.isLive
        const action = storeVisibilityModel.isLive
            ? 'show store to customers'
            : 'hide store from customers'

        confirmDialog({
            onConfirm: () => {
                stores.setVisibility(storeVisibilityModel).subscribe({})
            },
            title: 'Update store visibility',
            content: `Are you sure you want to ${action}?`,
            confirmText: `Yes, ${action}`,
        })
    }, [stores])

    const isStoreLive = stores.selectedStore?.isLive
    const isStoreOpen = useIsStoreOpen()

    const signOut = useCallback(() => {
        confirmDialog({
            onConfirm: () => auth.signOut(),
            title: 'Log out',
            content: 'Are you sure you want to log out?',
            confirmText: 'Yes, log me out',
        })
    }, [auth])

    return (
        <Wrapper>
            <div className="logo-area">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </div>
            <div className="body">
                <div className="store-changer relative">
                    <div
                        className="store-logo"
                        style={{
                            backgroundImage: `url(${
                                stores.selectedStore?.logo ??
                                auth.user?.partner.logo
                            })`,
                        }}
                    />
                    <span className="store-name">
                        {stores.selectedStore?.name ?? 'No store selected'}
                    </span>
                </div>
                <div className="mt-3 flex justify-center">
                    <div
                        className={getClassNames(
                            'py-4 px-4 flex flex-col rounded bg-white w-full',
                        )}
                    >
                        <span
                            className={getClassNames(
                                '!text-[10px] font-bold mb-5',
                                'uppercase tracking-widest',
                            )}
                        >
                            {isStoreLive
                                ? 'Store is visible to customers'
                                : 'Store is not visible to customers'}
                        </span>
                        <InvertedButton
                            className="!h-6 !p-1"
                            onClick={toggleVisibility}
                        >
                            <span>
                                {isStoreLive
                                    ? 'Hide from customers'
                                    : 'Show to customers'}
                            </span>
                        </InvertedButton>
                    </div>
                </div>
                <div className="mt-3 flex justify-center">
                    <div
                        className={getClassNames(
                            'py-1 px-2 flex rounded-lg',
                            isStoreOpen && 'bg-[#219653]',
                            !isStoreOpen && 'bg-[#CA2626]',
                        )}
                    >
                        <span
                            className={getClassNames(
                                '!text-[8px] font-bold',
                                isStoreOpen && 'text-[#219653]',
                                !isStoreOpen && 'text-[#CA2626]',
                                '!text-white uppercase tracking-widest',
                            )}
                        >
                            {isStoreOpen ? 'Open' : 'Closed'}
                        </span>
                    </div>
                </div>
                <div className="link-area">
                    <section className="top-section">
                        <nav className="links">
                            {sideBarLinks[0]
                                .filter((x) => !x.hidden)
                                .map(
                                    (
                                        { link, name, active, icon: LinkIcon },
                                        i,
                                    ) => (
                                        <NavLink
                                            to={link}
                                            className={getClassNames(
                                                !active &&
                                                    'cursor-not-allowed !text-gray-400',
                                                'link',
                                            )}
                                            key={i}
                                            onClick={
                                                active
                                                    ? undefined
                                                    : (e) => e.preventDefault()
                                            }
                                        >
                                            <LinkIcon size={18} />
                                            <span>{name}</span>
                                        </NavLink>
                                    ),
                                )}
                        </nav>
                    </section>
                    <section className="bottom-section">
                        <nav className="links">
                            {sideBarLinks[1]
                                .filter((x) => !x.hidden)
                                .map(
                                    (
                                        { link, name, active, icon: LinkIcon },
                                        i,
                                    ) => (
                                        <NavLink
                                            to={link}
                                            className={getClassNames(
                                                !active &&
                                                    'cursor-not-allowed !text-gray-400',
                                                'link',
                                            )}
                                            key={i}
                                            onClick={
                                                active
                                                    ? undefined
                                                    : (e) => e.preventDefault()
                                            }
                                        >
                                            <LinkIcon size={18} />
                                            <span>{name}</span>
                                        </NavLink>
                                    ),
                                )}
                            <Link
                                to="#"
                                className="link"
                                onClick={(e) => {
                                    e.preventDefault()
                                    signOut()
                                }}
                            >
                                <UilSignout size={18} />
                                <span>Log out</span>
                            </Link>
                        </nav>
                    </section>
                </div>
            </div>
        </Wrapper>
    )
})
