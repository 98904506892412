import { Formik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { PrimaryButton, TextInput } from '../../components'
import { UpdateProfileModel } from '../../models'
import { RouteLink, useStores } from '../../util'
import { validateModel } from '../../util/validation'
import { ProductImageUploader } from '../dashboard/products/components'
import { AuthPageWrapper } from './common'

export const OnboardingUpdateProfilePage: React.FC = () => {
    const { profile, auth } = useStores()
    const subscriptions: Subscription[] = []
    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <Formik
                    validate={validateModel}
                    enableReinitialize
                    initialValues={new UpdateProfileModel(auth.user?.partner)}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true)
                        values = await values.withProcessedImages()

                        const subscription = profile
                            .updateProfile(values)
                            .subscribe({
                                complete: () => {
                                    setSubmitting(false)
                                },
                            })

                        subscriptions.push(subscription)
                    }}
                >
                    {({ isValid, isSubmitting, handleSubmit }) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <header className="auth-form-header">
                                <span>Update profile</span>
                            </header>
                            <main>
                                <div className="flex flex-col gap-4">
                                    <ProductImageUploader
                                        name="files"
                                        label="Logo"
                                        placeholder=""
                                    />
                                    <TextInput
                                        name="phone"
                                        label="Contact number"
                                        placeholder="Contact number"
                                    />
                                </div>
                            </main>
                            <footer>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Update profile</span>
                                </PrimaryButton>
                            </footer>
                        </form>
                    )}
                </Formik>
            </main>
        </AuthPageWrapper>
    )
}
