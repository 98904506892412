import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import {
    InvertedButton,
    PrimaryButton,
    TextAreaInput,
} from '../../../../components'
import { Modal } from '../../../../components/misc/modal'
import { MakeCommentModel, Review } from '../../../../models'
import { useStores, validateModel } from '../../../../util'
import { ModalFormWrapper } from '../../common'
import { ReviewStars } from '../components'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    review: Review
}

const subscriptions: Subscription[] = []

export const ReplyModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, review }) => {
        const { reviews } = useStores()

        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Review reply">
                <Formik
                    validate={validateModel}
                    initialValues={new MakeCommentModel()}
                    onSubmit={async (values, { setSubmitting }) => {
                        unsubscribe()
                        setSubmitting(true)

                        const subscription = reviews
                            .makeComment(review._id, values)
                            .subscribe({
                                next: (response) => {
                                    setSubmitting(false)

                                    if (response.ok) {
                                        setIsOpen(false)
                                    }
                                },
                            })

                        subscriptions.push(subscription)
                    }}
                >
                    {({
                        isValid,
                        isSubmitting,
                        handleSubmit,
                        dirty,
                        values,
                    }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="grid grid-cols-1 gap-5">
                                    <div className="grid grid-cols-[40px_1fr] gap-[10px] bg-[#F6F7F8] rounded-lg p-3">
                                        <div className="h-10 w-10 bg-slate-200 rounded-full" />
                                        <div className="grid grid-cols-1 gap-3">
                                            <span className="text-xs font-bold">
                                                {review.user.name}
                                            </span>
                                            <ReviewStars stars={review.star} />
                                            <span className="text-sm">
                                                {review.comment}
                                            </span>
                                        </div>
                                    </div>
                                    {review.admin_comment ? (
                                        <div className="grid grid-cols-1 ml-[50px] gap-[10px] bg-[#F6F7F8] rounded-lg p-3">
                                            <div className="grid grid-cols-1 gap-3">
                                                <span className="text-xs font-bold">
                                                    Admin (you)
                                                </span>
                                                <span className="text-sm">
                                                    {review.admin_comment}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <TextAreaInput
                                                name="comment"
                                                label="Send reply"
                                                placeholder="Enter comment"
                                            />
                                        </div>
                                    )}
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>{dirty ? 'Cancel' : 'Close'}</span>
                                </InvertedButton>
                                {!review.admin_comment ? (
                                    <PrimaryButton
                                        type="submit"
                                        disabled={
                                            !isValid || isSubmitting || !dirty
                                        }
                                    >
                                        <span>Send reply</span>
                                    </PrimaryButton>
                                ) : null}
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
