import { Allow, IsPositive } from 'class-validator'
import { Product } from '../../response'
import { BaseModel } from '../base.model'

export class EditProductQuantityModel implements BaseModel {
    @Allow()
    public id: string

    @IsPositive()
    public quantity: number = 0

    @IsPositive()
    public price: number = 0

    public transform(): void {
        this.quantity = Math.abs(this.quantity)
        this.price = Math.abs(this.price)
    }

    constructor(product: Product) {
        this.id = product.id ?? product._id
        this.quantity = product.quantity
        this.price = product.price
    }
}
