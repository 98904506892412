import {
    clone,
    cloneDeep,
    Dictionary,
    keys,
    merge,
    setWith,
    values,
} from 'lodash'
import React, {
    Dispatch,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { PrimaryButton } from '../../../../../components'
import { UpdateOpeningHoursModel } from '../../../../../models'
import {
    OpeningHours,
    WeeklyOpeningHours,
} from '../../../../../models/response/store/opening-hours'
import { areWorkingHoursValid } from '../../../../../util'
import { WorkingHoursRangeInput } from './working-hours-range-input'

interface Props {
    workingHours: UpdateOpeningHoursModel
    setWorkingHours: (workingHours: UpdateOpeningHoursModel) => any
    setIsValid: Dispatch<React.SetStateAction<boolean>>
}

const DEFAULT_OPENING_HOURS: OpeningHours = {
    opens_at: '08:00',
    closes_at: '20:00',
}

export const WorkingHoursSelector: React.FC<Props> = ({
    workingHours,
    setWorkingHours: _setWorkingHours,
    setIsValid,
}) => {
    const [lastSetOpeningHours, setLastSetOpeningHours] =
        useState<OpeningHours | null>(null)

    const openingHours = useMemo(() => {
        return workingHours.openingHours as unknown as Dictionary<OpeningHours | null>
    }, [workingHours])

    const isValid: boolean = useMemo(() => {
        for (const hours of values(openingHours)) {
            if (!areWorkingHoursValid(hours?.opens_at, hours?.closes_at)) {
                return false
            }
        }

        return true
    }, [openingHours])

    const setWorkingHours = useCallback(
        (day: string, opensAt: string, closesAt: string) => {
            const _workingHours = cloneDeep(workingHours)
            const _openingHours = _workingHours.openingHours

            setWith(_openingHours, [day, 'opens_at'], opensAt, Object)
            setWith(_openingHours, [day, 'closes_at'], closesAt, Object)
            setLastSetOpeningHours(
                _openingHours[day as keyof WeeklyOpeningHours],
            )

            _setWorkingHours(_workingHours)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [workingHours],
    )

    const isUniform = useMemo(() => {
        let lastHours: OpeningHours | null = null

        for (const hours of values(openingHours)) {
            if (lastHours === null) {
                lastHours = hours
                continue
            }

            if (
                lastHours.opens_at !== hours?.opens_at ||
                lastHours.closes_at !== hours.closes_at
            ) {
                return false
            }

            lastHours = hours
        }

        return true
    }, [openingHours])

    const makeUniform = useCallback(() => {
        const _workingHours = clone(workingHours)
        const _openingHours = cloneDeep(openingHours)
        const { opens_at: opensAt, closes_at: closesAt } = {
            ...(lastSetOpeningHours ?? DEFAULT_OPENING_HOURS),
        }

        keys(_openingHours).forEach((day) => {
            const hours: OpeningHours = {
                opens_at: opensAt,
                closes_at: closesAt,
            }

            if (!_openingHours[day]) {
                _openingHours[day] = hours
            } else {
                merge(_openingHours[day], hours)
            }
        })

        _workingHours.openingHours = _openingHours as any
        _setWorkingHours(_workingHours)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workingHours, openingHours, _setWorkingHours, lastSetOpeningHours])

    const toggleDay = useCallback(
        (day: string) => () => {
            const _workingHours = clone(workingHours)
            const _openingHours = cloneDeep(openingHours)
            const dayHours = _openingHours[day]

            if (dayHours === null) {
                _openingHours[day] = {
                    ...(lastSetOpeningHours ?? DEFAULT_OPENING_HOURS),
                }
            } else {
                dayHours.status =
                    dayHours.status === 'inactive' ? 'active' : 'inactive'
            }

            _workingHours.openingHours = _openingHours as any
            _setWorkingHours(_workingHours)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [workingHours, openingHours, lastSetOpeningHours, _setWorkingHours],
    )

    useEffect(() => {
        if (workingHours.openingHours) {
            for (const openingHours of values(workingHours.openingHours)) {
                if (openingHours) {
                    setLastSetOpeningHours(openingHours)
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsValid(isValid)
    }, [isValid, setIsValid])

    return (
        <>
            <div className="grid grid-cols-1 gap-[15px]">
                {keys(openingHours).map((day, i) => {
                    const isEnabled =
                        openingHours[day] !== null &&
                        openingHours[day]?.status !== 'inactive'

                    return (
                        <React.Fragment key={i}>
                            <WorkingHoursRangeInput
                                day={day}
                                initialValues={openingHours}
                                isEnabled={isEnabled}
                                setValues={setWorkingHours}
                                toggleDay={toggleDay(day)}
                            />
                        </React.Fragment>
                    )
                })}
            </div>
            {!isUniform && (
                <div className="mt-4">
                    <PrimaryButton type="button" small onClick={makeUniform}>
                        <span>Make hours uniform</span>
                    </PrimaryButton>
                </div>
            )}
        </>
    )
}
