import { observer } from 'mobx-react'
import React from 'react'
import {
    FormNumericalInput,
    MultiTextInput,
    ReactSelectInput,
    TextAreaInput,
    TextInput,
} from '../../../../../components'
import { productStatusOptions, useStores } from '../../../../../util'
import { ProductImageUploader } from '../product-image-uploader'

interface Props {}

export const ManualEntry: React.FC<Props> = observer(() => {
    const { categories } = useStores()
    const categoryOptions = categories.categories

    return (
        <div className="form__content-grid">
            <TextInput
                name="title"
                label="Product title / name"
                placeholder="Enter title"
            />
            <ProductImageUploader
                name="files"
                label="Product image"
                placeholder="Upload image"
            />
            <TextInput
                name="product_code"
                label="Product code"
                placeholder="Enter product code"
            />
            <TextInput name="sku" label="SKU" placeholder="Enter product SKU" />
            <TextAreaInput
                name="description"
                label="Product description"
                placeholder="Enter description"
            />
            <ReactSelectInput
                name="categories"
                label="Categories"
                placeholder="Select categories"
                isMulti
                list={categoryOptions}
                options={{
                    display: 'title',
                    value: 'id',
                }}
            />
            <ReactSelectInput
                name="status"
                label="Status"
                placeholder="Status"
                list={productStatusOptions}
                options={{
                    display: 'value',
                    value: 'key',
                }}
            />
            <TextInput
                name="unit"
                label="Unit (kg / pc / lb / ml...etc)"
                placeholder="Enter unit"
            />
            <div className="form-split">
                <FormNumericalInput
                    name="quantity"
                    label="Quantity"
                    placeholder="Enter quantity"
                    precision={0}
                />
                <FormNumericalInput
                    name="price"
                    label="Price"
                    placeholder="Enter price"
                    precision={2}
                    prependSymbol="£"
                />
            </div>
            <MultiTextInput
                name="meta_data"
                label="Options"
                placeholder="Type then press ENTER to add option"
            />
        </div>
    )
})
