import { capitalize, every, find, values } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import {
    CreateStoreModel,
    EditStoreModel,
    Store,
    StoreCategory,
    StoreCategoryFormatted,
    UpdateOpeningHoursModel,
    UpdateStoreConfigurationModel,
    UpdateStoreInformationModel,
    UpdateStoreVisibilityModel,
} from '../models'
import { StoresService } from '../services'
import { HttpMethod, request, Resettable } from '../util'
import { dehydrateToStorage, hydrateFromStorage } from '../util/misc/storage'

const SELECTED_STORE_ID_KEY = 'ETHCO:SELECTED_STORE_ID'

export class StoresStore implements Resettable {
    public stores: Store[] = []
    public storeCategories: StoreCategory[] = []
    public storesLoaded: boolean = false
    public selectedStore: Store | null = null

    @computed
    public get storeCategoriesFormatted(): StoreCategoryFormatted[] {
        return this.storeCategories.map((category) => ({
            ...category,
            formattedName: capitalize(category.name),
        }))
    }

    @computed
    public get hasStore(): boolean {
        return this.storesLoaded && this.stores.length !== 0
    }

    @computed
    public get openingHoursSet(): boolean {
        return every(
            values(this.selectedStore!.opening_hours ?? {}),
            (hours) => !!hours,
        )
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public hydrateSelectedStore(): void {
        const selectedStoreId: string | null = hydrateFromStorage(
            SELECTED_STORE_ID_KEY,
        )

        this.selectStore(selectedStoreId)
    }

    @action
    public selectStore(storeId: string | null): void {
        const selectedStore =
            (storeId ? find(this.stores, { id: storeId }) : null) ??
            (this.stores.length ? this.stores[0] : null)

        this.selectedStore = selectedStore
        dehydrateToStorage(SELECTED_STORE_ID_KEY, selectedStore?.id ?? null)
    }

    @action
    public listStores(rehydrate?: boolean) {
        return StoresService.listStores().pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.storesLoaded = true
                        this.stores = response.data

                        if (!this.selectedStore || rehydrate) {
                            this.hydrateSelectedStore()
                        }
                    }
                })
            }),
        )
    }

    @action
    public listStoreCategories() {
        return StoresService.listStoreCategories().pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.storeCategories = response.data
                    }
                })
            }),
        )
    }

    @action
    public retrieveStore() {
        return StoresService.listStores().pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.stores = response.data
                    }
                })
            }),
        )
    }

    @action
    public updateConfiguration(model: UpdateStoreConfigurationModel) {
        return request(
            'partner/stores/' + this.selectedStore?.id + '/configuration',
            HttpMethod.PATCH,
            {
                body: model.getRequestBody(),
                loadingMessage: 'Updating store configuration',
                completionMessage: 'Store configuration updated',
            },
        ).pipe(
            tap((response) => {
                if (response.ok) {
                    this.listStores(true).subscribe()
                }
            }),
        )
    }

    @action
    public updateInformation(model: UpdateStoreInformationModel) {
        return request(
            'partner/stores/' + this.selectedStore?.id + '/information',
            HttpMethod.PATCH,
            {
                body: model.getRequestBody(),
                loadingMessage: 'Updating store information',
                completionMessage: 'Store information updated',
            },
        ).pipe(
            tap((response) => {
                if (response.ok) {
                    this.listStores(true).subscribe()
                }
            }),
        )
    }

    @action
    public setVisibility(model: UpdateStoreVisibilityModel) {
        return request(
            'partner/stores/' +
                this.selectedStore?.id +
                '/visibility/' +
                String(model.isLive),
            HttpMethod.PATCH,
            {
                loadingMessage: 'Updating store visibility',
                completionMessage: 'Store visibility updated',
            },
        ).pipe(
            tap((response) => {
                if (response.ok) {
                    this.listStores(true).subscribe()
                }
            }),
        )
    }

    @action
    public updateOpeningHours(model: UpdateOpeningHoursModel) {
        return request(
            'partner/stores/' + this.selectedStore?.id + '/opening_hours',
            HttpMethod.PATCH,
            {
                body: model.getRequestBody(),
                loadingMessage: 'Updating store opening hours',
                completionMessage: 'Store opening hours updated',
            },
        ).pipe(
            tap((response) => {
                if (response.ok) {
                    this.listStores(true).subscribe()
                }
            }),
        )
    }

    @action
    public createStore(model: CreateStoreModel) {
        return StoresService.createStore(model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listStores().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public editStore(id: string, model: EditStoreModel) {
        return StoresService.editStore(id, model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listStores().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.stores = []
        this.storesLoaded = false
        this.selectedStore = null
    }
}
