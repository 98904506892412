import { OrderStatus } from '../../models/response'
import { Option } from './option'

export const updateableStatuses: OrderStatus[] = [
    OrderStatus.PROCESSING,
    OrderStatus.PROCESSED,
]

export const cancellableStatuses: OrderStatus[] = [
    OrderStatus.NEW,
    OrderStatus.PROCESSING,
]

export const orderStatusAnalyticsOptions: Option[] = [
    {
        key: OrderStatus.NEW,
        value: 'New',
    },
    {
        key: OrderStatus.PROCESSING,
        value: 'Processing',
    },
    {
        key: OrderStatus.PROCESSED,
        value: 'Processed',
    },
]
