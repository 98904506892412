import { UilExclamationTriangle } from '@iconscout/react-unicons'
import { useField } from 'formik'
import React from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { getClassNames } from '../../util'

const Wrapper = styled.label`
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;

    .text-input__label {
        font-weight: 500;
    }

    .text-input__body {
        overflow: hidden;
        border: 1px solid #bfbfbf;
        border-radius: 0.85rem;
        display: flex;
        --height: calc(3rem + 2px);
        height: var(--height);

        :focus-within {
            border-color: black;
            border-width: 2px;
        }
    }

    .text-input__input {
        padding: 1rem 1.25rem;
        border: none;
        outline: none;
        flex: 1;

        &[type='password'] {
            padding-right: 0;
        }

        ::placeholder {
            color: #c4c4c4;
        }
    }

    .text-input__visibility-toggle {
        width: var(--height);
        height: var(--height);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: transparent;

        :focus,
        :hover {
            background: #eeeeee;
        }
    }

    .text-input__error-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .text-input__error-message {
        color: #a11717;
        font-size: 0.85rem;
        font-weight: 500;
        background: #f5f1f1;
        display: inline-flex;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        align-items: center;
    }

    .text-input__error-message__text {
        margin-left: 1rem;
    }
`

interface Props extends React.ComponentPropsWithoutRef<'input'> {
    name: string
    label?: string
    placeholder: string
    showMultipleErrors?: boolean
}

export const DateInput: React.FC<Props> = ({
    name,
    label,
    className,
    showMultipleErrors,
    ...props
}) => {
    const [field, meta] = useField({ name })
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const valid = React.useMemo(() => !meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    return (
        <Wrapper
            className={getClassNames(
                className,
                invalid && 'invalid',
                valid && 'valid',
            )}
        >
            {label && <span className="text-input__label">{label}</span>}
            <div className="text-input__body">
                <DatePicker
                    selected={null}
                    onChange={(date) => {
                        // 
                    }}
                    customInput={
                        <input
                            {...field}
                            {...props}
                            className="text-input__input"
                        />
                    }
                />
            </div>
            {invalid && (
                <div className="text-input__error-section">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <span key={i} className="text-input__error-message">
                                <UilExclamationTriangle size={14} />
                                <span className="text-input__error-message__text">
                                    {message}
                                </span>
                            </span>
                        ),
                    )}
                </div>
            )}
        </Wrapper>
    )
}
