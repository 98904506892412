import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { getClassNames } from '../../util'
import { ValidationMessage } from '../messages'
import { Input } from './common'

const Wrapper = styled.label`
    display: flex;
    flex-direction: column;

    .textarea-input__label {
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
    }

    .textarea-input__body {
        overflow: hidden;
        border: 1px solid #bfbfbf;
        border-radius: 0.5rem;
        display: flex;

        :focus-within {
            border-color: black;
        }
    }

    .textarea-input__input {
        width: 100%;
        --height: 6rem;
        height: var(--height);
        max-height: var(--height);
        min-height: var(--height);
        resize: none;
        padding: 0.75rem;
    }

    .textarea-input__error-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        margin-top: 0.75rem;
    }
`

interface Option {
    label: string
    value: string
}

interface Props extends React.ComponentPropsWithoutRef<'textarea'> {
    name: string
    label?: string
    placeholder: string
    showMultipleErrors?: boolean
    as?: any
    options?: Option[]
}

export const TextAreaInput: React.FC<Props> = ({
    name,
    label,
    className,
    options,
    showMultipleErrors,
    ...props
}) => {
    const [field, meta] = useField({ name })
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const valid = React.useMemo(() => !meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    return (
        <Wrapper
            className={getClassNames(
                className,
                invalid && 'invalid',
                valid && 'valid',
            )}
        >
            {label && <span className="textarea-input__label">{label}</span>}
            <div className="textarea-input__body">
                <Input
                    {...field}
                    {...props}
                    as="textarea"
                    className="textarea-input__input"
                />
            </div>
            {invalid && (
                <div className="textarea-input__error-section">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <ValidationMessage
                                key={i}
                                message={message}
                                type="error"
                            />
                        ),
                    )}
                </div>
            )}
        </Wrapper>
    )
}
