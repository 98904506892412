import { UilShoppingBasket, UilWallet } from '@iconscout/react-unicons'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect } from 'react'
import { CellProps } from 'react-table'
import { Loader, StatusTag, Table } from '../../../../components'
import { Transaction } from '../../../../models/response/transaction'
import { getCellItem, useStores } from '../../../../util'
import { DashboardPageWrapper } from '../../common'
import { PaymentDisplayBox } from '../components'

export const TransactionsPage: React.FC = observer(() => {
    const { payments } = useStores()
    const metrics = payments.transactionAggregate?.counts
    const transactions = payments.transactions
    const loading = payments.transactionsLoading

    useEffect(() => {
        const subscription = payments.listTransactions().subscribe()
        return () => {
            subscription.unsubscribe()
        }
    }, [payments])

    return (
        <>
            <DashboardPageWrapper title="Transactions">
                <div className="px-10 py-6 flex flex-col h-full">
                    <Loader loading={loading}>
                        <div className="w-full grid grid-cols-1 gap-4">
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                                <PaymentDisplayBox
                                    heading={metrics?.total_sales}
                                    isCurrency
                                    subheading="Total sales"
                                    icon={UilWallet}
                                    iconColor="#654FF3"
                                />
                                <PaymentDisplayBox
                                    heading={metrics?.total_amount}
                                    subheading="Total orders"
                                    icon={UilShoppingBasket}
                                    iconColor="#EAA44A"
                                />
                                <PaymentDisplayBox
                                    heading={metrics?.total_payout}
                                    isCurrency
                                    subheading="Total payout"
                                    icon={UilWallet}
                                    iconColor="#654FF3"
                                />
                                <PaymentDisplayBox
                                    heading={metrics?.expected_payout}
                                    isCurrency
                                    subheading="Expected payout"
                                    icon={UilWallet}
                                    iconColor="#654FF3"
                                />
                            </div>
                            {transactions.length ? (
                                <Table
                                    data={transactions}
                                    columns={[
                                        {
                                            Header: 'Reference',
                                            accessor: 'reference',
                                        },
                                        {
                                            Header: 'Created',
                                            accessor: 'created_at',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Transaction>) => {
                                                const { created_at } =
                                                    getCellItem(cell)
                                                const date = moment(created_at)

                                                return (
                                                    <div className="flex flex-col">
                                                        <span>
                                                            {date.format(
                                                                'DD MMM yyyy',
                                                            )}
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: '#646464',
                                                            }}
                                                        >
                                                            {date.format(
                                                                'hh:mm A',
                                                            )}
                                                        </span>
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Type',
                                            accessor: 'tx_type',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Transaction>) => {
                                                const transaction =
                                                    getCellItem(cell)

                                                return (
                                                    <StatusTag
                                                        value={
                                                            transaction.tx_type
                                                        }
                                                    />
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Channel',
                                            accessor(transaction: Transaction) {
                                                return capitalize(
                                                    transaction.tx_channel as string,
                                                )
                                            },
                                        },
                                        {
                                            Header: 'Status',
                                            accessor: 'status',
                                            Cell: ({
                                                cell,
                                            }: CellProps<Transaction>) => {
                                                const transaction =
                                                    getCellItem(cell)

                                                return (
                                                    <StatusTag
                                                        value={
                                                            transaction.status as string
                                                        }
                                                    />
                                                )
                                            },
                                        },
                                    ]}
                                ></Table>
                            ) : (
                                <span>You do not have any transactions</span>
                            )}
                        </div>
                    </Loader>
                </div>
            </DashboardPageWrapper>
        </>
    )
})

export default TransactionsPage
