import { Listbox } from '@headlessui/react'
import React from 'react'
import { getClassNames, Option } from '../../util'

interface Props {
    value: Option
    onChange: (...args: any[]) => any
    options: Option[]
}

export const ListBox: React.FC<Props> = ({ value, onChange, options }) => {
    return (
        <Listbox
            value={value}
            onChange={onChange}
            as="div"
            className="w-full relative min-w-[100px]"
        >
            {({ open }) => (
                <>
                    <Listbox.Button className="border w-full rounded-md p-2 text-[12px] leading-4 border-[#BFBFBF]">
                        <span>{value.value}</span>
                        {open && (
                            <div
                                className={getClassNames(
                                    'absolute right-4 w-2 h-2 top-full translate-y-2 rotate-45 transform z-[1] bg-accent-2',
                                )}
                            />
                        )}
                    </Listbox.Button>
                    <Listbox.Options
                        className={getClassNames(
                            'border border-[#BFBFBF] overflow-hidden',
                            'top-full transform translate-y-3 z-[1] absolute right-0 w-[9rem] origin-top-right divide-y divide-[#000000]/10 rounded-md',
                        )}
                    >
                        {options.map((option, i) => (
                            <Listbox.Option
                                key={i}
                                value={option}
                                disabled={option.disabled}
                                className="px-2 py-1 bg-white hover:bg-gray-100 cursor-pointer"
                            >
                                {option.value}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </>
            )}
        </Listbox>
    )
}
