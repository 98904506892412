import { FilePondFile } from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond/dist/filepond.min.css'
import { assign, flatten, values } from 'lodash'
import { observer } from 'mobx-react'
import papa from 'papaparse'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import { ProductUploadModel } from '../../../../models/request/products/product-upload.model'
import { appState, MessageType } from '../../../../stores'
import { cancelSubscriptions, useStores, validateModel } from '../../../../util'

registerPlugin(FilePondPluginFileValidateType)

const Wrapper = styled.div`
    /*  */
`

interface Props {
    validateProducts: (products: ProductUploadModel[]) => void
}

export const BulkProductUploader: React.FC<Props> = observer(
    ({ validateProducts }) => {
        const { categories } = useStores()
        const subscriptions: Subscription[] = useMemo(() => [], [])

        const validateCsv = useCallback(
            (file: FilePondFile, data: unknown[]) => {
                const productUploadModels: ProductUploadModel[] = []

                return appState.createPromiseTask(
                    new Promise<ProductUploadModel[]>(async (resolve) => {
                        for (let i = 0; i < data.length; ++i) {
                            const rowNumber = i + 1
                            const product = assign(
                                new ProductUploadModel(),
                                data[i],
                            )

                            const validationErrors = await validateModel(
                                product,
                                true,
                            )

                            const validationErrorMessages =
                                values(validationErrors)

                            if (validationErrorMessages.length > 0) {
                                file.abortLoad()
                                resolve([])

                                return appState.createMessage(
                                    `Validation error at row ${rowNumber}\n` +
                                        flatten(validationErrorMessages).join(
                                            '\n',
                                        ),
                                    MessageType.ERROR,
                                )
                            }

                            productUploadModels.push(product)
                        }

                        resolve(productUploadModels)
                    }),
                    'Validating products',
                    false,
                )
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [categories, validateProducts, subscriptions],
        )

        const readFile = useCallback(
            (file: FilePondFile) => {
                const reader = new FileReader()

                reader.addEventListener('load', async (event) => {
                    const csv = event.target?.result

                    if (!csv) {
                        file.abortLoad()
                        return appState.createMessage(
                            'File is empty',
                            MessageType.ERROR,
                        )
                    }

                    papa.parse(csv as string, {
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: async ({ data, errors }) => {
                            if (errors.length > 0) {
                                file.abortLoad()
                                return appState.createMessage(
                                    'Document has errors. Fix them and attempt re-upload',
                                    MessageType.ERROR,
                                )
                            }

                            validateCsv(file, data).then(validateProducts)
                        },
                    })
                })

                reader.readAsText(file.file)
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [categories],
        )

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <Wrapper>
                <FilePond
                    labelIdle="Upload file here"
                    credits={false}
                    instantUpload={false}
                    onaddfile={(error, file) => {
                        if (error) {
                            return appState.createMessage(
                                'Error occured while loading document',
                                MessageType.ERROR,
                            )
                        }

                        readFile(file)
                    }}
                    onremovefile={() => {
                        validateProducts([])
                    }}
                    acceptedFileTypes={['.csv', 'text/csv', 'application/csv']}
                />
            </Wrapper>
        )
    },
)
