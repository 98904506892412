import { Dictionary, entries } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import {
    Cell,
    Legend,
    LegendProps,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
} from 'recharts'

interface Props {
    data: Dictionary<number> & any
}

const PIE_COLORS: Record<string, string> = {
    early: '#EAA44A',
    late: '#654FF3',
}

const LEGEND_LABELS: Record<string, string> = {
    early: 'On time',
    late: 'Late',
}

const CustomLegend: any = ({ payload }: LegendProps) => {
    return (
        <div className="flex justify-center space-x-3">
            {payload?.map((entry, i) => (
                <div key={i} className="flex space-x-1 items-center">
                    <div
                        className="h-2 w-2 rounded-full"
                        style={{ background: entry.color }}
                    />
                    <span className="text-[#849AA9] text-[11px]">
                        {LEGEND_LABELS[entry.value]}
                    </span>
                </div>
            ))}
        </div>
    )
}

const CustomTooltip: any = ({ active, payload }: TooltipProps<any, any>) => {
    return active && payload?.length ? (
        <div className="bg-white min-w-[72px] rounded-md shadow-md py-2 px-3 flex flex-col items-center space-y-1">
            <span className="font-bold text-[#555] text-[21px]">
                {payload[0].value}
            </span>
            <span className="font-bold text-[#555] text-[11px] uppercase">
                {payload[0].name}
            </span>
        </div>
    ) : null
}

export const DonutChart: React.FC<Props> = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0)

    const chartData = useMemo(
        () =>
            entries(data).map(([key, value]) => ({
                name: key,
                value,
            })),
        [data],
    )

    const onPieEnter = useCallback((_: unknown, index: number) => {
        setActiveIndex(index)
    }, [])

    const legendFormatter = useCallback((key: string) => {
        return LEGEND_LABELS[key]
    }, [])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    activeIndex={activeIndex}
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={40}
                    outerRadius={72}
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                >
                    {chartData.map(({ name }, i) => (
                        <Cell key={i} fill={PIE_COLORS[name]} />
                    ))}
                </Pie>
                <Tooltip
                    // position={{ y: 0 }}
                    // itemStyle={{
                    //     fontSize: '10px',
                    //     marginTop: -8,
                    //     color: 'black',
                    // }}
                    // labelStyle={{
                    //     color: 'white',
                    //     marginBottom: 8,
                    // }}
                    // contentStyle={{
                    //     background: '#ffffff',
                    //     padding: 8,
                    //     borderRadius: 5,
                    //     border: 'none',
                    // }}
                    content={CustomTooltip}
                />
                <Legend
                    formatter={legendFormatter}
                    content={CustomLegend}
                    fill="#849AA9"
                />
            </PieChart>
        </ResponsiveContainer>
    )
}
