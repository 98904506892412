import { Icon, UilAngleRight } from '@iconscout/react-unicons'
import React from 'react'
import { Link } from 'react-router-dom'
import { getClassNames } from '../../../../util/misc'

export interface SettingsButtonProps {
    icon: Icon
    title: string
    link?: string
    action?: () => any
    inactive?: boolean
}

export const SettingsButton: React.FC<SettingsButtonProps> = ({
    icon: IconEl,
    title,
    action,
    link,
    inactive,
}) => {
    return (
        <Link
            to={link ?? '#'}
            onClick={inactive ? (e) => e.preventDefault() : action}
            className={getClassNames(
                'flex border items-center border-[#F2F2F2] justify-between bg-white p-5 w-full rounded-lg',
                inactive && 'cursor-not-allowed',
            )}
        >
            <div className="flex space-x-3 items-center">
                <div className="flex rounded-full items-center justify-center h-12 bg-primary/10 aspect-square text-primary">
                    <IconEl size={16} />
                </div>
                <span
                    className={getClassNames(
                        'font-bold',
                        inactive ? 'text-gray-400' : 'text-black',
                    )}
                >
                    {title}
                </span>
            </div>
            <UilAngleRight />
        </Link>
    )
}
