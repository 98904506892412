import { UilDownloadAlt } from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../components'
import { cancelSubscriptions, RouteLink, useStores } from '../../util'
import { AuthPageWrapper } from './common'

export const OnboardingTermsPage: React.FC = observer(() => {
    const { profile } = useStores()
    const subscriptions: Subscription[] = []
    const [isLoading, setIsLoading] = useState(false)

    const acceptTerms = useCallback(() => {
        setIsLoading(true)
        const subscription = profile.acceptTerms().subscribe({
            complete() {
                setIsLoading(false)
            },
        })

        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading])

    useEffect(() => {
        cancelSubscriptions(subscriptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <div className="w-[564px] bg-white rounded-lg p-5 text-[#646464]">
                    <header className="flex flex-col">
                        <span className="text-bold font-[Dosis] text-[26px] text-[#333]">
                            Terms of service
                        </span>
                        <span className="text-base font-[Quicksand] font-normal">
                            Updated August 2022
                        </span>
                    </header>
                    <main className="h-96 overflow-y-auto my-5 px-5 -mx-5">
                        <object
                            data="/docs/terms-of-agreement.pdf#toolbar=0"
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                            <iframe
                                title="Terms of service"
                                src="/docs/terms-of-agreement.pdf#toolbar=0"
                                width="100%"
                                height="100%"
                                style={{ border: 'none' }}
                            >
                                This browser does not support PDFs. Please
                                download the PDF to view it:
                                <a href="/docs/terms-of-agreement.pdf" download>
                                    Download PDF
                                </a>
                            </iframe>
                        </object>
                    </main>
                    <footer className="flex justify-between">
                        <div className="flex items-center">
                            <a
                                className="bg-none border-none text-primary inline-flex space-x-2 font-bold items-center"
                                href="/docs/terms-of-agreement.pdf"
                                download
                            >
                                <UilDownloadAlt size={15} />
                                <span>Download</span>
                            </a>
                        </div>
                        <PrimaryButton
                            small
                            className="!bg-[#118F1E] !border-[#118F1E]"
                            isLoading={isLoading}
                            onClick={acceptTerms}
                        >
                            <span>I agree</span>
                        </PrimaryButton>
                    </footer>
                </div>
            </main>
        </AuthPageWrapper>
    )
})
