import React from 'react'

export class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = { hasError: false }
    }

    public static getDerivedStateFromError(error: Error): any {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: any): void {
        // You can also log the error to an error reporting service
        console.error({ error, errorInfo })
    }

    public render(): React.ReactNode {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>
        }

        return this.props.children
    }
}
