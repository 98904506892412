import { omit } from 'lodash'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { Timeliness } from '../models'
import { HttpMethod, request, Resettable, stores } from '../util'

export class AnalyticsStore implements Resettable {
    public stageWiseTimeliness:
        | (Record<string, Timeliness> & { others: number })
        | null = null

    public overallTimeliness: Timeliness | null = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public getStageWiseTimeliness() {
        return request<never, any>(
            `partner/tracker/${stores.stores.selectedStore?.id}/stage`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        const res = response as any

                        this.stageWiseTimeliness = omit(
                            res,
                            'data',
                            'ok',
                            'status',
                            'message',
                        ) as any
                    }
                })
            }),
        )
    }

    @action
    public getOverallTimeliness() {
        return request<never, any>(
            `partner/tracker/${stores.stores.selectedStore?.id}/overall`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        const res = response as any

                        this.overallTimeliness = {
                            early: res.early,
                            late: res.late,
                        }
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.stageWiseTimeliness = null
        this.overallTimeliness = null
    }
}
