import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Subscription } from 'rxjs'
import {
    FormNumericalInput,
    InvertedButton,
    MultiTextInput,
    PrimaryButton,
    ReactSelectInput,
    TextAreaInput,
    TextInput,
} from '../../../../components'
import { Loader } from '../../../../components/layout/loader'
import { Modal } from '../../../../components/misc/modal'
import { EditProductModel, Product } from '../../../../models'
import {
    productStatusOptions,
    useStores,
    validateModel,
} from '../../../../util'
import { ModalFormWrapper } from '../../common'
import { ProductImageUploader } from '../components'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    product: Product | null
}

const subscriptions: Subscription[] = []

export const EditProductModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, product: activeProduct }) => {
        const { categories, products } = useStores()
        const categoryOptions = useMemo(
            () => ({
                list: categories.categories,
                display: 'title',
                value: 'id',
            }),
            [categories.categories],
        )

        const productLoading = products.activeLoading
        const product = products.activeProduct
        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            if (activeProduct) {
                products.retrieveProduct(activeProduct._id).subscribe()
            }
        }, [products, activeProduct])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        const editProduct = useCallback(
            async (
                values: EditProductModel,
                { setSubmitting }: FormikHelpers<EditProductModel>,
            ) => {
                unsubscribe()
                setSubmitting(true)

                values = await values.withProcessedImages()
                const subscription = products
                    .editProduct(values, product!)
                    .subscribe({
                        next: (response) => {
                            setSubmitting(false)

                            if (response.ok) {
                                setIsOpen(false)
                            }
                        },
                    })

                subscriptions.push(subscription)
            },
            [unsubscribe, products, product, setIsOpen],
        )

        return product ? (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit product">
                <Loader loading={productLoading}>
                    <Formik
                        validate={validateModel}
                        enableReinitialize
                        initialValues={new EditProductModel(product)}
                        onSubmit={editProduct}
                    >
                        {({ isValid, isSubmitting, handleSubmit, dirty }) => (
                            <ModalFormWrapper onSubmit={handleSubmit}>
                                <main className="form__content">
                                    <div className="form__content-grid">
                                        <TextInput
                                            name="title"
                                            label="Product title / name"
                                            placeholder="Enter title"
                                        />
                                        <ProductImageUploader
                                            name="files"
                                            label="Product image"
                                            placeholder="Upload image"
                                        />
                                        <TextInput
                                            name="product_code"
                                            label="Product code"
                                            placeholder="Enter product code"
                                        />
                                        <TextInput
                                            name="sku"
                                            label="SKU"
                                            placeholder="Enter product SKU"
                                        />
                                        <TextAreaInput
                                            name="description"
                                            label="Product description"
                                            placeholder="Enter description"
                                        />
                                        <ReactSelectInput
                                            name="categories"
                                            label="Categories"
                                            placeholder="Select categories"
                                            isMulti
                                            list={categoryOptions.list}
                                            options={categoryOptions as any}
                                        />
                                        <ReactSelectInput
                                            name="status"
                                            label="Status"
                                            placeholder="Status"
                                            list={productStatusOptions}
                                            options={{
                                                display: 'value',
                                                value: 'key',
                                            }}
                                        />
                                        <TextInput
                                            name="unit"
                                            label="Unit (kg / pc / lb / ml...etc)"
                                            placeholder="Enter unit"
                                        />
                                        <div className="form-split">
                                            <FormNumericalInput
                                                name="quantity"
                                                label="Quantity"
                                                placeholder="Enter quantity"
                                                precision={0}
                                            />
                                            <FormNumericalInput
                                                name="price"
                                                label="Price"
                                                placeholder="Enter price"
                                                precision={2}
                                                prependSymbol="£"
                                            />
                                        </div>
                                        <MultiTextInput
                                            name="meta_data"
                                            label="Options"
                                            placeholder="Type then press ENTER to add option"
                                        />
                                    </div>
                                </main>
                                <footer className="form__footer">
                                    <InvertedButton
                                        className="mr-3"
                                        type="button"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <span>
                                            {dirty ? 'Cancel' : 'Close'}
                                        </span>
                                    </InvertedButton>
                                    {dirty && (
                                        <PrimaryButton
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                        >
                                            <span>Update product</span>
                                        </PrimaryButton>
                                    )}
                                </footer>
                            </ModalFormWrapper>
                        )}
                    </Formik>
                </Loader>
            </Modal>
        ) : null
    },
)
