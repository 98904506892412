import moment from 'moment'
import { Order, OrderStatus, OrderTimeliness } from '../../models/response'
import { getStatusColors } from './get-status-colors'

const PROJECTED_ON_TIME_RANGES: Record<string, [number, number]> = {
    [OrderStatus.NEW]: [0, 5],
    [OrderStatus.PROCESSING]: [5, 35],
    [OrderStatus.PROCESSED]: [35, 55],
}

export const getOrderTimeliness = (order?: Order | null) => {
    if (!order) {
        return null
    }

    const createdAt = new Date(order.created_at)
    const timeSinceCreation = moment().diff(createdAt, 'm')
    const timeRange = PROJECTED_ON_TIME_RANGES[order.status.status]

    if (!timeRange) {
        return null
    }

    return getStatusColors(
        timeSinceCreation >= timeRange[0] && timeSinceCreation <= timeRange[1]
            ? OrderTimeliness.ON_TIME
            : OrderTimeliness.LATE,
    )
}
