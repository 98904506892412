import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CellProps } from 'react-table'
import { Subscription } from 'rxjs'
import {
    confirmDialog,
    InvertedButton,
    PrimaryButton,
    Table,
} from '../../../../components'
import { Loader } from '../../../../components/layout/loader'
import { PlanSubscription } from '../../../../models'
import { Plan } from '../../../../models/response/subscription/plan'
import {
    cancelSubscriptions,
    formatAsCurrency,
    getCellItem,
    getStatusColors,
    RouteLink,
    useStores,
} from '../../../../util'
import { DashboardPageWrapper } from '../../common'

const rxSubscriptions: Subscription[] = []

export const SubscriptionPage: React.FC = observer(() => {
    const { subscriptions } = useStores()
    const subscriptionList = subscriptions.subscriptions
    const {
        isActive: active,
        subscription: currentSubscription,
        cost,
    } = subscriptions.planInfo
    const loading = !subscriptions.subscriptionsLoaded

    const cancelSubscription = useCallback(() => {
        if (currentSubscription) {
            confirmDialog({
                title: `Cancel subscription to plan "${currentSubscription.plan.name}"`,
                content: 'Are you sure you want to cancel your subscription?',
                confirmText: 'Yes, cancel',
                cancelText: 'No, go back',
                confirmTextClassName: '!bg-red !text-white !border-red',
                onConfirm: () => {
                    const subscription = subscriptions
                        .cancelSubscription(currentSubscription.id)
                        .subscribe()
                    rxSubscriptions.push(subscription)
                },
            })
        }
    }, [subscriptions, currentSubscription])

    useEffect(() => {
        const subscription = subscriptions.listSubscriptions().subscribe()
        return () => subscription.unsubscribe()
    }, [subscriptions])

    useEffect(() => {
        if (subscriptions.plans.length === 0) {
            rxSubscriptions.push(subscriptions.listPlans().subscribe())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions, subscriptions.plans])

    useEffect(() => {
        return () => {
            cancelSubscriptions(rxSubscriptions)
        }
    }, [])

    return (
        <DashboardPageWrapper title="Subscription">
            <Loader loading={loading}>
                <div className="px-10 py-6 h-full flex flex-col space-y-9">
                    <div className="flex space-x-5">
                        {active && currentSubscription ? (
                            <>
                                <div className="flex flex-col justify-between bg-[#EAA44A] p-5 h-[210px] w-[280px] rounded-lg text-white font-bold">
                                    <main className="flex flex-col">
                                        <span className="text-[20px]">
                                            Current plan
                                        </span>
                                        <span className="text-[40px]">
                                            {currentSubscription.plan.name}
                                        </span>
                                    </main>
                                    <footer>
                                        <InvertedButton small disabled>
                                            <span>Upgrade</span>
                                        </InvertedButton>
                                    </footer>
                                </div>
                                <div className="flex flex-col justify-between bg-white border border-[#F2F2F2] p-5 h-[210px] w-[280px] rounded-lg text-[#646464] font-bold">
                                    <main className="flex flex-col">
                                        <span className="text-[20px]">
                                            Renewal
                                        </span>
                                        <span className="text-[40px]">
                                            {cost}
                                        </span>
                                    </main>
                                    <footer>
                                        <InvertedButton
                                            small
                                            onClick={cancelSubscription}
                                        >
                                            <span>Cancel</span>
                                        </InvertedButton>
                                    </footer>
                                </div>
                            </>
                        ) : (
                            <div>
                                <span className="flex mb-4">
                                    You have no subscriptions active
                                </span>
                                <Link to={RouteLink.Plans}>
                                    <PrimaryButton small>
                                        <span>Select a subscription</span>
                                    </PrimaryButton>
                                </Link>
                            </div>
                        )}
                    </div>
                    {!!subscriptionList.length && (
                        <div className="flex flex-col w-full h-full overflow-hidden space-y-9">
                            <Table
                                columns={[
                                    {
                                        Header: 'Ref',
                                        accessor: 'id',
                                        Cell: ({
                                            cell,
                                        }: CellProps<PlanSubscription>) => {
                                            const { id } = getCellItem(cell)
                                            return <b>{id}</b>
                                        },
                                    },
                                    {
                                        Header: 'Date created',
                                        accessor: 'created_at',
                                        Cell: ({
                                            cell,
                                        }: CellProps<PlanSubscription>) => {
                                            const { created_at } =
                                                getCellItem(cell)
                                            const date = moment(created_at)

                                            return (
                                                <div className="flex flex-col">
                                                    <span>
                                                        {date.format(
                                                            'DD MMM yyyy',
                                                        )}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        {date.format('hh:mm A')}
                                                    </span>
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        Header: 'Subscription Plan',
                                        accessor: (
                                            subscription: PlanSubscription,
                                        ) => capitalize(subscription.plan.name),
                                    },
                                    {
                                        Header: 'Status',
                                        accessor: 'status',
                                        Cell: ({
                                            cell,
                                        }: CellProps<PlanSubscription>) => {
                                            const { status } = getCellItem(cell)
                                            const { color, background, text } =
                                                getStatusColors(status)

                                            return (
                                                <span
                                                    className="inline-flex px-2 rounded"
                                                    style={{
                                                        color,
                                                        background,
                                                    }}
                                                >
                                                    {text}
                                                </span>
                                            )
                                        },
                                    },
                                    {
                                        Header: 'Frequency',
                                        accessor: (
                                            subscription: PlanSubscription,
                                        ) =>
                                            capitalize(
                                                subscription.plan.duration,
                                            ),
                                    },
                                    {
                                        Header: 'Cost',
                                        accessor: (
                                            subscription: PlanSubscription,
                                        ) => {
                                            const key = ('cost_' +
                                                subscription.plan
                                                    .duration) as unknown as keyof Plan
                                            const value = subscription.plan[
                                                key
                                            ] as number
                                            return formatAsCurrency(value)
                                        },
                                    },
                                ]}
                                data={subscriptionList}
                            />
                        </div>
                    )}
                </div>
            </Loader>
        </DashboardPageWrapper>
    )
})

export default SubscriptionPage
