import React, { useMemo } from 'react'
import { getStatusColors } from '../../util'

interface Props {
    value?: string
}

export const StatusTag: React.FC<Props> = ({ value }) => {
    const { color, text, background } = useMemo(() => {
        return getStatusColors(value)
    }, [value])

    return (
        <span
            className="inline-flex px-2 rounded"
            style={{
                color,
                background,
            }}
        >
            {text}
        </span>
    )
}
