export const validateImageLink = (url: string) => {
    const img = new Image()
    img.src = url

    return new Promise((resolve) => {
        if (img.complete) {
            return resolve(true)
        } else {
            img.onload = (e) => {
                return resolve(true)
            }

            img.onerror = (e) => {
                return resolve(false)
            }
        }
    })
}
