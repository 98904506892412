import { get, isEqual, keys } from 'lodash'

export const getObjectDiff = <A extends object, B extends object>(
    object1: A,
    object2: B,
) => {
    return keys(object1).reduce((result, key) => {
        if (!object2.hasOwnProperty(key)) {
            result.push(key)
        } else if (isEqual(get(object1, key), get(object2, key))) {
            const resultKeyIndex = result.indexOf(key)
            result.splice(resultKeyIndex, 1)
        }

        return result
    }, keys(object2))
}
