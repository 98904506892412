import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../components'
import { UpdateOpeningHoursModel } from '../../models'
import { RouteLink, useStores } from '../../util'
import { WorkingHoursSelector } from '../dashboard/settings/general/components'
import { AuthPageWrapper } from './common'

export const OnboardingStoreOpeningHoursPage: React.FC = () => {
    const { stores } = useStores()
    const navigate = useNavigate()
    const subscriptions: Subscription[] = []
    const [workingHours, setWorkingHours] = useState(
        new UpdateOpeningHoursModel(stores.selectedStore ?? undefined),
    )

    const [isValid, setIsValid] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setIsSubmitting(true)

        const subscription = stores.updateOpeningHours(workingHours).subscribe({
            next(response) {
                if (response.ok) {
                    navigate(RouteLink.Dashboard, { replace: true })
                }
            },
            complete() {
                stores.hydrateSelectedStore()
                setIsSubmitting(false)
            },
        })

        subscriptions.push(subscription)
    }

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <form className="auth-form" onSubmit={handleSubmit}>
                    <header className="auth-form-header">
                        <span>Opening Hours</span>
                    </header>
                    <main>
                        <WorkingHoursSelector
                            workingHours={workingHours}
                            setWorkingHours={setWorkingHours}
                            setIsValid={setIsValid}
                        />
                    </main>
                    <footer>
                        <PrimaryButton
                            type="submit"
                            disabled={!isValid || isSubmitting}
                            className="w-full"
                        >
                            <span>Complete registration</span>
                        </PrimaryButton>
                    </footer>
                </form>
            </main>
        </AuthPageWrapper>
    )
}
