import { UilAngleLeftB } from '@iconscout/react-unicons'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import { observer } from 'mobx-react'
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { BehaviorSubject } from 'rxjs'
import {
    MultiTextInput,
    PrimaryButton,
    ReactSelectInput,
    ReactSelectInputCreatable,
    SingleRangeInput,
    TextInput,
} from '../../../components'
import { CreateStoreModel } from '../../../models'
import {
    getClassNames,
    PolarAnswer,
    polarAnswerOptions,
    useStores,
} from '../../../util'
import { ProductImageUploader } from '../../dashboard/products/components'

const firstPageFields: (keyof CreateStoreModel)[] = [
    'name',
    'email',
    'street',
    'postcode',
    'mile_radius',
    'category',
]

const secondPageFields: (keyof CreateStoreModel | string)[] = [
    'feedback.epos_brand',
    'feedback.other_pos',
    'feedback.has_internet',
    'feedback.internet_provider',
    'feedback.internet_pay',
    'feedback.website_url',
    'feedback.products_in_store',
    'feedback.suppliers',
]

enum Progress {
    FirstPage,
    SecondPage,
    ThirdPage,
}

export const OnboardingStoreForm: React.FC<FormikProps<CreateStoreModel>> =
    observer(
        ({
            dirty,
            errors,
            isSubmitting,
            isValid,
            values,
            submitCount,
            handleSubmit,
            setTouched,
        }) => {
            const { misc, stores } = useStores()
            const [postcodeSubject] = useState<BehaviorSubject<string>>(
                new BehaviorSubject(''),
            )

            const postcodeInformation = misc.postcodeInformation
            const addresses = postcodeInformation?.addresses ?? []

            const [currentPage, setCurrentPage] = useState(Progress.FirstPage)
            const goToPage = useCallback(
                (page: Progress) => () => setCurrentPage(page),
                [],
            )

            const search = useCallback(
                (e: ChangeEvent<HTMLInputElement>) => {
                    postcodeSubject.next(e.target.value)
                },
                [postcodeSubject],
            )

            useEffect(() => {
                const subscription =
                    misc.getPostcodeSubscription(postcodeSubject)
                return () => {
                    subscription.unsubscribe()
                }
            }, [postcodeSubject, misc])

            const isFirstPageValid = useMemo(() => {
                if (!dirty) {
                    return false
                }

                return !firstPageFields.some((field) => !!get(errors, field))
            }, [dirty, errors])

            const isSecondPageValid = useMemo(() => {
                if (!dirty) {
                    return false
                }

                return !secondPageFields.some((field) => !!get(errors, field))
            }, [dirty, errors])

            return (
                <>
                    <form className="auth-form" onSubmit={handleSubmit}>
                        <header className="auth-form-header flex justify-between">
                            <span>Continue registration</span>
                            {currentPage !== Progress.FirstPage && (
                                <button
                                    className="text-primary inline-flex text-[1rem] items-center font-[500] font-[Quicksand]"
                                    onClick={goToPage(currentPage - 1)}
                                >
                                    <UilAngleLeftB size={16} />
                                    <span>Back</span>
                                </button>
                            )}
                        </header>
                        {currentPage === Progress.FirstPage && (
                            <>
                                <main>
                                    <TextInput
                                        name="name"
                                        placeholder="Name"
                                        label="Store name"
                                    />
                                    <TextInput
                                        name="email"
                                        placeholder="Email"
                                        label="Store email (optional)"
                                    />
                                    <TextInput
                                        name="postcode"
                                        label="Store postcode"
                                        onInput={search}
                                        onBlur={(e) => {
                                            search(e)
                                            setTouched({ postcode: true }, true)
                                        }}
                                        placeholder="Enter postcode"
                                    />
                                    <div
                                        className={getClassNames(
                                            'text-[10px] text-[#646464] flex space-x-4 -mt-2',
                                        )}
                                    >
                                        <span>
                                            Longitude: {values.longitude}
                                        </span>
                                        <span>Latitude: {values.latitude}</span>
                                    </div>
                                    <ReactSelectInputCreatable
                                        name="street"
                                        placeholder="Address"
                                        label="Store address"
                                        list={addresses.map((address) => ({
                                            display: address,
                                            value: address,
                                        }))}
                                        options={{
                                            display: 'display',
                                            value: 'value',
                                        }}
                                    />
                                    <SingleRangeInput
                                        name="mile_radius"
                                        label="Mile radius"
                                        description="Area of coverage for the store's branches."
                                        min={0}
                                        max={10}
                                        step={1}
                                    />
                                    <ReactSelectInput
                                        name="category"
                                        label="Category"
                                        placeholder="Select category"
                                        list={stores.storeCategoriesFormatted}
                                        options={{
                                            display: 'formattedName',
                                            value: 'id',
                                        }}
                                    />
                                </main>
                                <footer>
                                    <PrimaryButton
                                        type="button"
                                        onClick={goToPage(Progress.SecondPage)}
                                        disabled={
                                            !isFirstPageValid || isSubmitting
                                        }
                                    >
                                        <span>Continue</span>
                                    </PrimaryButton>
                                </footer>
                            </>
                        )}
                        {currentPage === Progress.SecondPage && (
                            <>
                                <main>
                                    <ReactSelectInput
                                        name="feedback.other_pos"
                                        label="Do you have an electric point of sale system?"
                                        placeholder="Select option"
                                        list={polarAnswerOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {values.feedback.other_pos ===
                                        PolarAnswer.Yes && (
                                        <TextInput
                                            name="feedback.epos_brand"
                                            placeholder="Brand name"
                                            label="Name of EPOS brand"
                                        />
                                    )}
                                    <ReactSelectInput
                                        name="feedback.has_internet"
                                        label="Do you have internet connection in the store?"
                                        placeholder="Select option"
                                        list={polarAnswerOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {values.feedback.has_internet ===
                                        PolarAnswer.Yes && (
                                        <TextInput
                                            name="feedback.internet_provider"
                                            placeholder="Internet Service Provider name"
                                            label="Name of Internet Service Provider"
                                        />
                                    )}
                                    {values.feedback.has_internet ===
                                        PolarAnswer.No && (
                                        <ReactSelectInput
                                            name="feedback.internet_pay"
                                            label="Are you willing to pay for cheap internet?"
                                            placeholder="Select option"
                                            list={polarAnswerOptions}
                                            options={{
                                                display: 'value',
                                                value: 'key',
                                            }}
                                        />
                                    )}
                                    <ReactSelectInput
                                        name="feedback.has_website"
                                        label="Do you have a website?"
                                        placeholder="Select option"
                                        list={polarAnswerOptions}
                                        options={{
                                            display: 'value',
                                            value: 'key',
                                        }}
                                    />
                                    {values.feedback.has_website ===
                                        PolarAnswer.Yes && (
                                        <TextInput
                                            name="feedback.website_url"
                                            placeholder="www.example.com"
                                            label="Website URL"
                                        />
                                    )}
                                    <TextInput
                                        name="feedback.products_in_store"
                                        placeholder="Number of unique products"
                                        label="What is the number of unique products you think you have in the store?"
                                        type="number"
                                    />
                                    <MultiTextInput
                                        name="feedback.suppliers"
                                        placeholder="Enter supplier names"
                                        label="Enter names of major suppliers / cash and carry you purchase goods from"
                                        useComma
                                    />
                                </main>
                                <footer>
                                    <PrimaryButton
                                        type="button"
                                        onClick={goToPage(Progress.ThirdPage)}
                                        disabled={
                                            !isSecondPageValid || isSubmitting
                                        }
                                    >
                                        <span>Continue</span>
                                    </PrimaryButton>
                                </footer>
                            </>
                        )}
                        {currentPage === Progress.ThirdPage && (
                            <>
                                <main>
                                    <div className="flex flex-col gap-4">
                                        <ProductImageUploader
                                            name="files"
                                            label="Logo"
                                            placeholder=""
                                        />
                                        <span className="font-[500] tracking-[0.09em] text-xs">
                                            CONTACT INFO
                                        </span>
                                        <div className="grid grid-cols-2 gap-4">
                                            <TextInput
                                                className="col-span-1"
                                                name="contact_person.name"
                                                placeholder="Name"
                                                label="Contact name"
                                            />
                                            <TextInput
                                                className="col-span-1"
                                                name="contact_person.phone"
                                                placeholder="Phone"
                                                label="Contact phone"
                                            />
                                            <TextInput
                                                className="col-span-2"
                                                name="contact_person.email"
                                                placeholder="Email"
                                                label="Contact email"
                                            />
                                        </div>
                                    </div>
                                </main>
                                <footer>
                                    <PrimaryButton
                                        type="submit"
                                        disabled={
                                            submitCount > 0 &&
                                            (!isValid || isSubmitting)
                                        }
                                    >
                                        <span>Create store</span>
                                    </PrimaryButton>
                                </footer>
                            </>
                        )}
                    </form>
                </>
            )
        },
    )
