import { UilAngleLeftB, UilSearch } from '@iconscout/react-unicons'
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PrimaryButton } from '../../../components'
import { history } from '../../../util'

const Wrapper = styled.div`
    background: white;
    border-bottom: 1px solid #f2f2f2;
    height: 4.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem;

    .page-title {
        font-family: Dosis;
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    .search-form {
        max-width: 25rem;
        width: 100%;
        padding: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 5rem;
        display: flex;
        justify-content: space-between;

        .text-section {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1rem;
            font-family: Quicksand;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: #646464;
        }
    }
`

export const SearchForm: React.FC = () => {
    return (
        <div className="search-form">
            <section className="text-section">
                <UilSearch size={24} color="#333333" />
                <span
                    style={{
                        marginLeft: '1rem',
                        color: '#646464',
                    }}
                >
                    Enter your postcode to find local stores
                </span>
            </section>
            <section>
                <PrimaryButton small>
                    <span>Search</span>
                </PrimaryButton>
            </section>
        </div>
    )
}

interface Props {
    title?: string
}

export const TopBar: React.FC<Props> = ({ title }) => {
    const location = useLocation()
    const canGoBack = !!location && location.key !== 'default'

    return (
        <Wrapper>
            <div className="flex items-center">
                {canGoBack && (
                    <button
                        className="inline-flex items-center space-x-2 mr-6 font-semibold text-black text-[14px]"
                        onClick={history.back}
                    >
                        <UilAngleLeftB size={18} />
                        <span>Back</span>
                    </button>
                )}
                <span className="page-title">{title ?? 'Dashboard'}</span>
            </div>
        </Wrapper>
    )
}
