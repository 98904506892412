import { observer } from 'mobx-react'
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { routes } from '../../pages'
import { history, useStores } from '../../util'
import { LoadingScreen, TaskArea } from '../layout'
import { RouteComponentWrapper } from './route-component-wrapper'

export const Router: React.FC = observer(() => {
    const { auth } = useStores()

    React.useEffect(() => {
        const subscription = auth.getUser().subscribe()

        return () => {
            subscription.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return auth.ready ? (
        <HistoryRouter history={history as any}>
            <Suspense fallback={<LoadingScreen />}>
                <Routes>
                    {routes.map(
                        ([route, title, component, requirements], i) => {
                            const paths: string[] = Array.isArray(route)
                                ? route
                                : [route]

                            return paths.map((path, j) => (
                                <Route
                                    key={`${i}${j}`}
                                    path={path}
                                    element={
                                        <RouteComponentWrapper
                                            requirements={requirements}
                                            title={title}
                                            component={component}
                                        />
                                    }
                                />
                            ))
                        },
                    )}
                </Routes>
            </Suspense>
            <TaskArea />
        </HistoryRouter>
    ) : (
        <LoadingScreen />
    )
})
