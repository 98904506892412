import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import { Loader } from '../../../components'
import { Review } from '../../../models'
import { useStores } from '../../../util'
import { DashboardPageWrapper } from '../common'
import { ReviewBox } from './components'
import { ReplyModal } from './modals'

const Wrapper = styled.div`
    height: 100%;
    padding: 1.5rem 2.5rem;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .main-view {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex: 1 1 auto;
    }
`

export const ReviewsPage: React.FC = observer(() => {
    const { reviews, stores } = useStores()
    const subscriptions: Subscription[] = []
    const reviewsLoading = reviews.loading
    const [activeReview, setActiveReview] = useState<Review | null>(null)
    const [isReplyModalOpen, setIsReplyModalOpen] = useState(false)

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    useEffect(() => {
        if (stores.storesLoaded && stores.selectedStore) {
            const subscription = reviews.listReviews().subscribe()
            subscriptions.push(subscription)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviews, stores.stores, stores.selectedStore, stores.storesLoaded])

    return (
        <DashboardPageWrapper title="Reviews">
            {isReplyModalOpen && activeReview && (
                <ReplyModal
                    review={activeReview}
                    isOpen={isReplyModalOpen}
                    setIsOpen={setIsReplyModalOpen}
                />
            )}
            <Loader loading={reviewsLoading}>
                <Wrapper>
                    <main className="main-view">
                        {reviews.reviews.length === 0 && (
                            <span className="flex mt-3">
                                You do not have any reviews
                            </span>
                        )}
                        {reviews.reviews.length !== 0 && (
                            <div className="grid grid-cols-1 gap-3">
                                {reviews.reviews.map((review, i) => (
                                    <ReviewBox
                                        review={review}
                                        controls={{
                                            isOpen: isReplyModalOpen,
                                            setIsOpen: setIsReplyModalOpen,
                                            setActive: setActiveReview,
                                        }}
                                        key={i}
                                    />
                                ))}
                            </div>
                        )}
                    </main>
                </Wrapper>
            </Loader>
        </DashboardPageWrapper>
    )
})
