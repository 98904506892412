import { Allow, IsEnum, IsOptional, IsString } from 'class-validator'
import { Order, OrderStatus } from '../../response'
import { BaseModel } from '../base.model'

export class UpdateOrderStatusModel implements BaseModel {
    @Allow()
    public id: string

    @IsEnum(OrderStatus, { message: 'Must be a valid order status' })
    public status: string

    @IsOptional()
    @IsString({ message: 'Must be a valid string' })
    public reason: string = ''

    public transform(): void {
        if (this.status === OrderStatus.DISPATCHED) {
            ;(this as any).parcel_info = ''
        }
    }

    constructor(order: Order) {
        this.id = order._id
        this.status = order.status.status
    }
}
