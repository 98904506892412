import { Store } from './store'

export enum OrderStatus {
    NEW = 'new',
    PROCESSING = 'processing',
    PROCESSED = 'processed',
    DISPATCHED = 'dispatched',
    CANCELLED = 'cancelled',
    DELIVERED = 'delivered',
}

export enum OrderTimeliness {
    ON_TIME = 'on-time',
    LATE = 'late',
}

export interface OrderStatusInformation {
    status: OrderStatus
    reason: string
}

export interface OrderTrackerEvent {
    id: string
    status: OrderStatusInformation
    created_by: string
    created_at: string
}

export interface OrderItem {
    id: string
    title: string
    price: number
    quantity: number
    unit: string
    product: {
        id: string
        image: string[]
    }
    status: OrderStatusInformation
    order_tracker: any[]
}

interface OrderAddress {
    name: string
    email: string
    address: string
    phone_number: string
}

export interface Order {
    _id: string
    reference: string
    store: Store
    pickup: OrderAddress
    delivery: OrderAddress
    orders: OrderItem[]
    shipping_fee: number
    sub_total: number
    payment_details: {
        type: string
        total_amount: number
        status: string
        refund: {
            status: string
            amount: number
        }
    }
    additional_info: string
    status: OrderStatusInformation
    order_tracker: OrderTrackerEvent[]
    created_at: string
    updated_at: string
}
