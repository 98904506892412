import { entries } from 'lodash'
import { Option } from './option'

export enum ShoppingOption {
    Store = 'store',
    Online = 'online',
    Both = 'both',
}

export const shoppingOptionMap = {
    [ShoppingOption.Store]: 'Store',
    [ShoppingOption.Online]: 'Online',
    [ShoppingOption.Both]: 'Both',
}

export const shoppingOptionOptions: Option[] = entries(shoppingOptionMap).map(
    ([key, value]) => ({ key, value }),
)
