import React, { useEffect, useMemo, useState } from 'react'
import TimePicker from 'react-time-picker'
import styled from 'styled-components'
import { ValidationMessage } from '../../../../../components'
import { areWorkingHoursValid, getClassNames } from '../../../../../util'
import { WorkingHoursDayButton } from './working-hours-day-button'

interface Props {
    day: string
    isEnabled: boolean
    initialValues: any
    setValues: (day: string, opensAt: string, closesAt: string) => any
    toggleDay: () => any
}

interface InputProps {
    name: string
    placeholder: string
    value: string
    disabled: boolean
    onChange: (value: string) => any
}

const Wrapper = styled.div`
    .react-time-picker__wrapper {
        border: none;
    }

    .react-time-picker__inputGroup {
        padding: 0;
        justify-content: center;
    }

    .react-time-picker__inputGroup__divider {
        margin: 0 10px;
    }

    .react-time-picker__inputGroup__input {
        width: 24px !important;
    }
`

const Input: React.FC<InputProps> = ({
    name,
    value,
    disabled,
    onChange: changeFunction,
}) => {
    const onChange = (value: unknown) => {
        changeFunction(value as string)
    }

    return (
        <Wrapper
            className={getClassNames(
                'flex-1 h-11 items-center flex justify-center p-3 rounded-lg bg-[#F5F5F5] disabled:bg-[#BDBDBD]/30',
                'text-[#4F4F4F] disabled:text-[#4F4F4F]/30 font-bold',
            )}
        >
            <TimePicker
                value={value}
                format="HH:mm"
                disableClock
                clearIcon={null}
                disabled={disabled}
                name={name}
                onChange={onChange}
            />
        </Wrapper>
    )
}

export const WorkingHoursRangeInput: React.FC<Props> = ({
    day,
    initialValues,
    isEnabled,
    setValues,
    toggleDay,
}) => {
    const [opensAt, setOpensAt] = useState<string>(initialValues[day]?.opens_at)
    const [closesAt, setClosesAt] = useState<string>(
        initialValues[day]?.closes_at,
    )

    const isValid = useMemo(() => {
        return areWorkingHoursValid(opensAt, closesAt)
    }, [opensAt, closesAt])

    useEffect(() => {
        setValues(day, opensAt, closesAt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opensAt, closesAt])

    useEffect(() => {
        setOpensAt(initialValues[day]?.opens_at)
        setClosesAt(initialValues[day]?.closes_at)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    return (
        <div className="flex justify-between items-start space-x-6">
            <WorkingHoursDayButton
                day={day}
                isToggled={isEnabled}
                toggleDay={toggleDay}
                isValid={isValid}
            />
            <div className="flex-1">
                <div className="flex items-center space-x-4">
                    <Input
                        name={day + '.opens_at'}
                        placeholder="Opens at"
                        value={opensAt ?? ''}
                        disabled={!isEnabled}
                        onChange={setOpensAt}
                    />
                    <span>-</span>
                    <Input
                        name={day + '.closes_at'}
                        placeholder="Closes at"
                        value={closesAt ?? ''}
                        disabled={!isEnabled}
                        onChange={setClosesAt}
                    />
                </div>
                {!isValid && (
                    <div className="mt-4">
                        <ValidationMessage
                            type="error"
                            message="Invalid time range selected"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
