import { MinLength } from 'class-validator'
import { NAME_MIN_LENGTH } from '../../../util'
import { Review } from '../../response'
import { BaseModel } from '../base.model'

export class MakeCommentModel implements BaseModel {
    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public comment: string = ''

    constructor(review?: Review) {
        this.comment = review?.admin_comment ?? ''
    }
}
