import {
    IsEnum,
    IsNotEmpty,
    IsPositive,
    IsString,
    IsUrl,
    MaxLength,
    MinLength,
} from 'class-validator'
import {
    NAME_MIN_LENGTH,
    ProductStatus,
    validateImageLink,
} from '../../../util'
import { BaseModel } from '../base.model'
import { CreateProductModel } from './create-product.model'

export class ProductUploadModel implements BaseModel {
    @IsString()
    @IsNotEmpty()
    public product_code: string = ''

    @IsString()
    @IsNotEmpty()
    public sku: string = ''

    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @MaxLength(400, {
        message: 'Must be at most $constraint1 character(s) long',
    })
    public title: string = ''

    @IsString()
    @IsNotEmpty()
    public description: string = ''

    @IsUrl({ protocol: 'https' })
    public image_url?: string

    @IsString()
    @IsNotEmpty()
    public category?: string

    @IsPositive()
    public quantity: number = 0

    @IsPositive()
    public price: number = 0

    @IsString()
    @IsNotEmpty()
    public unit: string = ''

    @IsEnum(ProductStatus, { message: 'Must be a valid status' })
    public status: string = ''

    public transform(): void {
        try {
            this.product_code = '' + this.product_code
            this.sku = '' + this.sku
            this.quantity = +this.quantity.toString().replace(/[^\d.]/, '')
            this.price = +this.price.toString().replace(/[^\d.]/, '')
            this.status = this.status.toLowerCase()
        } catch (error) {
            console.error(error)
        }
    }

    public async generateCustomValidation(): Promise<
        Record<keyof BaseModel, string[]>
    > {
        const errors: Partial<Record<keyof ProductUploadModel, string[]>> = {}

        if (!this.image_url || !(await validateImageLink(this.image_url))) {
            return {
                image_url: [
                    'error loading image in image_url. Is it a valid image link?',
                ],
            }
        }

        return errors
    }

    public convertToCreateProductModel() {
        const model = new CreateProductModel()

        model.product_code = this.product_code
        model.sku = this.sku
        model.title = this.title
        model.description = this.description
        model.image = [this.image_url!]
        model.categories = [this.category!]
        model.quantity = this.quantity
        model.price = this.price
        model.unit = this.unit
        model.status = this.status

        return model
    }
}
