import { Listbox } from '@headlessui/react'
import {
    UilCheckSquare,
    UilSquareFull,
    UilTimes,
} from '@iconscout/react-unicons'
import { isArray } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { getClassNames } from '../../../util/misc'
import { ValidationMessage } from '../../messages'

export interface SelectInputProps<T> {
    name: string
    className?: string
    label?: string
    errors?: string[]
    placeholder?: string
    onChange?: (...args: any[]) => any
    onBlur?: (...args: any[]) => any
    value?: any | any[]
    multiple?: boolean
    position?: 'bottom' | 'top'
    options: T[]
    accessor: {
        display: keyof T
        value: keyof T
    }
}

interface TagProps {
    display: string
    value: string
    remove: (value: string) => () => void
}

const Tag: React.FC<TagProps> = ({ display, value, remove }) => {
    return (
        <div className="inline-flex items-center bg-gray-100 rounded-sm h-5 mr-2 mb-2 whitespace-nowrap">
            <span className="px-2 text-xs">{display}</span>
            <button
                className="flex items-center justify-center h-full rounded-sm aspect-square bg-gray-200 hover:bg-gray-300"
                onClick={remove(value)}
                type="button"
            >
                <UilTimes className="h-3 w-3" />
            </button>
        </div>
    )
}

export const SelectInput = <T,>({
    name,
    className,
    label,
    errors,
    placeholder,
    onChange,
    onBlur,
    value,
    multiple,
    position,
    options: optionTypes,
    accessor,
}: React.PropsWithChildren<SelectInputProps<T>>) => {
    const invalid = !!errors?.length
    const showPlaceholder = !value || (isArray(value) && value.length === 0)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [width, setWidth] = useState(0)
    const [referenceElement, setReferenceElement] =
        useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<any>(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: position ?? 'bottom',
        strategy: 'fixed',
        modifiers: [
            {
                name: 'preventOverflow',
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'flip',
                options: {
                    padding: 8,
                },
            },
        ],
    })

    const options = useMemo(() => {
        return optionTypes.map((option) => ({
            label: option[accessor.display] as unknown as string,
            value: option[accessor.value] as any,
        }))
    }, [accessor, optionTypes])

    const optionsMap = useMemo(() => {
        const map: Record<string, typeof options[number]> = {}

        options.forEach((option) => {
            map[option.value as unknown as string] = option
        })

        return map
    }, [options])

    const select = useCallback(
        (selected: any) => {
            return onChange?.(selected)
        },
        [onChange],
    )

    const removeElement = useCallback(
        (val: any) => () => {
            const newValue = (value as string[]).filter((x) => x !== val)
            select(newValue)
        },
        [value, select],
    )

    const tags = useMemo(() => {
        if (!multiple) {
            return null
        }

        return (value as any[]).map((selection, i) => (
            <Tag
                display={optionsMap[selection].label}
                value={optionsMap[selection].value}
                key={i}
                remove={removeElement}
            />
        ))
    }, [value, removeElement, optionsMap, multiple])

    useEffect(() => {
        if (referenceElement) {
            setWidth(referenceElement.getBoundingClientRect().width)
        }
    }, [referenceElement])

    return (
        <div
            className={getClassNames(
                className,
                'grid grid-cols-1 gap-2 ring-0',
            )}
        >
            {label && (
                <label className="inline-block text-[#333] font-[500] text-[.75rem] mb-1">
                    {label}
                </label>
            )}
            <Listbox
                value={value}
                onChange={select}
                multiple={multiple}
                name={name}
                as="div"
                className="flex w-full relative"
            >
                <Listbox.Button
                    className={getClassNames(
                        'flex flex-col justify-center px-3 py-2 h-[42px] rounded-[.5rem] bg-white overflow-hidden border !border-[#bfbfbf]',
                    )}
                    as="div"
                    ref={setReferenceElement}
                >
                    {showPlaceholder && (
                        <span className="text-xs text-[#c4c4c4]">
                            {placeholder}
                        </span>
                    )}
                    {!showPlaceholder && multiple && (
                        <div className="flex flex-nowrap -mb-2 text-xs overflow-hidden overflow-x-auto w-full">
                            {tags}
                        </div>
                    )}
                    {!showPlaceholder && !multiple && (
                        <span>{optionsMap[value].label}</span>
                    )}
                </Listbox.Button>
                <Listbox.Options
                    className={getClassNames(
                        'absolute w-[20rem] z-10 bottom-full left-0 transform -translate-y-2 overflow-hidden overflow-y-auto max-h-[20rem]',
                        'grid grid-cols-1 items-center border border-[#bfbfbf] bg-white text-xs rounded-lg',
                        'outline-none justify-center shadow-lg divide-y-1 divide-slate-100 !ring-0',
                    )}
                    ref={setPopperElement}
                    style={{ ...styles.popper }}
                    {...attributes.popper}
                >
                    {options.map((option) => {
                        return (
                            <Listbox.Option
                                as={React.Fragment}
                                key={option.value}
                                value={option.value}
                            >
                                {({ active, selected, disabled }) => (
                                    <li
                                        className={getClassNames(
                                            disabled &&
                                                'cursor-not-allowed pointer-events-none text-slate-300',
                                            active &&
                                                (selected
                                                    ? 'bg-primary-dark'
                                                    : 'bg-slate-200'),
                                            selected &&
                                                'bg-primary text-white focus:bg-primary-dark active:bg-primary-dark hover:!bg-primary-dark',
                                            'cursor-pointer h-10 w-full px-5 py-1 flex items-center ring-inset !ring-0 hover:bg-slate-100 space-x-4',
                                        )}
                                    >
                                        {selected ? (
                                            <UilCheckSquare
                                                size={12}
                                                className="text-inherit"
                                            />
                                        ) : (
                                            <UilSquareFull
                                                size={12}
                                                className="text-inherit"
                                            />
                                        )}
                                        <span>{option.label}</span>
                                    </li>
                                )}
                            </Listbox.Option>
                        )
                    })}
                </Listbox.Options>
            </Listbox>
            {invalid && (
                <div className="grid grid-cols-1 gap-2">
                    {errors.map((error, i) => {
                        return (
                            <ValidationMessage
                                message={error}
                                type="error"
                                key={i}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
