import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import styled from 'styled-components'
import { PrimaryButton } from '..'
import { getClassNames } from '../../util'

const Wrapper = styled.div`
    background: #fff;
    border-radius: 0.5rem;
    padding: 2rem;
    width: 100vw;
    max-width: 25rem;
    backdrop-filter: blur(1.5rem);
    display: grid;
    grid-template-columns: 1fr;

    header {
        font-size: 1.25rem;
        font-family: Dosis;
        font-weight: bold;
    }

    main {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    footer {
        display: flex;
        justify-content: flex-start;
    }
`

interface Options {
    title: string
    content: string | React.ReactElement
    onConfirm: () => any
    onCancel?: () => any
    confirmText?: string | React.ReactElement
    cancelText?: string | React.ReactElement
    confirmTextClassName?: string
    cancelTextClassName?: string
}

export const confirmDialog = (options: Options) => {
    return confirmAlert({
        customUI: ({ onClose }) => (
            <Wrapper>
                <header>{options.title}</header>
                <main>{options.content}</main>
                <footer>
                    <PrimaryButton
                        type="button"
                        small
                        className={getClassNames(options.confirmTextClassName)}
                        onClick={() => {
                            options.onConfirm()
                            onClose()
                        }}
                    >
                        <span>{options.confirmText ?? 'Okay'}</span>
                    </PrimaryButton>
                    <PrimaryButton
                        type="button"
                        className={getClassNames(
                            options.cancelTextClassName,
                            'cancel',
                        )}
                        style={{ marginLeft: '1rem' }}
                        small
                        onClick={() => {
                            options.onCancel?.()
                            onClose()
                        }}
                    >
                        <span>{options.cancelText ?? 'Cancel'}</span>
                    </PrimaryButton>
                </footer>
            </Wrapper>
        ),
    })
}
