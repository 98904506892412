import { observer } from 'mobx-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useStores } from '../../../../util'

const Wrapper = styled.nav`
    display: flex;
    flex-shrink: 0;
    background: white;
    width: 100%;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;

    .tab-link {
        font-size: 14px;
        padding: 1rem 1.25rem;
        color: black;
        border-bottom: 1px solid transparent;
    }

    .tab-link.active {
        border-color: var(--primary);
        font-weight: bold;
    }

    .tab-link__count {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--primary);
        margin-left: 0.5rem;
        background: rgba(101, 79, 243, 0.08);
        border-radius: 0.25rem;
        padding: 0.25rem;
    }
`

export const OrdersTabBar: React.FC = observer(() => {
    const { orders } = useStores()
    return (
        <Wrapper>
            <NavLink className="tab-link" to="/orders">
                <span>All orders</span>
                <span className="tab-link__count">{orders.orders.length}</span>
            </NavLink>
            {/* <NavLink className="tab-link" to="/orders/pickups">
                <span>Pickups</span>
                <span className="tab-link__count">21</span>
            </NavLink>
            <NavLink className="tab-link" to="/orders/returns">
                <span>Returns</span>
                <span className="tab-link__count">12</span>
            </NavLink> */}
        </Wrapper>
    )
})
