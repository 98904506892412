import React from 'react'
import { RouteLink, RouteRequirement } from '../util'
import { LogInPage, LostPage, SignUpPage } from './auth'
import { ForgotPasswordPage } from './auth/forgot-password.page'
import {
    AnalyticsPage,
    CategoriesPage,
    DiscountsPage,
    PaymentSettingsPage,
    PlansPage,
    ProductsPage,
    ProfilePage,
    ReviewsPage,
    SettingsPage,
    SubscriptionPage,
    TransactionsPage,
} from './dashboard'
import { GeneralSettingsPage } from './dashboard/settings/general'
import {
    OnboardingStoreDetailsPage,
    OnboardingStoreOpeningHoursPage,
    OnboardingStorePage,
    OnboardingTermsPage,
    OnboardingUpdateProfilePage,
} from './onboarding'

type Component = React.FC | React.LazyExoticComponent<any>
type RoutePath = RouteLink | string

export type Route = [
    RoutePath | RoutePath[] /* Route path */,
    string /* Route name */,
    Component /* Route component */,
    RouteRequirement[] /* Route requirements */,
]

export const routes: Route[] = [
    [
        [RouteLink.Index, RouteLink.LogIn],
        'Log in',
        LogInPage,
        [RouteRequirement.NOT_AUTHENTICATED],
    ],
    [
        RouteLink.SignUp,
        'Sign up',
        SignUpPage,
        [RouteRequirement.NOT_AUTHENTICATED],
    ],
    [
        RouteLink.ForgotPassword,
        'Forgot password?',
        ForgotPasswordPage,
        [RouteRequirement.NOT_AUTHENTICATED],
    ],
    [
        RouteLink.OnboardingCreateStore,
        'Create your store',
        OnboardingStorePage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.DOES_NOT_HAVE_STORE,
        ],
    ],
    [
        RouteLink.OnboardingStoreDetails,
        'Provide additional store information',
        OnboardingStoreDetailsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.INCOMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.OnboardingStoreOpeningHours,
        'Set store opening hours',
        OnboardingStoreOpeningHoursPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.OnboardingTerms,
        'Accept the terms and conditions',
        OnboardingTermsPage,
        [RouteRequirement.AUTHENTICATED, RouteRequirement.NOT_ACCEPTED_TERMS],
    ],
    [
        RouteLink.OnboardingUpdateProfile,
        'Update your profile',
        OnboardingUpdateProfilePage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.INCOMPLETE_STORE,
        ],
    ],
    // [
    //     RouteLink.Dashboard,
    //     'Products',
    //     // Link to orders page until dashboard page is functional
    //     React.lazy(() => import('./dashboard/products/products.page')),
    //     [
    //         RouteRequirement.AUTHENTICATED,
    //         RouteRequirement.ACCEPTED_TERMS,
    //         RouteRequirement.COMPLETE_STORE,
    //         RouteRequirement.HAS_STORE,
    //     ],
    // ],
    [
        RouteLink.Orders,
        'Orders',
        React.lazy(() => import('./dashboard/orders/orders.page')),
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Order,
        'Order',
        React.lazy(() => import('./dashboard/orders/order.page')),
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Products,
        'Products',
        ProductsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Categories,
        'Categories',
        CategoriesPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Reviews,
        'Reviews',
        ReviewsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Discounts,
        'Discounts',
        DiscountsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Analytics,
        'Analytics',
        AnalyticsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    // [
    //     RouteLink.Stores,
    //     'Stores',
    //     StoresPage,
    //     [
    //         RouteRequirement.AUTHENTICATED,
    //         RouteRequirement.ACCEPTED_TERMS,
    //         RouteRequirement.COMPLETE_STORE,
    //         RouteRequirement.HAS_STORE,
    //     ],
    // ],
    [
        RouteLink.Profile,
        'Profile',
        ProfilePage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.SettingsIndex,
        'Settings',
        SettingsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.GeneralSettings,
        'General',
        GeneralSettingsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Payment,
        'Payment',
        PaymentSettingsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Transactions,
        'Transactions',
        TransactionsPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Subscription,
        'Subscription',
        SubscriptionPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_STORE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [
        RouteLink.Plans,
        'Plans',
        PlansPage,
        [
            RouteRequirement.AUTHENTICATED,
            RouteRequirement.ACCEPTED_TERMS,
            RouteRequirement.COMPLETE_PROFILE,
            RouteRequirement.HAS_STORE,
        ],
    ],
    [RouteLink.Any, 'Lost', LostPage, []],
]
