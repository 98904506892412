import { action, makeAutoObservable } from 'mobx'
import { tap } from 'rxjs'
import { UpdateProfileModel, UploadLogoModel } from '../models'
import { ProfileService } from '../services'
import { HttpMethod, request, Resettable, stores } from '../util'

export class ProfileStore implements Resettable {
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public updateProfile(model: UpdateProfileModel) {
        return request<UpdateProfileModel, never>(
            `partner/profile`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating profile',
                completionMessage: 'Partner profile updated',
            },
        ).pipe(
            tap((response) => {
                if (response.ok) {
                    stores.auth.getUser().subscribe()
                }
            }),
        )
    }

    @action
    public acceptTerms() {
        return request<never, never>(
            `partner/profile/terms`,
            HttpMethod.POST,
        ).pipe(
            tap((response) => {
                if (response.ok) {
                    stores.auth.getUser().subscribe()
                }
            }),
        )
    }

    @action
    public uploadLogo(model: UploadLogoModel) {
        return ProfileService.uploadLogo(model).pipe(
            tap((response) => {
                if (response.ok) {
                    stores.auth.getUser().subscribe()
                }
            }),
        )
    }

    @action
    public reset(): void {
        // Do nothing
    }
}
