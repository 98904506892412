import { IsEmail, Matches, MinLength } from 'class-validator'
import {
    DESCRIPTION_MIN_LENGTH,
    NAME_MIN_LENGTH,
    NAME_MIN_NUMBER,
    PASSWORD_MIN_LENGTH,
} from '../../../util/constants'
import { BaseModel } from '../base.model'

export class EmailSignupModel implements BaseModel {
    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public name: string = ''

    @IsEmail(undefined, { message: 'Must be a valid email address' })
    public email: string = ''

    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public business_name: string = ''

    @MinLength(DESCRIPTION_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public description: string = ''

    @MinLength(PASSWORD_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @Matches(/[A-Z]+/, { message: 'Must contain an uppercase letter' })
    @Matches(/[a-z]+/, { message: 'Must contain a lowercase letter' })
    @Matches(/[0-9]+/, { message: 'Must contain a number' })
    public password: string = ''

    public generateCustomValidation(): Record<keyof BaseModel, string[]> {
        const names = this.name.trim().split(/\s+/)
        const errors: Partial<Record<keyof EmailSignupModel, string[]>> = {}

        if (names.length < NAME_MIN_NUMBER) {
            errors.name = [`Must provide at least ${NAME_MIN_NUMBER} name(s)`]
        }

        return errors
    }
}
