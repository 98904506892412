import {
    IsArray,
    IsEnum,
    IsNotEmpty,
    IsOptional,
    IsPositive,
    IsString,
    MaxLength,
    MinLength,
} from 'class-validator'
import { FilePondFile } from 'filepond'
import { assign, clone, isString, map } from 'lodash'
import { NAME_MIN_LENGTH, ProductStatus, toBase64 } from '../../../util'
import { BaseModel } from '../base.model'

export class CreateProductModel implements BaseModel {
    @IsString()
    @IsNotEmpty()
    public product_code: string = ''

    @IsString()
    @IsNotEmpty()
    public sku: string = ''

    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @MaxLength(400, {
        message: 'Must be at most $constraint1 character(s) long',
    })
    public title: string = ''

    @IsString()
    @IsNotEmpty()
    public description: string = ''

    @IsString({ each: true })
    public image: string[] = []

    @IsOptional()
    public files?: (FilePondFile | string)[] = []

    @IsArray()
    @IsNotEmpty({ each: true })
    public categories: string[] = []

    @IsPositive()
    public quantity: number = 0

    @IsPositive()
    public price: number = 0

    @IsString()
    @IsNotEmpty()
    public unit: string = ''

    @IsEnum(ProductStatus, { message: 'Must be a valid status' })
    public status: string = ''

    @IsString({ each: true })
    public meta_data: string[] = []

    public transform(): void {
        this.quantity = +this.quantity
        this.price = +this.price
    }

    public static fromInitial(
        values?: Partial<CreateProductModel>,
    ): CreateProductModel {
        const model = new CreateProductModel()
        if (values) assign(model, values)
        return model
    }

    public generateCustomValidation(): Record<keyof BaseModel, string[]> {
        const errors: Partial<Record<keyof CreateProductModel, string[]>> = {}

        if (this.files && this.files.length === 0) {
            errors.files = ['Must provide a product image']
        }

        if (this.categories.length === 0) {
            errors.categories = ['Must select at least one category']
        }

        return errors
    }

    public async withProcessedImages(): Promise<CreateProductModel> {
        const model = clone(this)
        model.image = await Promise.all(
            map(this.files, async (file) => {
                if (isString(file)) {
                    return file
                }

                if (isString(file.source)) {
                    return file.source
                }

                return await toBase64(file.source as File)
            }),
        )

        delete model.files
        return model
    }
}
