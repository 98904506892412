import React, { useCallback, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { InvertedButton, Modal, PrimaryButton } from '../../../../../components'
import { UpdateOpeningHoursModel } from '../../../../../models'
import { useStores } from '../../../../../util'
import { ModalFormWrapper } from '../../../common'
import { WorkingHoursSelector } from '../components'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

export const WorkingHoursModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    const { stores } = useStores()
    const subscriptions: Subscription[] = []
    const [workingHours, setWorkingHours] = useState(
        new UpdateOpeningHoursModel(stores.selectedStore ?? undefined),
    )

    const [isValid, setIsValid] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setIsSubmitting(true)

        const subscription = stores.updateOpeningHours(workingHours).subscribe({
            next(response) {
                if (response.ok) {
                    setIsOpen(false)
                }
            },
            complete() {
                setIsSubmitting(false)
            },
        })

        subscriptions.push(subscription)
    }

    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Working Time">
            <ModalFormWrapper onSubmit={handleSubmit}>
                <main className="form__content">
                    <WorkingHoursSelector
                        workingHours={workingHours}
                        setWorkingHours={setWorkingHours}
                        setIsValid={setIsValid}
                    />
                </main>
                <footer className="form__footer">
                    <InvertedButton
                        className="mr-3"
                        type="button"
                        onClick={() => setIsOpen(false)}
                    >
                        <span>Cancel</span>
                    </InvertedButton>
                    <PrimaryButton
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        isLoading={isSubmitting}
                    >
                        <span>Save working hours</span>
                    </PrimaryButton>
                </footer>
            </ModalFormWrapper>
        </Modal>
    )
}
