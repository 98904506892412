import { useField } from 'formik'
import React, { memo, useCallback, useMemo } from 'react'
import { getTrackBackground, Range } from 'react-range'
import {
    IRenderThumbParams,
    IRenderTrackParams,
    ITrackProps,
} from 'react-range/lib/types'
import { getClassNames } from '../../util'
import { ValidationMessage } from '../messages'

interface Props {
    name: string
    className?: string
    label?: string
    description?: string
    min: number
    max: number
    step: number
}

interface TrackProps {
    props: ITrackProps
    children: React.ReactNode
    min: number
    max: number
    step: number
    values: number[]
}

const Track: React.FC<TrackProps> = memo(
    ({ props, children, max, min, values }) => {
        return (
            <div
                {...props}
                className={getClassNames('h-1 w-full relative rounded-[1px]')}
                style={{
                    background: getTrackBackground({
                        colors: ['#3c358a', '#E3E3E3'],
                        max,
                        min,
                        values,
                    }),
                }}
            >
                {children}
            </div>
        )
    },
)

export const SingleRangeInput: React.FC<Props> = ({
    name,
    className,
    label,
    description,
    min,
    max,
    step,
}) => {
    const [field, meta, helpers] = useField<number>(name)
    const invalid = useMemo(() => meta.error && meta.touched, [meta])
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    const onChange = useCallback(
        (values: number[]) => {
            const value = values[0]
            helpers.setTouched(true)
            helpers.setValue(value)
        },
        [helpers],
    )

    const values = useMemo(() => {
        return [field.value]
    }, [field.value])

    const renderTrack = useCallback(
        ({ props, children }: IRenderTrackParams) => (
            <Track
                props={props}
                children={children}
                min={min}
                max={max}
                step={step}
                values={values}
            />
        ),
        [min, max, step, values],
    )

    const renderThumb = useCallback(
        ({ props }: IRenderThumbParams) => (
            <div
                {...props}
                className="flex flex-col relative"
                style={{
                    ...props.style,
                    backgroundColor: '#999',
                }}
            >
                <div
                    className={getClassNames(
                        'rounded-[4px] flex py-[2px] px-3.5 transform bg-primary absolute bottom-2',
                        values[0] === max && '-translate-x-full',
                        values[0] !== min &&
                            values[0] !== max &&
                            '-translate-x-1/2',
                    )}
                >
                    <span className="text-white text-xs font-bold">
                        {values[0]}
                    </span>
                </div>
            </div>
        ),
        [values, min, max],
    )

    return (
        <div className="flex flex-col">
            {label ? (
                <div className="flex flex-col mb-8">
                    <span className="text-xs text-[#333]">{label}</span>
                    {description ? (
                        <span className="text-[10px] text-[#646464]">
                            {description}
                        </span>
                    ) : null}
                </div>
            ) : null}
            <Range
                min={min}
                max={max}
                step={step}
                values={values}
                onChange={onChange}
                renderTrack={renderTrack}
                renderThumb={renderThumb}
            />
            {invalid && (
                <div className="grid grid-cols-1 gap-2 mt-3">
                    {errors.map((message, i) => (
                        <ValidationMessage
                            key={i}
                            message={message}
                            type="error"
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
