import { Order } from '../../../../models'
import React, { useMemo } from 'react'
import { formatAsCurrency, getStatusColors } from '../../../../util'
import { HeadedBox } from '../../common'

interface Props {
    order: Order
}

export const OrderItemsBlock: React.FC<Props> = ({ order }) => {
    const orderInformation = useMemo(() => {
        const subtotal = order.sub_total

        return {
            subtotal,
            total: subtotal + order.shipping_fee,
            itemCount: order.orders.length,
            status: getStatusColors(order.status.status),
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    return (
        <HeadedBox
            heading={`Items (${orderInformation.itemCount})`}
            footerElement={
                <table className="clear-table">
                    <tbody>
                        <tr>
                            <td>
                                <span>Total</span>
                            </td>
                            <td>
                                <span className="flex justify-end">
                                    <b
                                        style={{
                                            color: '#646464',
                                        }}
                                    >
                                        {formatAsCurrency(
                                            orderInformation.subtotal,
                                        )}
                                    </b>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        >
            <table className="clear-table mb-3">
                <tbody>
                    {order.orders.map((orderItem, i) => (
                        <tr key={i}>
                            <td>
                                <div
                                    style={{
                                        backgroundImage: `url(${orderItem.product.image[0]})`,
                                    }}
                                    className="py-1 h-8 w-8 bg-slate-200 bg-center bg-no-repeat bg-contain"
                                />
                            </td>
                            <td>
                                <b>{orderItem.title}</b>
                            </td>
                            <td>
                                <b>{formatAsCurrency(orderItem.price)}</b>
                            </td>
                            <td>
                                <b>{orderItem.quantity}</b>
                            </td>
                            <td>
                                <span className={'flex justify-end'}>
                                    <b>
                                        {formatAsCurrency(
                                            orderItem.quantity *
                                                orderItem.price,
                                        )}
                                    </b>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </HeadedBox>
    )
}
