import {
    Allow,
    IsNumber,
    IsOptional,
    Max,
    Min,
    ValidateNested,
} from 'class-validator'
import { FilePondFile } from 'filepond'
import { clone, isString } from 'lodash'
import { toBase64 } from '../../../util'
import { formatPostcode } from '../../../util/formatting/postcode'
import { Store } from '../../response'
import { ContactPersonModel } from './update-store-configuration.model'
import { UpdateStoreInformationModel } from './update-store-information.model'

export class CreateStoreModel extends UpdateStoreInformationModel {
    @Min(1, { message: 'Must be at least $constraint1 mile(s)' })
    @Max(10, { message: 'Must be at most $constraint1 mile(s)' })
    @IsNumber(undefined, { message: 'Must be a number' })
    public mile_radius: number = 1

    @Allow()
    public category: string = ''

    @Allow()
    public logo?: string = ''

    @IsOptional()
    public files?: (FilePondFile | string)[] = []

    @ValidateNested({ message: 'Contact person details are invalid ' })
    public contact_person: ContactPersonModel

    constructor(store?: Store) {
        super(store)
        this.contact_person = new ContactPersonModel()
    }

    public generateCustomValidation() {
        const errors: Record<string, string[]> = {}

        if (this.files && this.files.length === 0) {
            errors.files = ['Must provide a logo']
        }

        return errors
    }

    public async withProcessedImages(): Promise<CreateStoreModel> {
        const model = clone(this)
        const file = model.files![0]

        if (isString(file)) {
            model.logo = file
        } else if (isString(file.source)) {
            model.logo = file.source
        } else {
            model.logo = await toBase64(file.source as File)
        }

        delete model.files
        return model
    }

    public transform(): void {
        super.transform()
        this.mile_radius = +this.mile_radius
    }

    public getRequestBody() {
        const model = clone(super.getRequestBody())
        model.postcode = formatPostcode(model.postcode)
        return model
    }
}
