import { Icon } from '@iconscout/react-unicons'
import React from 'react'
import { formatAsCurrency } from '../../../../util'

interface Props {
    heading?: string | number
    isCurrency?: boolean
    subheading: string
    iconColor: string
    icon: Icon
}

export const PaymentDisplayBox: React.FC<Props> = ({
    icon: UilIcon,
    iconColor,
    isCurrency,
    heading,
    subheading,
}) => {
    const formattedHeading = isCurrency
        ? formatAsCurrency((heading as number) ?? 0)
        : heading

    return (
        <div className="flex border items-center border-[#F2F2F2] justify-between bg-white p-4 w-full rounded-lg">
            <div className="flex flex-1 items-center justify-between flex-wrap">
                <div className="flex flex-col">
                    <span className="font-bold text-[21px]">
                        {formattedHeading}
                    </span>
                    <span className="uppercase text-[10px] tracking-[0.09em] font-bold">
                        {subheading}
                    </span>
                </div>
                <UilIcon
                    className="opacity-20"
                    style={{ color: iconColor }}
                    size={54}
                />
            </div>
        </div>
    )
}
