import { action, makeAutoObservable, runInAction } from 'mobx'
import { switchMap, tap } from 'rxjs'
import {
    EmailLoginModel,
    EmailSignupModel,
    ForgotPasswordModel,
    ResetPasswordModel,
    User,
    VerifyPhoneNumberModel,
} from '../models'
import { AuthService } from '../services'
import {
    dehydrateToStorage,
    HttpMethod,
    request,
    Resettable,
    stores,
} from '../util'
import { hydrateFromStorage, removeFromStorage } from '../util/misc/storage'
import { appState, MessageType } from './app.store'

const TOKEN_KEY = 'ETHCO:TOKEN'
const USER_KEY = 'ETHCO:USER'
const SKIP_PHONE_VERIFICATION_KEY = 'ETHCO:SKIP-PHONE-VERIFICATION'

export class AuthStore implements Resettable {
    public user!: User | null
    public token!: string | null
    public ready: boolean = false

    public get authenticated(): boolean {
        return !!this.user && !!this.token
    }

    public get verified(): boolean {
        return (this.user?.email_verified || this.user?.phone_verified) ?? false
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setUp(): void {
        this.token = hydrateFromStorage(TOKEN_KEY)
        this.user = hydrateFromStorage(USER_KEY)
    }

    @action
    public setToken(token: string): void {
        this.token = token
        dehydrateToStorage(TOKEN_KEY, token)
    }

    @action
    public setUser(user: User): void {
        this.user = user
        dehydrateToStorage(USER_KEY, user)
    }

    @action
    public logIn(user: User, token: string): void {
        this.setUser(user)
        this.setToken(token)
        stores.stores.listStores().subscribe()
        ;(window as any).pendo.initialize({
            visitor: { id: user.id },
            account: {},
        })
    }

    @action
    public skipPhoneVerification(): void {
        dehydrateToStorage(SKIP_PHONE_VERIFICATION_KEY, true)
    }

    @action
    public getUser() {
        this.ready = false

        return AuthService.getUser().pipe(
            tap((response) => {
                if (!response.data) {
                    this.signOut()
                } else {
                    this.logIn(response.data, this.token!)
                }

                runInAction(() => {
                    this.ready = true
                })
            }),
        )
    }

    @action
    public logInWithEmail(model: EmailLoginModel) {
        return AuthService.logInWithEmail(model).pipe(
            tap((response) => {
                if (!response.token) {
                    return
                }

                this.logIn(response.user, response.token)
            }),
        )
    }

    @action
    public requestPasswordReset(model: ForgotPasswordModel) {
        return AuthService.requestPasswordReset(model).pipe(
            tap((response) => {
                return response.ok
            }),
        )
    }

    @action
    public resetPassword(model: ResetPasswordModel) {
        return request(
            `auth/confirm/${model.code}/${model.email}`,
            HttpMethod.GET,
        ).pipe(
            switchMap((response: any) => {
                model.attachToken(response.token)

                return request(`auth/reset/password`, HttpMethod.POST, {
                    body: model,
                    loadingMessage: 'Resetting password',
                    completionMessage: 'Password reset successful',
                })
            }),
        )
    }

    @action
    public requestOtp() {
        return AuthService.requestOtp().pipe(
            tap((response) => {
                return response.ok
            }),
        )
    }

    @action
    public verifyPhoneNumber(model: VerifyPhoneNumberModel) {
        return AuthService.verifyPhoneNumber(model).pipe(
            tap((response) => {
                if (response.ok) {
                    this.user!.partner.phone = model.phone
                    this.user!.phone_verified = true
                    this.setUser(this.user!)
                }

                return response.ok
            }),
        )
    }

    @action
    public signUpWithEmail(model: EmailSignupModel) {
        return AuthService.signUpWithEmail(model).pipe(
            tap((response) => {
                if (response.ok) {
                    appState.createMessage(
                        'Sign up request successful',
                        MessageType.SUCCESS,
                    )

                    this.logInWithEmail(model).subscribe()
                }
            }),
        )
    }

    @action
    public signOut(): void {
        stores.reset()
    }

    @action
    public reset(): void {
        this.user = null
        this.token = null

        removeFromStorage(TOKEN_KEY, USER_KEY, SKIP_PHONE_VERIFICATION_KEY)
    }
}
