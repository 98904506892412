import { Allow, IsEnum } from 'class-validator'
import { ProductStatus } from '../../../util'
import { Product } from '../../response'
import { BaseModel } from '../base.model'

export class EditProductStatusModel implements BaseModel {
    @Allow()
    public id: string

    @IsEnum(ProductStatus, { message: 'Must be a valid status' })
    public status: string

    constructor(product: Product) {
        this.id = product._id
        this.status = product.status ?? ProductStatus.Published
    }
}
