import { UilExclamationTriangle, UilSitemap } from '@iconscout/react-unicons'
import React from 'react'
import { getClassNames } from '../../util'

interface Props {
    type: 'info' | 'error'
    message: string
}

export const ValidationMessage: React.FC<Props> = ({ type, message }) => {
    return (
        <div
            className={getClassNames(
                'w-full text-[.75rem] font-[500] bg-[#f5f1f1] inline-flex px-4 py-1 rounded-lg items-center',
                type === 'info' && 'text-[#252525]',
                type === 'error' && 'text-[#a11717]',
            )}
        >
            {type === 'error' && <UilExclamationTriangle size={14} />}
            {type === 'info' && <UilSitemap size={14} />}
            <span className="ml-4">{message}</span>
        </div>
    )
}
