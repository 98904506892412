import { loadStripe } from '@stripe/stripe-js'
import { find, first, orderBy } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { PlanSubscription, SubscribeResponse } from '../models'
import { Plan } from '../models/response/subscription/plan'
import {
    formatAsCurrency,
    HttpMethod,
    request,
    Resettable,
    stores,
} from '../util'
import { hydrateFromStorage } from '../util/misc/storage'

const PLANS_KEY = 'ETHCO:PLANS'

export class SubscriptionsStore implements Resettable {
    public stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)
    public subscriptionClientSecret: string | null = null

    // Subscription Plans
    public plansLoaded: boolean = false
    public plans: any[] = []
    public openPlan: any = null

    // Subscriptions
    public subscriptionsLoaded: boolean = false
    public subscriptions: PlanSubscription[] = []
    public openSubscription: any = null

    @computed
    public get currentSubscription(): PlanSubscription | null {
        return first(this.subscriptions) ?? null
    }

    @computed
    public get planInfo() {
        if (!this.currentSubscription) {
            return {
                subscription: null,
                isActive: false,
                cost: 0,
            }
        }

        const subscription = this.currentSubscription
        const cost = formatAsCurrency(
            this.currentSubscription.plan[
                ('cost_' +
                    this.currentSubscription.plan
                        .duration) as unknown as keyof Plan
            ] as number,
        )

        const isActive = ['active', 'pending'].includes(subscription.status)

        return {
            subscription,
            isActive,
            cost,
        }
    }

    @computed
    public get basicPlan(): any | null {
        const basicPlanId = process.env.REACT_APP_STANDARD_PLAN_ID
        return (
            find(
                this.plans,
                basicPlanId ? { id: basicPlanId } : { name: 'basic' },
            ) ?? null
        )
    }

    @computed
    public get standardPlan(): any | null {
        const standardPlanId = process.env.REACT_APP_STANDARD_PLAN_ID
        return (
            find(
                this.plans,
                standardPlanId ? { id: standardPlanId } : { name: 'standard' },
            ) ?? null
        )
    }

    @computed
    public get premiumPlan(): any | null {
        const premiumPlanId = process.env.REACT_APP_PREMIUM_PLAN_ID
        return (
            find(
                this.plans,
                premiumPlanId ? { id: premiumPlanId } : { name: 'premium' },
            ) ?? null
        )
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadPlans()
    }

    @action
    public loadPlans() {
        const plans = hydrateFromStorage<any[]>(PLANS_KEY, true)

        if (Array.isArray(plans)) {
            this.plans = plans
            this.plansLoaded = true
        }
    }

    @action
    public listPlans() {
        this.plansLoaded = false

        return request(`partner/subscriptions/plans`, HttpMethod.GET, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    this.plansLoaded = true

                    if (response.data) {
                        this.plans = response.data
                    }
                })
            }),
        )
    }

    @action
    public retrievePlan(id: string) {
        return request(
            `partner/subscriptions/plans/${id}`,
            HttpMethod.GET,
            {},
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.openPlan = response.data
                    }
                })
            }),
        )
    }

    @action
    public subscribeToPlan(model: any) {
        const store = stores.stores.selectedStore
        return request<any, SubscribeResponse>(
            `partner/subscriptions/${store?.id}/my`,
            HttpMethod.POST,
            {
                body: model,
                query: {
                    duration: 'monthly',
                },
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.subscriptionClientSecret =
                            response.data.client_secret
                    }
                })
            }),
        )
    }

    @action
    public listSubscriptions() {
        const store = stores.stores.selectedStore
        this.subscriptionsLoaded = false

        return request(
            `partner/subscriptions/${store?.id}/my`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    this.subscriptionsLoaded = true

                    if (response.data) {
                        this.subscriptions = orderBy(
                            response.data,
                            'id',
                            'desc',
                        ) as any
                    }
                })
            }),
        )
    }

    @action
    public retrieveSubscription(id: string) {
        const store = stores.stores.selectedStore
        return request(
            `partner/subscriptions/${store?.id}/${id}`,
            HttpMethod.GET,
            {},
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.subscriptions = response.data
                    }
                })
            }),
        )
    }

    @action
    public upgradeSubscription(id: string, model: any) {
        const store = stores.stores.selectedStore
        return request(
            `partner/subscriptions/${store?.id}/${id}/upgrade`,
            HttpMethod.PATCH,
            { body: model },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    //
                })
            }),
        )
    }

    @action
    public cancelSubscription(id: string) {
        const store = stores.stores.selectedStore
        return request(
            `partner/subscriptions/${store?.id}/${id}/cancel`,
            HttpMethod.PATCH,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listSubscriptions().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.subscriptionClientSecret = null
        // No need to clear the list of plans
        this.subscriptions = []
        this.subscriptionsLoaded = false
        this.openPlan = null
        this.openSubscription = null
    }
}
