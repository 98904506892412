import {
    Allow,
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsPositive,
} from 'class-validator'
import { DiscountLevel, DiscountStatus, DiscountType } from '../../../util'
import { BaseModel } from '../base.model'

export class CreateDiscountModel implements BaseModel {
    @IsPositive({ message: 'Must be a positive number' })
    @IsNotEmpty({ message: 'Must be provided' })
    public offer: number = 0

    @IsEnum(DiscountType, { message: 'Must be a valid discount type' })
    @IsNotEmpty({ message: 'Must be provided' })
    public type: string = ''

    @IsEnum(DiscountLevel, { message: 'Must be a valid discount level' })
    @IsNotEmpty({ message: 'Must be provided' })
    public level: string = ''

    @IsNumber({}, { message: 'Must be at least zero' })
    @IsNotEmpty({ message: 'Must be provided' })
    public min_amount: number = 0

    @IsEnum(DiscountStatus, { message: 'Must be a valid discount status' })
    @IsNotEmpty({ message: 'Must be provided' })
    public status: string = ''

    @Allow()
    public products: string[] = []

    @Allow()
    public categories: string[] = []

    @IsBoolean()
    public all_products: boolean = false

    public transform() {
        this.offer = +this.offer
        this.min_amount = +this.min_amount
    }

    public getRequestBody() {
        return {
            discount: {
                offer: this.offer,
                type: this.type,
                min_amount: this.min_amount,
                status: this.status,
            },
            products: this.products,
            categories: this.categories,
            all_products: this.all_products,
        }
    }
}
