import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { Subscription } from 'rxjs'
import styled from 'styled-components'
import {
    InvertedButton,
    Modal,
    PrimaryButton,
    TextAreaInput,
} from '../../../../components'
import { Order, OrderStatus, UpdateOrderStatusModel } from '../../../../models'
import { formatAsCurrency, useStores, validateModel } from '../../../../util'
import { ModalFormWrapper } from '../../common'

interface Props {
    order: Order
    isOpen: boolean
    setIsOpen: (x: boolean) => any
}

const Label = styled.label`
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 0.25rem;
`

export const CancelOrderModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, order }) => {
        const { orders } = useStores()
        const subscriptions: Subscription[] = []
        const initialFormValue = new UpdateOrderStatusModel(order)
        initialFormValue.status = OrderStatus.CANCELLED

        const subtotal = order.sub_total
        const unsubscribe = useCallback(() => {
            subscriptions.map((subscription) => subscription.unsubscribe())
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            return () => {
                unsubscribe()
            }
        }, [unsubscribe])

        return (
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Cancel order"
                titleColor="#CA2626"
            >
                <Formik
                    enableReinitialize
                    validate={validateModel}
                    initialValues={initialFormValue}
                    onSubmit={(values, { setSubmitting }) => {
                        unsubscribe()
                        setSubmitting(true)

                        const subscription = orders
                            .updateOrderStatus(values)
                            .subscribe({
                                next: (response) => {
                                    setSubmitting(false)

                                    if (response.ok) {
                                        setIsOpen(false)
                                        orders.listOrders().subscribe()
                                    }
                                },
                            })

                        subscriptions.push(subscription)
                    }}
                >
                    {({ isValid, isSubmitting, handleSubmit }) => (
                        <ModalFormWrapper onSubmit={handleSubmit}>
                            <main className="form__content">
                                <div className="form__content-grid">
                                    <div>
                                        <Label className="flex">Customer</Label>
                                        <span className="flex">
                                            {order.pickup.name}
                                        </span>
                                    </div>
                                    <div>
                                        <Label className="flex">Price</Label>
                                        <b className="flex">
                                            {formatAsCurrency(subtotal)}
                                        </b>
                                    </div>
                                    <div>
                                        <Label className="flex">Address</Label>
                                        <span className="flex">
                                            {order.pickup.address}
                                        </span>
                                    </div>
                                    <TextAreaInput
                                        name="reason"
                                        label="Reason for status change (optional)"
                                        placeholder="Enter reason"
                                    />
                                </div>
                            </main>
                            <footer className="form__footer">
                                <InvertedButton
                                    className="mr-3"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span>Cancel</span>
                                </InvertedButton>
                                <PrimaryButton
                                    type="submit"
                                    style={{
                                        background: '#CA2626',
                                        borderColor: '#CA2626',
                                    }}
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Cancel order</span>
                                </PrimaryButton>
                            </footer>
                        </ModalFormWrapper>
                    )}
                </Formik>
            </Modal>
        )
    },
)
