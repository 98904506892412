import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactModal from 'react-modal'
import { ErrorBoundary, Router } from './components'
import './index.css'
import reportWebVitals from './report-web-vitals'

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)
ReactModal.setAppElement(container)

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Router />
        </ErrorBoundary>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
