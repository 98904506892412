import { Option } from './option'

export enum ProductStatus {
    Published = 'published',
    Unpublished = 'unpublished',
}

export const productStatusMap = {
    [ProductStatus.Published]: 'Published',
    [ProductStatus.Unpublished]: 'Unpublished',
}

export const productStatusOptions: Option[] = [
    {
        key: ProductStatus.Published,
        value: productStatusMap[ProductStatus.Published],
    },
    {
        key: ProductStatus.Unpublished,
        value: productStatusMap[ProductStatus.Unpublished],
    },
]
