import { forEach, isFunction, values } from 'lodash'

import {
    AnalyticsStore,
    appState,
    AuthStore,
    CategoriesStore,
    DiscountsStore,
    MiscStore,
    OrdersStore,
    PaymentsStore,
    ProductsStore,
    ProfileStore,
    ReviewsStore,
    StoresStore,
    SubscriptionsStore,
} from '../stores'

type Stores = {
    // Store classes
    analytics: AnalyticsStore
    auth: AuthStore
    categories: CategoriesStore
    discounts: DiscountsStore
    misc: MiscStore
    orders: OrdersStore
    payments: PaymentsStore
    products: ProductsStore
    profile: ProfileStore
    reviews: ReviewsStore
    stores: StoresStore
    subscriptions: SubscriptionsStore

    // Store functions
    reset: () => void
}

export const stores: Stores = {
    analytics: new AnalyticsStore(),
    auth: new AuthStore(),
    categories: new CategoriesStore(),
    discounts: new DiscountsStore(),
    misc: new MiscStore(),
    orders: new OrdersStore(),
    payments: new PaymentsStore(),
    products: new ProductsStore(),
    profile: new ProfileStore(),
    reviews: new ReviewsStore(),
    stores: new StoresStore(),
    subscriptions: new SubscriptionsStore(),

    reset(): void {
        appState.reset()
        forEach(values(this), (value: any) => {
            const reset = value.reset

            if (reset && isFunction(reset)) {
                reset()
            }
        })
    },
}

export const useStores = () => {
    return stores
}
