import Fuse from 'fuse.js'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { firstValueFrom, map, tap } from 'rxjs'
import { Category, CreateCategoryModel, EditCategoryModel } from '../models'
import { CategoriesService } from '../services'
import { CategoryVisibility, HttpMethod, request, Resettable } from '../util'

const MAX_MATCH_SCORE = 0.1 // 0 is perfect match

export class CategoriesStore implements Resettable {
    public categories: Category[] = []
    public categoriesLoaded = false

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public listCategories(silent?: boolean) {
        this.categoriesLoaded = false
        return CategoriesService.listCategories(silent).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.categories = response.data
                        this.categoriesLoaded = true
                    }
                })
            }),
        )
    }

    @action
    public retrieveCategory() {
        return CategoriesService.listCategories().pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.categories = response.data
                    }
                })
            }),
        )
    }

    @action
    public deleteCategory(id: string) {
        return request(`partner/categories/${id}`, HttpMethod.DELETE, {
            loadingMessage: 'Deleting category',
            completionMessage: 'Category deleted',
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCategories().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public createCategory(
        model: CreateCategoryModel,
        silent?: boolean,
        suppressReload?: boolean,
    ) {
        return CategoriesService.createCategory(model, silent).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && !suppressReload) {
                        this.listCategories().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public findOrCreateCategoryByName(name: string) {
        const categories = this.categories
        const fuse = new Fuse(categories, {
            includeScore: true,
            keys: ['title'],
        })

        const results = fuse.search(name)
        const match = results[0]

        if (match && match.score! <= MAX_MATCH_SCORE) {
            const category = categories[match.refIndex]
            return Promise.resolve(category)
        }

        const model = new CreateCategoryModel()
        model.title = name
        model.visibility = CategoryVisibility.InStock

        return firstValueFrom(
            this.createCategory(model, true, true).pipe(
                map((response) => {
                    return response.ok ? (model as Category) : null
                }),
            ),
        )
    }

    @action
    public async findOrCreateCategoriesByName(
        names: string[],
        suppressReload?: boolean,
    ) {
        const categories: (Category | null)[] = []

        for (const name of names) {
            const category = await this.findOrCreateCategoryByName(name)
            categories.push(category)
        }

        if (!suppressReload) {
            await firstValueFrom(this.listCategories())
        }

        return categories
    }

    @action
    public editCategory(model: EditCategoryModel) {
        return CategoriesService.editCategory(model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCategories().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.categories = []
    }
}
