import { Observable } from 'rxjs'
import { GetPostcodeInformationModel } from '../models'
import { HttpMethod, request, Response } from '../util'

export class MiscService {
    public static getPostcodeInformation(
        model: GetPostcodeInformationModel,
    ): Observable<Response> {
        return request(
            `addresses/${model.postcode.replace(/\s*/, '')}`,
            HttpMethod.GET,
            {
                loadingMessage: 'Getting postcode information',
            },
        )
    }
}
