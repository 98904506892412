import styled from 'styled-components'

export const Input = styled.input`
    padding: 0 0.75rem;
    background: #fff;
    border: none;
    outline: none;
    flex: 1;
    font-size: 0.75rem;

    &[type='password'] {
        padding-right: 0;
    }

    ::placeholder {
        color: #c4c4c4;
    }
`
