import { Icon as SvgIcon, UilEye } from '@iconscout/react-unicons'
import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { getClassNames } from '../../util'
import { ValidationMessage } from '../messages'
import { Input } from './common'

const Wrapper = styled.label`
    display: flex;
    flex-direction: column;

    .text-input__label {
        color: #333;
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
    }

    .text-input__body {
        background: white;
        overflow: hidden;
        border: 1px solid #bfbfbf;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        --height: calc(2.5rem + 2px);
        height: var(--height);

        :focus-within {
            border-color: black;
        }
    }

    .text-input__icon {
        margin-left: 0.75rem;
        color: #333;
    }

    .text-input__input {
        width: 100%;
        height: var(--height);
    }

    .text-input__visibility-toggle {
        width: var(--height);
        height: var(--height);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: transparent;

        :focus,
        :hover {
            background: #eeeeee;
        }
    }

    .text-input__error-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        margin-top: 0.75rem;
    }

    .text-input__error-message {
        color: #a11717;
        font-size: 0.85rem;
        font-weight: 500;
        background: #f5f1f1;
        display: inline-flex;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        align-items: center;
    }

    .text-input__error-message__text {
        margin-left: 1rem;
    }
`

interface Option {
    label: string
    value: string
}

interface Props extends React.ComponentPropsWithoutRef<'input'> {
    name: string
    label?: string
    placeholder: string
    showMultipleErrors?: boolean
    as?: any
    icon?: SvgIcon
    options?: Option[]
}

export const TextInput: React.FC<Props> = ({
    name,
    label,
    className,
    options,
    icon: Icon,
    showMultipleErrors,
    ...props
}) => {
    const [field, meta] = useField({ name })
    const invalid = React.useMemo(() => meta.error && meta.touched, [meta])
    const valid = React.useMemo(() => !meta.error && meta.touched, [meta])
    const [isVisible, setIsVisible] = React.useState(false)
    const errors: string[] = meta.error
        ? (meta.error as unknown as string[])
        : []

    const type: typeof props.type =
        props.type === 'password'
            ? isVisible
                ? 'text'
                : 'password'
            : props.type

    const toggleVisibility = React.useCallback(() => {
        setIsVisible((x) => !x)
    }, [setIsVisible])

    return (
        <Wrapper
            className={getClassNames(
                className,
                invalid && 'invalid',
                valid && 'valid',
            )}
        >
            {label && <span className="text-input__label">{label}</span>}
            <div className="text-input__body">
                {Icon && <Icon className="text-input__icon" size={16} />}
                <Input
                    {...field}
                    {...props}
                    list={options ? 'datalist-' + name : undefined}
                    type={type}
                    className="text-input__input"
                />
                {options && (
                    <datalist id={'datalist-' + name}>
                        {options.map(({ label: optionLabel, value }, i) => (
                            <option key={i} value={value}>
                                {optionLabel}
                            </option>
                        ))}
                    </datalist>
                )}
                {props.type === 'password' ? (
                    <button
                        className="text-input__visibility-toggle"
                        type="button"
                        onClick={toggleVisibility}
                    >
                        <UilEye size={16} />
                    </button>
                ) : null}
            </div>
            {invalid && (
                <div className="text-input__error-section">
                    {(showMultipleErrors ? errors : errors.slice(0, 1)).map(
                        (message, i) => (
                            <ValidationMessage
                                key={i}
                                message={message}
                                type="error"
                            />
                        ),
                    )}
                </div>
            )}
        </Wrapper>
    )
}
