import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import {
    Transaction,
    TransactionAggregate,
} from '../models/response/transaction'
import { HttpMethod, request, Resettable, stores } from '../util'

export class PaymentsStore implements Resettable {
    public paymentMethods: any[] = []
    public transactionAggregate: TransactionAggregate | null = null
    public transactionsLoading: boolean = false

    @computed
    public get transactions(): Transaction[] {
        return this.transactionAggregate?.transactions.data ?? []
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public listTransactions() {
        const storeId = stores.stores.selectedStore?.id
        this.transactionsLoading = true

        return request(`partner/transactions/${storeId}`, HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    this.transactionsLoading = false

                    if (response.data) {
                        this.transactionAggregate = response.data
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.paymentMethods = []
        this.transactionAggregate = null
    }
}
