import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import {
    RouteLink,
    RouteRequirement,
    stores as storesCollection,
    useStores,
} from '../../util'

interface Props {
    requirements: RouteRequirement[]
    title: string
    component: React.FC
}

enum RouteOutcome {
    ALLOWED,
    LOADING,
}

const DASHBOARD_LINK = RouteLink.Products

const getComponent = (requirement: RouteRequirement) => {
    const auth = storesCollection.auth
    const stores = storesCollection.stores

    switch (requirement) {
        case RouteRequirement.AUTHENTICATED:
            if (auth.authenticated) break
            return <Navigate to={RouteLink.LogIn} replace />

        case RouteRequirement.NOT_AUTHENTICATED:
            if (!auth.authenticated) break
            return <Navigate to={DASHBOARD_LINK} replace />

        case RouteRequirement.HAS_STORE:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (!stores.storesLoaded) return RouteOutcome.LOADING

            if (!stores.hasStore)
                return <Navigate to={RouteLink.OnboardingCreateStore} replace />

            break

        case RouteRequirement.COMPLETE_PROFILE:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (!auth.user?.partner.logo || !auth.user.partner.phone)
                return (
                    <Navigate to={RouteLink.OnboardingUpdateProfile} replace />
                )

            break

        case RouteRequirement.INCOMPLETE_PROFILE:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (auth.user?.partner.logo && auth.user.partner.phone)
                return <Navigate to={DASHBOARD_LINK} replace />

            break

        case RouteRequirement.COMPLETE_STORE:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (!stores.storesLoaded) return RouteOutcome.LOADING

            if (!stores.selectedStore?.logo)
                return (
                    <Navigate to={RouteLink.OnboardingStoreDetails} replace />
                )

            break

        case RouteRequirement.INCOMPLETE_STORE:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (!stores.storesLoaded) return RouteOutcome.LOADING

            if (stores.selectedStore?.logo)
                return <Navigate to={DASHBOARD_LINK} replace />

            break

        case RouteRequirement.ACCEPTED_TERMS:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (!auth.user?.partner.accepted_terms)
                return <Navigate to={RouteLink.OnboardingTerms} replace />

            break

        case RouteRequirement.NOT_ACCEPTED_TERMS:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (auth.user?.partner.accepted_terms)
                return <Navigate to={DASHBOARD_LINK} replace />

            break

        case RouteRequirement.DOES_NOT_HAVE_STORE:
            if (!auth.authenticated)
                return <Navigate to={RouteLink.LogIn} replace />

            if (!stores.storesLoaded) return RouteOutcome.LOADING

            if (stores.hasStore) {
                if (!stores.openingHoursSet)
                    return (
                        <Navigate
                            to={RouteLink.OnboardingStoreOpeningHours}
                            replace
                        />
                    )

                return <Navigate to={DASHBOARD_LINK} replace />
            }

            break

        case RouteRequirement.PHONE_VERIFIED:
            if (auth.authenticated && auth.user?.phone_verified) break
            return <Navigate to={RouteLink.VerifyPhone} replace />

        case RouteRequirement.PHONE_NOT_VERIFIED:
            if (auth.authenticated && !auth.user?.phone_verified) break
            return <Navigate to={DASHBOARD_LINK} replace />

        case RouteRequirement.EMAIL_VERIFIED:
            if (auth.authenticated && auth.user?.email_verified) break
            return <Navigate to={DASHBOARD_LINK} replace />

        case RouteRequirement.EMAIL_NOT_VERIFIED:
            if (auth.authenticated && !auth.user?.email_verified) break
            return <Navigate to={DASHBOARD_LINK} replace />

        case RouteRequirement.EITHER_VERIFIED:
            if (
                auth.authenticated &&
                (auth.user?.email_verified || auth.user?.phone_verified)
            )
                break
            return <Navigate to={DASHBOARD_LINK} replace />

        case RouteRequirement.BOTH_VERIFIED:
            if (
                auth.authenticated &&
                auth.user?.email_verified &&
                auth.user.phone_verified
            )
                break
            return <Navigate to={DASHBOARD_LINK} replace />
    }

    return RouteOutcome.ALLOWED
}

export const RouteComponentWrapper: React.FC<Props> = observer(
    ({ requirements, title, component: Component }) => {
        const { auth, stores } = useStores()
        const [component, setComponent] = useState<
            ReturnType<typeof getComponent> | typeof Component | null
        >(null)

        const getPage = useCallback(() => {
            const page = (() => {
                for (const requirement of requirements) {
                    const response = getComponent(requirement)

                    switch (response) {
                        case RouteOutcome.ALLOWED:
                            continue

                        case RouteOutcome.LOADING:
                            return null

                        default:
                            return response
                    }
                }

                return <Component />
            })()

            setComponent(page)
        }, [Component, requirements])

        React.useEffect(() => {
            document.title = `Ethco | ${title}`
        }, [title])

        useEffect(() => {
            getPage()
        }, [getPage, auth.user, stores.stores])

        return component as any
    },
)
