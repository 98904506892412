import { min, times } from 'lodash'
import React from 'react'

interface Props {
    stars: number
}

export const ReviewStars: React.FC<Props> = ({ stars }) => {
    const MAX_STARS = 5
    const orangeCount = min([MAX_STARS, stars]) as number
    const greyCount = MAX_STARS - orangeCount

    return (
        <div className="inline-flex">
            <div className="grid grid-cols-5 gap-0.5">
                {times(orangeCount, (i) => {
                    return (
                        <img
                            src="/img/icons/orange-star.svg"
                            alt="Grey star"
                            key={i}
                        />
                    )
                })}
                {times(greyCount, (i) => {
                    return (
                        <img
                            src="/img/icons/grey-star.svg"
                            alt="Grey star"
                            key={i}
                        />
                    )
                })}
            </div>
        </div>
    )
}
