import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { InvertedButton, PrimaryButton } from '../../../../components'
import { Loader } from '../../../../components/layout/loader'
import {
    cancelSubscriptions,
    formatAsCurrency,
    getClassNames,
    RouteLink,
    useStores,
} from '../../../../util'
import { DashboardPageWrapper, HeadedBox } from '../../common'

interface Props {
    className?: string
    name: string
    price: number
    features: string[]
    isPreferred?: boolean
    icon: string
    colors: {
        heading: string
        background: string
        text: string
        textAccent: string
        buttonBackground: string
        buttonText: string
    }
    plan: any
}

const PlanCard: React.FC<Props> = observer(
    ({ className, name, price, features, isPreferred, colors, plan }) => {
        const { subscriptions } = useStores()

        const subscribe = useCallback(() => {
            subscriptions
                .subscribeToPlan({
                    plan_id: plan.id,
                    coupon_code: '',
                })
                .subscribe()
        }, [subscriptions, plan.id])

        return (
            <div className="w-[230px]">
                <div
                    className={getClassNames(
                        'w-full rounded-[16px] p-5 relative',
                        className,
                    )}
                    style={{
                        background: colors.background,
                        color: colors.text,
                    }}
                >
                    {isPreferred && (
                        <div
                            className={getClassNames(
                                'text-[#182327] bg-[#F0BF7F] rounded-xl',
                                'inline-flex absolute left-1/2 top-0',
                                'px-3 items-center justify-center h-7',
                                'transform -translate-y-1/2 -translate-x-1/2',
                            )}
                        >
                            <span className="whitespace-pre text-[12px] font-bold">
                                Most preferred
                            </span>
                        </div>
                    )}
                    <header className="mb-2">
                        <span
                            className="font-[Dosis] font-[800] text-[15px] uppercase block mb-2"
                            style={{
                                color: colors.heading,
                            }}
                        >
                            {name}
                        </span>
                        <div>
                            <span
                                className="text-[30px] font-bold"
                                style={{ color: colors.textAccent }}
                            >
                                {formatAsCurrency(price)}
                            </span>
                            <span className="text-[12px] ml-2 font-bold">
                                Per month
                            </span>
                        </div>
                    </header>
                    <main className="grid grid-cols-1 gap-2.5 mb-4">
                        {features.map((feature, i) => (
                            <span key={i} className="text-[13px]">
                                {feature}
                            </span>
                        ))}
                    </main>
                    <footer>
                        <PrimaryButton
                            small
                            style={{
                                background: colors.buttonBackground,
                                borderColor: colors.buttonBackground,
                                color: colors.buttonText,
                            }}
                            onClick={subscribe}
                        >
                            <span>Subscribe</span>
                        </PrimaryButton>
                    </footer>
                </div>
            </div>
        )
    },
)

function CheckoutForm() {
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState<any>(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const loading = isProcessing || !stripe || !elements

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsProcessing(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.origin}${RouteLink.Subscription}`,
            },
        })

        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message)
        } else {
            setMessage('An unexpected error occured.')
        }

        setIsProcessing(false)
    }

    return (
        <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
            <PaymentElement options={{}} />
            <Loader loading={loading}>
                <footer className="grid grid-cols-2 gap-4 w-full">
                    <PrimaryButton
                        className="w-full"
                        isLoading={isProcessing}
                        disabled={isProcessing || !stripe || !elements}
                        small
                        type="submit"
                    >
                        <span>
                            {isProcessing ? 'Processing ... ' : 'Pay now'}
                        </span>
                    </PrimaryButton>
                    <a href={RouteLink.Plans}>
                        <InvertedButton type="button" className="w-full" small>
                            <span>Cancel</span>
                        </InvertedButton>
                    </a>
                </footer>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </Loader>
        </form>
    )
}

export const PlansPage: React.FC = observer(() => {
    const { subscriptions } = useStores()
    const stripePromise = subscriptions.stripePromise
    const clientSecret = subscriptions.subscriptionClientSecret
    const { isActive } = subscriptions.planInfo
    const rxSubscriptions: Subscription[] = useMemo(() => [], [])

    useEffect(() => {
        if (subscriptions.plans.length === 0) {
            rxSubscriptions.push(subscriptions.listPlans().subscribe())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions, subscriptions.plans, subscriptions.plansLoaded])

    useEffect(() => {
        rxSubscriptions.push(subscriptions.listSubscriptions().subscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions])

    useEffect(() => {
        return () => {
            cancelSubscriptions(rxSubscriptions)
        }
    }, [rxSubscriptions])

    return (
        <DashboardPageWrapper title="Plans">
            {clientSecret && (
                <div className="flex bg-white justify-center items-center absolute top-0 left-0 w-screen h-screen z-10 p-40">
                    <div className="max-w-[500px] w-full">
                        <Elements
                            stripe={stripePromise}
                            options={{ clientSecret }}
                        >
                            <CheckoutForm />
                        </Elements>
                    </div>
                </div>
            )}
            <div className="px-10 py-6 overflow-y-auto flex">
                {!isActive ? (
                    <div className="grid grid-cols-[274px_1fr] gap-5 w-full">
                        <div>
                            <HeadedBox className="w-full" heading="Active Plan">
                                <span>You have no active plan</span>
                            </HeadedBox>
                        </div>
                        <div className="flex space-x-4">
                            {subscriptions.basicPlan && (
                                <PlanCard
                                    name="Basic Plan"
                                    colors={{
                                        heading: 'black',
                                        background: '#4E39D51C',
                                        buttonBackground: '#654FF3',
                                        buttonText: '#FFFFFF',
                                        text: '#425466',
                                        textAccent: '#2F4F4F',
                                    }}
                                    features={[
                                        'Up To 50 Products Published On The Marketplace',
                                        'Integration To Epos',
                                        'New Epos + Printer + Scanner (+£20/Month)',
                                    ]}
                                    icon="/img/icons/star-bullet-point.svg"
                                    price={34.99}
                                    plan={subscriptions.basicPlan}
                                />
                            )}
                            {subscriptions.standardPlan && (
                                <PlanCard
                                    name="Standard Plan"
                                    colors={{
                                        heading: 'white',
                                        background: '#654FF3',
                                        buttonBackground: 'white',
                                        buttonText: 'black',
                                        text: 'white',
                                        textAccent: '#F0BF7F',
                                    }}
                                    features={[
                                        'Up To 300 Products Published On The Marketplace',
                                        'Integration To Epos (1 Location)',
                                        'Free Epos (If Required)',
                                        'Free Internet Connection For Epos',
                                        'Supply Chain And Reorder Automation',
                                    ]}
                                    isPreferred
                                    icon="/img/icons/star-bullet-point-yellow.svg"
                                    price={89.99}
                                    plan={subscriptions.standardPlan}
                                />
                            )}
                            {subscriptions.premiumPlan && (
                                <PlanCard
                                    name="Premium Plan"
                                    colors={{
                                        heading: 'black',
                                        background: '#FDF6ED',
                                        buttonBackground: '#654FF3',
                                        buttonText: '#FFFFFF',
                                        text: '#425466',
                                        textAccent: '#2F4F4F',
                                    }}
                                    features={[
                                        'Up To 50 Products Published On The Marketplace',
                                        'Integration To Epos',
                                        'New Epos + Printer + Scanner (+£20/Month)',
                                    ]}
                                    icon="/img/icons/star-bullet-point.svg"
                                    price={299.99}
                                    plan={subscriptions.premiumPlan}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <HeadedBox
                            className="w-full"
                            heading="Aready subscribed"
                        >
                            <Link to={RouteLink.Subscription}>
                                <span>Back to subscriptions</span>
                            </Link>
                        </HeadedBox>
                    </div>
                )}
            </div>
        </DashboardPageWrapper>
    )
})

export default PlansPage
