import { entries } from 'lodash'
import { Option } from '../option'

export enum DiscountType {
    AMOUNT = 'amount',
    PERCENTAGE = 'percentage',
}

export const discountTypeMap = {
    [DiscountType.AMOUNT]: 'Amount',
    [DiscountType.PERCENTAGE]: 'Percentage',
}

export const discountTypeOptions: Option[] = entries(discountTypeMap).map(
    ([key, value]) => ({ key, value }),
)
