import { IsPhoneNumber } from 'class-validator'
import { SendOtpModel } from './send-otp.model'

export class VerifyPhoneNumberModel extends SendOtpModel {
    @IsPhoneNumber('GB', {
        message: 'Must be a valid UK phone number',
    })
    public phone: string = ''

    constructor(phone?: string | null) {
        super()
        this.phone = phone ?? ''
    }
}
