import { Observable } from 'rxjs'
import {
    CreateProductModel,
    EditProductModel,
    EditProductQuantityModel,
    Product,
} from '../models'
import { HttpMethod, request, Response, stores } from '../util'

const getCurrentStoreId = () => {
    try {
        const store = stores.stores.selectedStore
        return store?.id ?? null
    } catch {
        return null
    }
}

export class ProductsService {
    public static listProducts(
        filters: Record<string, any>,
    ): Observable<Response<Product[]>> {
        const storeId = getCurrentStoreId()
        return request<never, Product[]>(
            `partner/products/${storeId}`,
            HttpMethod.GET,
            { query: filters },
        )
    }

    public static createProduct(
        model: CreateProductModel,
    ): Observable<Response<Product>> {
        const storeId = getCurrentStoreId()
        return request<CreateProductModel, Product>(
            `partner/products/${storeId}`,
            HttpMethod.POST,
            {
                body: model,
                completionMessage: 'Product created',
            },
        )
    }

    public static createMultipleProducts(
        models: CreateProductModel[],
    ): Observable<Response<Product>> {
        const storeId = getCurrentStoreId()
        return request<CreateProductModel[], never>(
            `partner/products/${storeId}/multiple`,
            HttpMethod.POST,
            {
                body: models,
                completionMessage: 'Products created',
            },
        )
    }

    public static editProduct(
        model: EditProductModel,
    ): Observable<Response<Product>> {
        return request<EditProductModel, Product>(
            `partner/products/${model.id}`,
            HttpMethod.PATCH,
            {
                body: model,
                completionMessage: 'Product updated',
            },
        )
    }

    public static editProductQuantity(
        model: EditProductQuantityModel,
    ): Observable<Response<Product>> {
        return request<EditProductQuantityModel, Product>(
            `partner/products/${model.id}/quantity`,
            HttpMethod.PATCH,
            {
                body: model,
                completionMessage: 'Product quantity updated',
            },
        )
    }
}
