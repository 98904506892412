import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RouteLink } from '../../util'
import { PageWidthConstraint } from './page-width-constraint'

const Wrapper = styled.div`
    background: white;
    padding: 1rem 0;
    height: 5rem;
    display: flex;
    align-items: center;

    .top-bar__logo {
        height: 1.5rem;
    }

    .top-bar__logo-area,
    .top-bar__content-area {
        display: flex;
        align-items: center;
    }

    .top-bar__content-area {
        width: 100%;
    }
`

interface Props {
    content?: ReactElement
}

export const TopBar: React.FC<Props> = ({ content }) => {
    return (
        <Wrapper>
            <PageWidthConstraint className="flex flex-row">
                <section className="top-bar__logo-area">
                    <Link to={RouteLink.Index}>
                        <img
                            src="/img/logo/color/Colour.svg"
                            alt="Ethco logo"
                            className="top-bar__logo"
                        />
                    </Link>
                </section>
                <section className="top-bar__content-area">{content}</section>
            </PageWidthConstraint>
        </Wrapper>
    )
}
