import { UploadLogoModel } from '../models'
import { HttpMethod, request } from '../util'

export class ProfileService {
    public static uploadLogo(model: UploadLogoModel) {
        return request<UploadLogoModel, never>(
            'partner/profile/logo',
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Uploading logo',
                completionMessage: 'Logo uploaded',
            },
        )
    }
}
