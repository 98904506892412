import { isPostalCode } from 'class-validator'
import { action, makeAutoObservable, runInAction } from 'mobx'
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    map,
    tap,
} from 'rxjs'
import { GetPostcodeInformationModel, PostcodeInformation } from '../models'
import { MiscService } from '../services'
import { isValidPostcode, Resettable } from '../util'

export class MiscStore implements Resettable {
    public postcodeInformation: PostcodeInformation | null = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public getPostcodeSubscription(postcodeSubject: BehaviorSubject<string>) {
        return postcodeSubject
            .pipe(
                map((postcode) => postcode.trim()),
                distinctUntilChanged(),
                debounceTime(1000),
                map((postcode) => {
                    runInAction(() => {
                        if (
                            isPostalCode(postcode, 'GB') &&
                            isValidPostcode(postcode)
                        ) {
                            const model = new GetPostcodeInformationModel()
                            model.postcode = postcode
                            return this.getPostcodeInformation(
                                model,
                            ).subscribe()
                        }

                        this.postcodeInformation = null
                    })
                }),
            )
            .subscribe()
    }

    @action
    public getPostcodeInformation(model: GetPostcodeInformationModel) {
        return MiscService.getPostcodeInformation(model).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.postcodeInformation = response.data
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.postcodeInformation = null
    }
}
