import { UilPlus } from '@iconscout/react-unicons'
import React, { useCallback } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

const StyledModal = styled(ReactModal)`
    display: flex;
    justify-content: flex-end;

    .modal__body {
        height: 100vh;
        width: 500px;
        background: white;
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .modal__close-button {
        height: 40px;
        width: 40px;
        color: #828282;
        background: #e3e3e3;
        border-radius: 100%;
        border: none;
    }

    .modal__header {
        padding: 1.75rem 1.75rem 0;
        margin-bottom: 2.25rem;
    }

    .modal__header-text {
        font-family: Dosis;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
    }

    .modal__content {
        flex: 1;
        overflow: hidden;
    }
`

interface Props {
    title: string
    titleColor?: string
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    children: React.ReactNode
}

export const Modal: React.FC<Props> = ({
    title,
    titleColor,
    isOpen,
    setIsOpen,
    children,
}) => {
    const closeModal = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <StyledModal
            isOpen={isOpen}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.25)',
                },
                content: {
                    background: 'blue',
                    width: 500,
                    position: 'absolute',
                    right: 0,
                },
            }}
            shouldCloseOnOverlayClick
            onRequestClose={closeModal}
        >
            <div className="modal__body">
                <header className="modal__header flex items-center">
                    <button
                        className="modal__close-button inline-flex items-center justify-center mr-3"
                        onClick={closeModal}
                    >
                        <UilPlus style={{ transform: 'rotate(45deg)' }} />
                    </button>
                    <span
                        className="modal__header-text"
                        style={{ ...(titleColor ? { color: titleColor } : {}) }}
                    >
                        {title}
                    </span>
                </header>
                <main className="modal__content">{children}</main>
            </div>
        </StyledModal>
    )
}
