import { Option } from './option'

export enum PolarAnswer {
    Yes = 'yes',
    No = 'no',
}

export const polarAnswerMap = {
    [PolarAnswer.Yes]: 'Yes',
    [PolarAnswer.No]: 'No',
}

export const convertPolarAnswerToBoolean = (answer: PolarAnswer) => {
    switch (answer) {
        case PolarAnswer.Yes:
            return true
        case PolarAnswer.No:
            return false
    }
}

export const convertBooleanToPolarAnswer = (bool: boolean) => {
    switch (bool) {
        case true:
            return PolarAnswer.Yes
        case false:
            return PolarAnswer.No
    }
}

export const polarAnswerOptions: Option[] = [
    {
        key: PolarAnswer.Yes,
        value: polarAnswerMap[PolarAnswer.Yes],
    },
    {
        key: PolarAnswer.No,
        value: polarAnswerMap[PolarAnswer.No],
    },
]
