import { Allow, IsOptional, IsPhoneNumber } from 'class-validator'
import { FilePondFile } from 'filepond'
import { clone, isString } from 'lodash'
import { toBase64 } from '../../../util'
import { Partner } from '../../response'
import { BaseModel } from '../base.model'

export class UpdateProfileModel implements BaseModel {
    @Allow()
    public logo?: string

    @Allow()
    public description?: string

    @Allow()
    public business_name?: string

    @Allow()
    public accepted_terms?: boolean

    @IsPhoneNumber('GB', { message: 'Must be a valid phone number' })
    public phone: string = ''

    @IsOptional()
    public files?: (FilePondFile | string)[] = []

    constructor(partner?: Partner) {
        if (partner) {
            this.logo = partner.logo ?? undefined
            this.files = partner.logo ? [partner.logo] : []
            this.description = partner.description ?? ''
            this.business_name = partner.business_name ?? ''
            this.phone = partner.phone ?? ''
            this.accepted_terms = true
        }
    }

    public generateCustomValidation(): Record<keyof BaseModel, string[]> {
        const errors: Partial<Record<keyof UpdateProfileModel, string[]>> = {}

        if (this.files && this.files.length === 0) {
            errors.files = ['Must provide a logo']
        }

        return errors
    }

    public async withProcessedImages(): Promise<UpdateProfileModel> {
        const model = clone(this)
        const file = model.files![0]

        if (isString(file)) {
            model.logo = file
        } else if (isString(file.source)) {
            model.logo = file.source
        } else {
            model.logo = await toBase64(file.source as File)
        }

        delete model.files
        return model
    }
}
