import { Formik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import { PrimaryButton, TextInput } from '../../components'
import { EmailLoginModel } from '../../models'
import { RouteLink, useStores } from '../../util'
import { validateModel } from '../../util/validation'
import { AuthPageWrapper } from './common'

export const LogInPage: React.FC = () => {
    const { auth } = useStores()

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <Formik
                    validate={validateModel}
                    initialValues={new EmailLoginModel()}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)

                        auth.logInWithEmail(values).subscribe({
                            complete: () => {
                                setSubmitting(false)
                            },
                        })
                    }}
                >
                    {({ isValid, isSubmitting, handleSubmit }) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <header className="auth-form-header">
                                <span>Log in</span>
                            </header>
                            <main>
                                <TextInput
                                    name="email"
                                    placeholder="Email address"
                                    label="Enter your email address"
                                />
                                <TextInput
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    label="Enter your password"
                                />
                            </main>
                            <footer>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    <span>Log in</span>
                                </PrimaryButton>
                            </footer>
                        </form>
                    )}
                </Formik>
                <div className="auth-form-tail">
                    <span>
                        New to Ethco Stores?{' '}
                        <Link to={RouteLink.SignUp}>Join Ethco</Link>
                    </span>
                    <Link to={RouteLink.ForgotPassword}>
                        Forgot your password?
                    </Link>
                </div>
            </main>
        </AuthPageWrapper>
    )
}
