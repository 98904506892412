import { Formik } from 'formik'
import { isMatch, omit } from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Subscription } from 'rxjs'
import { PrimaryButton, TextAreaInput, TextInput } from '../../../../components'
import { UpdateProfileModel } from '../../../../models'
import { appState, MessageType } from '../../../../stores'
import { cancelSubscriptions, useStores, validateModel } from '../../../../util'
import { DashboardPageWrapper } from '../../common'
import { ProductImageUploader } from '../../products/components'

export const ProfilePage: React.FC = observer(() => {
    const { auth, profile } = useStores()
    const subscriptions: Subscription[] = []

    useEffect(() => {
        return () => {
            cancelSubscriptions(subscriptions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <DashboardPageWrapper title="Profile">
            <div className="px-10 py-6 overflow-y-auto">
                <div className="bg-white border border-[#F2F2F2] rounded-lg p-4 max-w-2xl">
                    <Formik
                        validate={validateModel}
                        enableReinitialize
                        initialValues={
                            new UpdateProfileModel(auth.user?.partner)
                        }
                        onSubmit={async (values, { setSubmitting }) => {
                            setSubmitting(true)
                            values = await values.withProcessedImages()

                            if (
                                isMatch(
                                    omit(values, 'files'),
                                    omit(
                                        new UpdateProfileModel(
                                            auth.user?.partner,
                                        ),
                                        'files',
                                    ),
                                )
                            ) {
                                appState.createMessage(
                                    'No changes made',
                                    MessageType.INFO,
                                )
                                return
                            }

                            const subscription = profile
                                .updateProfile(values)
                                .subscribe({
                                    complete: () => {
                                        setSubmitting(false)
                                    },
                                })

                            subscriptions.push(subscription)
                        }}
                    >
                        {({ isValid, isSubmitting, handleSubmit }) => (
                            <form className="space-y-8" onSubmit={handleSubmit}>
                                <main>
                                    <div className="flex flex-col gap-4">
                                        <div className="max-w-xs">
                                            <ProductImageUploader
                                                name="files"
                                                label="Logo"
                                                placeholder=""
                                            />
                                        </div>
                                        <TextInput
                                            name="business_name"
                                            label="Business name"
                                            placeholder="Business name"
                                        />
                                        <TextAreaInput
                                            name="description"
                                            label="Description"
                                            placeholder="Description"
                                        />
                                        <TextInput
                                            name="phone"
                                            label="Contact number"
                                            placeholder="Contact number"
                                        />
                                    </div>
                                </main>
                                <footer>
                                    <PrimaryButton
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        <span>Update profile</span>
                                    </PrimaryButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </DashboardPageWrapper>
    )
})
