import { Formik } from 'formik'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { BehaviorSubject, Subscription } from 'rxjs'
import {
    InvertedButton,
    PrimaryButton,
    ReactSelectInput,
    TextInput,
} from '../../../components'
import { Modal } from '../../../components/misc/modal'
import { EditStoreModel, Store } from '../../../models'
import { useStores } from '../../../util'
import { validateModel } from '../../../util/validation'
import { ModalFormWrapper } from '../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    store: Store | null
}

export const EditStoreModal: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    store,
}) => {
    const { stores, misc } = useStores()
    const [postcodeSubject] = useState<BehaviorSubject<string>>(
        new BehaviorSubject(''),
    )

    const subscriptions: Subscription[] = []
    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const subscription = stores.listStoreCategories().subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores])

    const search = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            postcodeSubject.next(e.target.value)
        },
        [postcodeSubject],
    )

    useEffect(() => {
        const subscription = misc.getPostcodeSubscription(postcodeSubject)
        return () => {
            subscription.unsubscribe()
        }
    }, [postcodeSubject, misc])

    useEffect(() => {
        if (store) {
            postcodeSubject.next(store.postcode)
        }
    }, [postcodeSubject, store])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return store ? (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit store">
            <Formik
                enableReinitialize
                validate={validateModel}
                initialValues={new EditStoreModel(store)}
                onSubmit={(values, { setSubmitting }) => {
                    unsubscribe()
                    setSubmitting(true)

                    const subscription = stores
                        .editStore(store.id, values)
                        .subscribe({
                            next: (response) => {
                                setSubmitting(false)

                                if (response.ok) {
                                    setIsOpen(false)
                                }
                            },
                        })

                    subscriptions.push(subscription)
                }}
            >
                {({ isValid, isSubmitting, handleSubmit, setTouched }) => (
                    <ModalFormWrapper onSubmit={handleSubmit}>
                        <main className="form__content">
                            <div className="form__content-grid">
                                <TextInput
                                    name="name"
                                    label="Name"
                                    placeholder="Enter name"
                                />
                                <TextInput
                                    name="postcode"
                                    label="Postcode"
                                    onInput={search}
                                    onBlur={(e) => {
                                        search(e)
                                        setTouched({ postcode: true }, true)
                                    }}
                                    placeholder="Enter postcode"
                                />
                                <TextInput
                                    name="street"
                                    label="Street"
                                    placeholder="Enter street"
                                    options={
                                        misc.postcodeInformation
                                            ? misc.postcodeInformation.addresses.map(
                                                  (address) => ({
                                                      label: address,
                                                      value: address,
                                                  }),
                                              )
                                            : undefined
                                    }
                                />
                                <TextInput
                                    name="mile_radius"
                                    label="Delivery mile radius"
                                    placeholder="Enter mile radius"
                                />
                                <ReactSelectInput
                                    name="category"
                                    label="Category"
                                    placeholder="Select category"
                                    list={stores.storeCategoriesFormatted}
                                    options={{
                                        display: 'formattedName',
                                        value: 'id',
                                    }}
                                />
                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <TextInput
                                            name="longitude"
                                            label="Store longitude"
                                            placeholder="Longitude"
                                            disabled
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <TextInput
                                            name="latitude"
                                            label="Store latitude"
                                            placeholder="Latitude"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </main>
                        <footer className="form__footer">
                            <InvertedButton
                                className="mr-3"
                                type="button"
                                onClick={() => setIsOpen(false)}
                            >
                                <span>Cancel</span>
                            </InvertedButton>
                            <PrimaryButton
                                type="submit"
                                disabled={!isValid || isSubmitting}
                            >
                                <span>Update store</span>
                            </PrimaryButton>
                        </footer>
                    </ModalFormWrapper>
                )}
            </Formik>
        </Modal>
    ) : null
}
