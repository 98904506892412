import { Formik, FormikHelpers } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BehaviorSubject, Subscription } from 'rxjs'
import { CreateStoreModel, UpdateProfileModel } from '../../models'
import { RouteLink, useStores } from '../../util'
import { validateModel } from '../../util/validation'
import { AuthPageWrapper } from './common'
import { OnboardingStoreForm } from './forms'

export const OnboardingStorePage: React.FC = () => {
    const { auth, stores, misc, profile } = useStores()
    const navigate = useNavigate()
    const partner = auth.user?.partner
    const [postcodeSubject] = useState<BehaviorSubject<string>>(
        new BehaviorSubject(''),
    )

    const subscriptions: Subscription[] = []
    const unsubscribe = useCallback(() => {
        subscriptions.map((subscription) => subscription.unsubscribe())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createStore = useCallback(
        async (
            values: CreateStoreModel,
            { setSubmitting }: FormikHelpers<CreateStoreModel>,
        ) => {
            setSubmitting(true)

            const storeDetailsModel = await values.withProcessedImages()
            const profileModel = new UpdateProfileModel(partner)
            profileModel.logo = storeDetailsModel.logo
            profileModel.phone = storeDetailsModel.contact_person!.phone

            const subscription = stores
                .createStore(storeDetailsModel)
                .subscribe({
                    next(response) {
                        if (response.ok) {
                            navigate(RouteLink.OnboardingStoreOpeningHours, {
                                replace: true,
                            })
                        }
                    },
                    complete() {
                        stores.hydrateSelectedStore()
                        setSubmitting(false)
                    },
                })

            profile.updateProfile(profileModel).subscribe()
            subscriptions.push(subscription)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [stores, partner, profile, navigate],
    )

    useEffect(() => {
        const subscription = stores.listStoreCategories().subscribe()
        subscriptions.push(subscription)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores])

    useEffect(() => {
        const subscription = misc.getPostcodeSubscription(postcodeSubject)
        return () => {
            subscription.unsubscribe()
        }
    }, [postcodeSubject, misc])

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    }, [unsubscribe])

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <Formik
                    validate={validateModel}
                    initialValues={new CreateStoreModel()}
                    onSubmit={createStore}
                >
                    {(props) => <OnboardingStoreForm {...props} />}
                </Formik>
            </main>
        </AuthPageWrapper>
    )
}
