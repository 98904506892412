import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { CreateDiscountModel, Discount, EditDiscountModel } from '../models'
import { HttpMethod, request, Resettable, stores } from '../util'

export class DiscountsStore implements Resettable {
    public discounts: Discount[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public listDiscounts() {
        return request<never, any>(
            `partner/discounts/${stores.stores.selectedStore?.id}`,
            HttpMethod.GET,
            {
                loadingMessage: 'Fetching discounts',
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.discounts = response.data
                    }
                })
            }),
        )
    }

    @action
    public createDiscount(model: CreateDiscountModel) {
        return request(
            `partner/discounts/${stores.stores.selectedStore?.id}`,
            HttpMethod.POST,
            {
                loadingMessage: 'Creating discount',
                completionMessage: 'Discount created',
                body: model.getRequestBody(),
                query: {
                    discount_type: model.level,
                },
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listDiscounts().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public updateDiscount(discountId: string, model: EditDiscountModel) {
        return request(`partner/discounts/${discountId}`, HttpMethod.PATCH, {
            loadingMessage: 'Updating discount',
            completionMessage: 'Discount updated',
            body: model,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listDiscounts().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public removeDiscount(discountId: string) {
        return request(`partner/discounts/${discountId}`, HttpMethod.DELETE, {
            loadingMessage: 'Removing discount',
            completionMessage: 'Discount removed',
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listDiscounts().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.discounts = []
    }
}
