import styled from 'styled-components'
import { Page } from '../../../components'

const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .auth-header {
        margin-top: 90px;
        margin-bottom: 36px;

        img {
            height: 32px;
        }
    }

    .logo {
        margin-bottom: 10px;
    }

    .auth-body {
        margin-bottom: 2rem;
    }

    .auth-form {
        width: 440px;
        background: #ffffff;
        border-radius: 9px;
        padding: 32px;
    }

    .auth-form > main {
        display: grid;
        gap: 1rem;
        margin-bottom: 4rem;
    }

    .auth-form-header {
        font-family: Dosis;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        color: #333333;
        margin-bottom: 2rem;
    }

    .auth-form-tail {
        margin-top: 24px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;

        a {
            color: white;
            font-weight: 700;
        }

        > * + * {
            margin-top: 10px;
        }
    }
`

export const AuthPageWrapper: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => (
    <Page>
        <Wrapper>{children}</Wrapper>
    </Page>
)
