import { Formik, FormikHelpers } from 'formik'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Subscription, switchMap } from 'rxjs'
import { PrimaryButton, TextInput } from '../../components'
import { UpdateProfileModel, UpdateStoreConfigurationModel } from '../../models'
import { RouteLink, useStores } from '../../util'
import { validateModel } from '../../util/validation'
import { ProductImageUploader } from '../dashboard/products/components'
import { AuthPageWrapper } from './common'

const subscriptions: Subscription[] = []

export const OnboardingStoreDetailsPage: React.FC = () => {
    const { auth, stores, profile } = useStores()
    const selectedStore = stores.selectedStore
    const partner = auth.user?.partner

    const updateConfiguration = useCallback(
        async (
            values: UpdateStoreConfigurationModel,
            { setSubmitting }: FormikHelpers<UpdateStoreConfigurationModel>,
        ) => {
            setSubmitting(true)

            const storeDetailsModel = await values.withProcessedImages()
            const profileModel = new UpdateProfileModel(partner)
            profileModel.logo = storeDetailsModel.logo
            profileModel.phone = storeDetailsModel.contact_person!.phone

            const subscription = profile
                .updateProfile(profileModel)
                .pipe(
                    switchMap((response) => {
                        if (!response.ok) {
                            throw new Error()
                        }

                        return stores.updateConfiguration(storeDetailsModel)
                    }),
                )
                .subscribe({
                    complete() {
                        stores.hydrateSelectedStore()
                        setSubmitting(false)
                    },
                })

            profile.updateProfile(profileModel).subscribe()
            subscriptions.push(subscription)
        },
        [stores, partner, profile],
    )

    // useEffect(() => {
    //     return () => {
    //         unsubscribe()
    //     }
    // }, [unsubscribe])

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <Formik
                    validate={validateModel}
                    enableReinitialize
                    initialValues={
                        new UpdateStoreConfigurationModel(
                            partner!,
                            selectedStore,
                        )
                    }
                    onSubmit={updateConfiguration}
                >
                    {({ isValid, isSubmitting, handleSubmit }) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <header className="auth-form-header">
                                <span>Store details</span>
                            </header>
                            <main>
                                <div className="flex flex-col gap-4">
                                    <ProductImageUploader
                                        name="files"
                                        label="Logo"
                                        placeholder=""
                                    />
                                    <span className="font-[500] tracking-[0.09em] text-xs">
                                        CONTACT INFO
                                    </span>
                                    <div className="grid grid-cols-2 gap-4">
                                        <TextInput
                                            className="col-span-1"
                                            name="contact_person.name"
                                            placeholder="Name"
                                            label="Contact name"
                                        />
                                        <TextInput
                                            className="col-span-1"
                                            name="contact_person.phone"
                                            placeholder="Phone"
                                            label="Contact phone"
                                        />
                                        <TextInput
                                            className="col-span-2"
                                            name="contact_person.email"
                                            placeholder="Email"
                                            label="Contact email"
                                        />
                                    </div>
                                </div>
                            </main>
                            <footer>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                    className="w-full"
                                >
                                    <span>Continue</span>
                                </PrimaryButton>
                            </footer>
                        </form>
                    )}
                </Formik>
            </main>
        </AuthPageWrapper>
    )
}
