import { Observable } from 'rxjs'
import {
    EmailLoginModel,
    EmailLoginResponse,
    EmailSignupModel,
    ForgotPasswordModel,
    User,
    VerifyPhoneNumberModel,
} from '../models'
import { ExtendedResponse, HttpMethod, request, Response } from '../util'

export class AuthService {
    public static getUser(): Observable<Response<User>> {
        return request<never, User>('profile', HttpMethod.GET, {
            silentErrors: true,
        })
    }

    public static logInWithEmail(
        model: EmailLoginModel,
    ): Observable<ExtendedResponse<EmailLoginResponse>> {
        return request<EmailLoginModel, EmailLoginResponse>(
            'partner/auth/login',
            HttpMethod.POST,
            {
                body: model,
                loadingMessage: 'Logging user in using email address',
                completionMessage: 'Log in successful',
            },
        ) as any
    }

    public static signUpWithEmail(
        model: EmailSignupModel,
    ): Observable<Response> {
        return request<EmailSignupModel>(
            'partner/auth/register',
            HttpMethod.POST,
            {
                body: model,
                loadingMessage: 'Creating partner account',
                completionMessage: 'Partner account created',
            },
        )
    }

    public static requestPasswordReset(
        model: ForgotPasswordModel,
    ): Observable<Response> {
        return request(`auth/forgot/${model.email}`, HttpMethod.GET, {
            loadingMessage: 'Requesting password reset',
            completionMessage: 'Password reset request sent',
        })
    }

    public static confirmEmail(code: string): Observable<Response> {
        return request(`auth/confirm/${code}`, HttpMethod.GET, {
            loadingMessage: 'Confirming email address',
            completionMessage: 'Email address confirmed',
        })
    }

    public static requestOtp(): Observable<Response> {
        return request(`otp`, HttpMethod.GET, {
            loadingMessage: 'Requesting OTP',
            completionMessage: 'OTP sent',
        })
    }

    public static verifyPhoneNumber(
        model: VerifyPhoneNumberModel,
    ): Observable<Response> {
        return request(`profile/verify_phone`, HttpMethod.POST, {
            body: model,
            loadingMessage: 'Verifying phone number',
            completionMessage: 'Phone number verified',
        })
    }
}
