import { UilEllipsisV } from '@iconscout/react-unicons'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Subscription } from 'rxjs'
import {
    confirmDialog,
    Dropdown,
    DropdownItem,
    TableButton,
} from '../../../../components'
import { Discount } from '../../../../models'
import { getClassNames, useStores } from '../../../../util'

interface Props {
    discount: Discount
    small?: boolean
    className?: string
}

let subscription: Subscription | null = null

export const DiscountDropdown: React.FC<Props> = observer(
    ({ discount, small, className }) => {
        const { discounts } = useStores()

        const deleteDiscount = useCallback(() => {
            confirmDialog({
                title: `Remove discount`,
                content: 'Are you sure you want to remove this discount',
                confirmText: 'Yes, remove',
                confirmTextClassName: '!bg-red !text-white !border-red',
                onConfirm: () => {
                    subscription = discounts
                        .removeDiscount(discount.id)
                        .subscribe()
                },
            })
        }, [discounts, discount])

        useEffect(() => {
            return () => {
                subscription?.unsubscribe?.()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const items: DropdownItem[] = useMemo(() => {
            return [
                {
                    label: 'Remove',
                    action: deleteDiscount,
                    className: '!bg-red !text-white',
                },
            ]
        }, [deleteDiscount])

        return (
            <Dropdown
                button={TableButton}
                buttonClassName={getClassNames('!px-1', small && '!h-5')}
                buttonContent={<UilEllipsisV size={small ? 8 : 12} />}
                items={items}
                className={getClassNames(small && '!h-5', className)}
            />
        )
    },
)
