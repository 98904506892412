import type React from 'react'
import { useCallback } from 'react'
import ReactModal from 'react-modal'
import { BarcodeScanner } from '../components'

interface Props {
    onRead: (barcode: string) => unknown
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
;('857434004643')

export const BarcodeScannerModal: React.FC<Props> = ({
    onRead,
    isOpen,
    setIsOpen,
}) => {
    const closeModal = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const handleRead = useCallback(
        (barcode: string) => {
            onRead(barcode)
            closeModal()
        },
        [onRead, closeModal],
    )

    return (
        <ReactModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick
            shouldReturnFocusAfterClose
            onRequestClose={closeModal}
        >
            <BarcodeScanner onRead={handleRead} />
        </ReactModal>
    )
}
