import { UilAngleLeftB } from '@iconscout/react-unicons'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { PrimaryButton, TextInput } from '../../components'
import { ForgotPasswordModel, ResetPasswordModel } from '../../models'
import { RouteLink, useStores, validateModel } from '../../util'
import { AuthPageWrapper } from './common'

enum PageContext {
    RequestCode,
    ResetPassword,
}

type Model = ForgotPasswordModel | ResetPasswordModel

export const ForgotPasswordPage: React.FC = observer(() => {
    const { auth } = useStores()
    const navigate = useNavigate()
    const [pageContext, setPageContext] = useState(PageContext.RequestCode)
    const [email, setEmail] = useState('')
    const model = useMemo(() => {
        switch (pageContext) {
            case PageContext.RequestCode:
                return new ForgotPasswordModel()
            case PageContext.ResetPassword:
            default:
                return new ResetPasswordModel(email)
        }
    }, [pageContext, email])

    const resetContext = useCallback(() => {
        setPageContext(PageContext.RequestCode)
        setEmail('')
    }, [])

    const onSubmit = useCallback(
        (values: Model, { setSubmitting }: FormikHelpers<any>) => {
            setSubmitting(true)

            if (pageContext === PageContext.RequestCode) {
                auth.requestPasswordReset(values).subscribe({
                    next: () => {
                        setEmail(values.email)
                        setSubmitting(false)
                        setPageContext(PageContext.ResetPassword)
                    },
                })

                return
            }

            if (pageContext === PageContext.ResetPassword) {
                auth.resetPassword(values as ResetPasswordModel).subscribe({
                    next: () => {
                        setSubmitting(false)
                        navigate(RouteLink.LogIn)
                    },
                })

                return
            }
        },
        [pageContext, setPageContext, navigate, auth],
    )

    return (
        <AuthPageWrapper>
            <Link to={RouteLink.Index} className="auth-header">
                <img
                    src="/img/logo/white/ethco_white.svg"
                    alt="Ethco logo"
                    className="logo"
                />
            </Link>
            <main className="auth-body">
                <Formik
                    validate={validateModel}
                    enableReinitialize
                    initialValues={model}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, isValid, handleSubmit }) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <header className="auth-form-header flex justify-between">
                                <span>Password reset</span>
                                {pageContext !== PageContext.RequestCode && (
                                    <button
                                        className="text-primary inline-flex text-[1rem] items-center font-[500] font-[Quicksand]"
                                        onClick={resetContext}
                                    >
                                        <UilAngleLeftB size={16} />
                                        <span>Back</span>
                                    </button>
                                )}
                            </header>
                            <main>
                                {!email && (
                                    <TextInput
                                        name="email"
                                        label="Email address"
                                        placeholder="Enter your email address"
                                    />
                                )}
                                {pageContext === PageContext.ResetPassword && (
                                    <>
                                        <TextInput
                                            name="password"
                                            label="Password"
                                            placeholder="Enter your new password"
                                            type="password"
                                            showMultipleErrors
                                        />
                                        <TextInput
                                            name="code"
                                            label="Code"
                                            placeholder="Enter your code"
                                        />
                                    </>
                                )}
                            </main>
                            <footer>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    {pageContext ===
                                        PageContext.RequestCode && (
                                        <span>Request reset</span>
                                    )}
                                    {pageContext ===
                                        PageContext.ResetPassword && (
                                        <span>Reset password</span>
                                    )}
                                </PrimaryButton>
                            </footer>
                        </form>
                    )}
                </Formik>
                <div className="auth-form-tail">
                    <span>
                        Remember your password?{' '}
                        <Link to={RouteLink.LogIn}>Log in</Link>
                    </span>
                </div>
            </main>
        </AuthPageWrapper>
    )
})
