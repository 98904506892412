import JsBarcode from 'jsbarcode'
import React, { useCallback } from 'react'
import { InvertedButton, PrimaryButton } from '../../../../components'
import { Modal } from '../../../../components/misc/modal'
import { Product } from '../../../../models'
import { ModalFormWrapper } from '../../common'

interface Props {
    isOpen: boolean
    setIsOpen: (x: boolean) => any
    product: Product | null
}

export const ProductBarcodeModal: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    product,
}) => {
    const ref = useCallback(
        (node: HTMLImageElement) => {
            if (node !== null) {
                JsBarcode(node, product!.product_code)
            }
        },
        [product],
    )

    return product ? (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={product.title}>
            <ModalFormWrapper>
                <main className="form__content">
                    <img
                        ref={ref}
                        alt="Product barcode"
                        style={{
                            display: 'flex',
                            width: '100%',
                            maxWidth: '15rem',
                            margin: '0 auto',
                        }}
                    />
                </main>
                <footer className="form__footer">
                    <InvertedButton
                        className="mr-3"
                        type="button"
                        onClick={() => setIsOpen(false)}
                    >
                        <span>Close</span>
                    </InvertedButton>
                    <PrimaryButton type="button" disabled>
                        <span>Print code</span>
                    </PrimaryButton>
                </footer>
            </ModalFormWrapper>
        </Modal>
    ) : null
}
