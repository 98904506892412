import {
    IsEmail,
    IsEnum,
    IsLatitude,
    IsLongitude,
    IsNotEmpty,
    IsOptional,
    IsPositive,
    IsPostalCode,
    IsString,
    IsUrl,
    MaxLength,
    MinLength,
    ValidateIf,
    ValidateNested,
} from 'class-validator'
import { clone } from 'lodash'
import {
    convertBooleanToPolarAnswer,
    convertPolarAnswerToBoolean,
    NAME_MIN_LENGTH,
    PolarAnswer,
    stores,
} from '../../../util'
import { formatPostcode } from '../../../util/formatting/postcode'
import { isValidPostcode } from '../../../util/validation'
import { Satisfies } from '../../../util/validation/decorators'
import { Store, StoreFeedback } from '../../response'
import { BaseModel } from '../base.model'

class StoreFeedbackModel {
    @ValidateIf((o: StoreFeedbackModel) => o.other_pos === PolarAnswer.Yes, {
        message: 'Must provide name of internet provider',
    })
    @IsString()
    public epos_brand = ''

    @IsEnum(PolarAnswer, { message: 'Must be a valid choice' })
    public other_pos: string = ''

    @IsEnum(PolarAnswer, { message: 'Must be a valid choice' })
    public has_internet: string = ''

    @ValidateIf((o: StoreFeedbackModel) => o.has_internet === PolarAnswer.Yes)
    @MinLength(1, {
        message: 'Must provide name of internet provider',
    })
    public internet_provider: string = ''

    @ValidateIf((o: StoreFeedbackModel) => o.has_internet === PolarAnswer.No)
    @IsEnum(PolarAnswer, { message: 'Must be a valid choice' })
    public internet_pay: string = ''

    @IsEnum(PolarAnswer, { message: 'Must be a valid choice' })
    public has_website: string = ''

    @ValidateIf((o: StoreFeedbackModel) => o.has_website === PolarAnswer.Yes)
    @IsUrl(undefined, { message: 'Must be a valid url' })
    public website_url: string = ''

    @IsPositive({ message: 'Must be a positive number' })
    public products_in_store: number = 0

    @MinLength(1, { each: true, message: 'Must be a valid name' })
    public suppliers: string[] = []

    constructor(feedback?: StoreFeedback | null) {
        if (feedback) {
            this.epos_brand = feedback.epos_brand
            this.other_pos = convertBooleanToPolarAnswer(feedback.other_pos)
            this.has_internet = convertBooleanToPolarAnswer(
                !!feedback.internet_provider,
            )
            this.internet_provider = feedback.internet_provider
            this.internet_pay = convertBooleanToPolarAnswer(
                feedback.internet_pay,
            )
            this.has_website = convertBooleanToPolarAnswer(
                !!feedback.website_url,
            )
            this.website_url = feedback.website_url
            this.products_in_store = +feedback.products_in_store
            this.suppliers = feedback.suppliers
        }

        this.transform()
    }

    public getRequestBody(): StoreFeedback {
        const model = clone(this) as unknown as StoreFeedback
        model.other_pos = convertPolarAnswerToBoolean(
            this.other_pos as PolarAnswer,
        )
        model.internet_pay = convertPolarAnswerToBoolean(
            this.internet_pay as PolarAnswer,
        )
        return model
    }

    public transform() {
        this.products_in_store = +this.products_in_store

        if (this.has_internet === PolarAnswer.Yes) {
            this.internet_pay = ''
        }

        if (this.has_internet === PolarAnswer.No) {
            this.internet_provider = ''
        }

        if (this.has_website === PolarAnswer.No) {
            this.website_url = ''
        }

        if (this.other_pos === PolarAnswer.No) {
            this.epos_brand = ''
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class UpdateStoreInformationModel implements BaseModel {
    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @MaxLength(400, {
        message: 'Must be at most $constraint1 character(s) long',
    })
    public name: string = ''

    @IsOptional()
    @ValidateIf((o) => !!o.email)
    @IsEmail(undefined, { message: 'Must be a valid email address' })
    public email: string = ''

    @MinLength(NAME_MIN_LENGTH, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @MaxLength(400, {
        message: 'Must be at most $constraint1 character(s) long',
    })
    public street: string = ''

    @IsPostalCode('GB', { message: 'Must be a valid postcode' })
    @Satisfies(isValidPostcode, { message: 'Must be a valid postcode' })
    public postcode: string = ''

    @IsLongitude({ message: 'Must be a valid longitude value' })
    public longitude: number | '' = ''

    @IsLatitude({ message: 'Must be a valid latitude value' })
    public latitude: number | '' = ''

    @ValidateNested()
    @IsNotEmpty()
    public feedback!: StoreFeedbackModel

    constructor(store?: Store) {
        if (store) {
            this.name = store.name
            this.email = store.email ?? ''
            this.street = store.street
            this.postcode = store.postcode
            this.longitude = store.location?.coordinates[0] ?? ''
            this.latitude = store.location?.coordinates[1] ?? ''
        }

        this.feedback = new StoreFeedbackModel(store?.feedback)
    }

    public transform(): void {
        this.feedback.transform()

        if (!stores.misc.postcodeInformation) {
            this.longitude = this.longitude || ''
            this.latitude = this.latitude || ''
        } else {
            this.longitude = +(
                stores.misc.postcodeInformation?.longitude ??
                this.longitude ??
                0
            )

            this.latitude = +(
                stores.misc.postcodeInformation?.latitude ??
                this.latitude ??
                0
            )
        }
    }

    public generateCustomValidation() {
        if (this.feedback.suppliers.length === 0) {
            return {
                feedback: {
                    suppliers: ['Must provide at least one supplier'],
                },
            }
        }

        return {}
    }

    public getRequestBody() {
        const model = clone(this) as Omit<
            UpdateStoreInformationModel,
            'feedback'
        > & { feedback: StoreFeedback }

        model.postcode = formatPostcode(model.postcode)
        model.feedback = this.feedback.getRequestBody()
        return model
    }
}
