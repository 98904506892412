import { Observable } from 'rxjs'
import {
    CreateStoreModel,
    EditStoreModel,
    Store,
    StoreCategory,
} from '../models'
import { HttpMethod, request, Response } from '../util'

export class StoresService {
    public static listStores(): Observable<Response<Store[]>> {
        return request<never, Store[]>(`partner/stores`, HttpMethod.GET, {
            loadingMessage: 'Retrieving stores',
        })
    }

    public static listStoreCategories(): Observable<Response<StoreCategory[]>> {
        return request<never, StoreCategory[]>(
            `partner/stores/categories`,
            HttpMethod.GET,
            {
                loadingMessage: 'Fetching store categories',
            },
        )
    }

    public static retrieveStore(id: string): Observable<Response<Store>> {
        return request<never, Store>(`partner/stores/${id}`, HttpMethod.GET, {
            loadingMessage: 'Retrieving store',
        })
    }

    public static createStore(
        model: CreateStoreModel,
    ): Observable<Response<Store>> {
        return request(`partner/stores`, HttpMethod.POST, {
            body: model.getRequestBody(),
            loadingMessage: 'Creating store',
            completionMessage: 'Store created',
        })
    }

    public static editStore(
        id: string,
        model: EditStoreModel,
    ): Observable<Response<Store>> {
        return request<EditStoreModel, Store>(
            `partner/stores/${id}`,
            HttpMethod.PATCH,
            {
                body: model,
                loadingMessage: 'Updating store',
                completionMessage: 'Store updated',
            },
        )
    }

    public static deleteStore(id: string): Observable<Response<never>> {
        return request<never, never>(
            `partner/stores/${id}`,
            HttpMethod.DELETE,
            {
                loadingMessage: 'Deleting store',
                completionMessage: 'Store deleted',
            },
        )
    }
}
