export enum RouteLink {
    Index = '/',
    LogIn = '/auth/log-in',
    SignUp = '/auth/sign-up',
    VerifyPhone = '/auth/phone/verify',
    ForgotPassword = '/auth/password/forgot',
    PasswordReset = '/auth/password/reset/:token',

    /* Onboarding */
    OnboardingCreateStore = '/onboarding/store',
    OnboardingStoreDetails = '/onboarding/store/details',
    OnboardingStoreOpeningHours = '/onboarding/store/opening-hours',
    OnboardingTerms = '/onboarding/terms',
    OnboardingUpdateProfile = '/onboarding/profile',

    /* Dashboard routes */
    Dashboard = '/dashboard',
    Orders = '/orders',
    Order = '/orders/:orderId',
    Products = '/products',
    Categories = '/categories',
    Reviews = '/reviews',
    Discounts = '/discounts',
    Stores = '/stores',
    Analytics = '/analytics',

    SettingsIndex = '/settings',
    Profile = '/settings/profile',
    GeneralSettings = '/settings/general',
    Payment = '/settings/payment',
    Transactions = '/settings/payment/transactions',
    Subscription = '/settings/subscription',
    Plans = '/settings/subscription/plans',
    Any = '*',
}

export enum RouteRequirement {
    AUTHENTICATED,
    NOT_AUTHENTICATED,
    ACCEPTED_TERMS,
    NOT_ACCEPTED_TERMS,
    HAS_STORE,
    DOES_NOT_HAVE_STORE,
    COMPLETE_STORE,
    INCOMPLETE_STORE,
    COMPLETE_PROFILE,
    INCOMPLETE_PROFILE,
    PHONE_VERIFIED,
    PHONE_NOT_VERIFIED,
    EMAIL_VERIFIED,
    EMAIL_NOT_VERIFIED,
    EITHER_VERIFIED,
    BOTH_VERIFIED,
}
